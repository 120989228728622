import { Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser, PlatformLocation } from '@angular/common';
import { DynamicScriptLoaderService } from 'src/services/dynamic-script-loader.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {
  currentUser: any = null;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private router: Router, private platformLocation: PlatformLocation, private dynamicScriptLoader: DynamicScriptLoaderService) {
    this.currentUser = JSON.parse(localStorage.getItem('user')!);
  }

  ngOnInit(): void {
    this.dynamicScriptLoader.load("main");
  }

  sellAction() {
    window.location.href = (this.currentUser) ? "auto/add" : "login";
  }

  downloadAction() {
    const isDesktop = this.isDesktop();
    const isAndroid = this.isAndroid();
    const isIOS = this.isIOS();

    var downloadLink = "";
    if (isAndroid) {
      downloadLink = "https://play.google.com/store/apps/details?id=com.mootori.app";
    } else if (isIOS) {
      downloadLink = "https://apps.apple.com/ae/app/mootori/id6448007817";
    }

    if (downloadLink != "") {
      window.open(downloadLink, '_blank');
    }
  }

  getClass(routName: string) {
    return this.router.url.startsWith("/" + routName);
  }

  isDesktop(): boolean {
    // Check if the platform is a desktop (web)
    var dd = isPlatformBrowser(this.platformId);
    return isPlatformBrowser(this.platformId);
  }

  isAndroid(): boolean {
    // Check if the platform is Android
    return navigator.userAgent.toLowerCase().includes('android');
  }

  isIOS(): boolean {
    // Check if the platform is iOS
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }
}
