import { Injectable } from '@angular/core';

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  { name: 'main', src: 'assets/js/main.js' },
  { name: 'slider', src: 'assets/js/flex-slider.js' },
  { name: 'watermark', src: 'assets/js/watermark.min.js' },
  { name: 'steps', src: 'assets/js/jquery.steps.js' },
  { name: 'inputmask', src: 'assets/js/inputmask.min.js' },
  { name: 'google', src: 'assets/js/google.api.js' },
];

declare var document: any;

@Injectable({
  providedIn: 'root'
})
export class DynamicScriptLoaderService {

  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    // if (scripts.length == 0)
    //   scripts = ScriptStore.map(s => s.name);

    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        //load script
        let script = document.createElement('script');

        script.type = 'text/javascript';

        script.src = this.scripts[name].src;

        script.id = name;

        if (script.readyState) {  //IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {  //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('body')[0].appendChild(script);
      } else {
        var elem = document.getElementById(name);
        elem.parentElement.removeChild(elem);

        this.scripts[name].loaded = false;
        this.loadScript(name);

        //resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }

}
