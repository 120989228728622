<div class="login-area py-20">
    <div class="container">
        <div class="col-md-5 mx-auto">
            <div class="login-form">
                <div class="login-header">
                    <img src="assets/img/logo/mootori_Red.png" alt="Mootori">
                    <form class="form w-100 mb-13" novalidate="novalidate" (submit)="submit()">
                        <div class="text-center mb-10">
                            <!--begin::Title-->
                            <h1 class="text-dark mb-3">OTP Verification</h1>
                            <!--end::Title-->
                            <!--begin::Sub-title-->
                            <div class="text-muted fw-semibold fs-5 mb-5">Enter the verification code we sent to</div>
                            <!--end::Sub-title-->
                            <!--begin::Mobile no-->
                            <div class="fw-bold text-dark fs-3">{{sendTo}}</div>
                            <!--end::Mobile no-->
                        </div>

                        <div class="mb-10">
                            <!--begin::Label-->
                            <div class="fw-bold text-start text-dark fs-6 mb-1 ms-1">Type your 4 digit security code
                            </div>
                            <!--end::Label-->
                            <!--begin::Input group-->
                            <div class="d-flex flex-wrap flex-stack">
                                <input type="text" name="code_1" data-inputmask="'mask': '9', 'placeholder': ''"
                                    maxlength="1"
                                    class="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
                                    value="" (keyup)="elementTab('code_1')" />
                                <input type="text" name="code_2" data-inputmask="'mask': '9', 'placeholder': ''"
                                    maxlength="1"
                                    class="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
                                    value="" (keyup)="elementTab('code_2')" />
                                <input type="text" name="code_3" data-inputmask="'mask': '9', 'placeholder': ''"
                                    maxlength="1"
                                    class="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
                                    value="" (keyup)="elementTab('code_3')" />
                                <input type="text" name="code_4" data-inputmask="'mask': '9', 'placeholder': ''"
                                    maxlength="1"
                                    class="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
                                    value="" (keyup)="submit()"/>

                            </div>
                            <!--begin::Input group-->
                        </div>

                        <div class="d-flex align-items-center">
                            <button type="submit" class="theme-btn">Verify</button>
                        </div>
                    </form>

                    <div class="login-footer">
                        <p>Didn't get the code ? <a href="#" (click)="newOTP()">Resend</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>