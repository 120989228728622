<style type="text/css">
    .navwrap {
        width: 100%;
        max-width: calc(100% - 10px);
        margin: auto;
        overflow-y: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        /* for iOS */
        padding-bottom: 14px;

        background: var(--color-white);
        margin-bottom: 25px;
        padding: 10px 10px 10px 15px;
        border-radius: 10px;
    }

    .nav {
        display: table;
        /*white-space fix*/
        width: 100%;
        text-align: left;
        word-spacing: -9em;
        /*white-space fix*/
    }

    .nav li {
        display: inline-block;
        text-align: left;
        word-spacing: normal;
        vertical-align: middle;
        /*white-space fix*/
    }

    .nav li>a {
        position: relative;
    }

    .nav li:hover,
    .nav>li>a:hover {
        z-index: 105;
    }

    .nav li li {
        display: block;
    }

    .nav a {
        display: block;
        padding: 7px 10px;
        /* background: red; */
        /* color: #fff;
        border: 1px solid #000; */
        text-decoration: none;
        white-space: nowrap;
        position: relative;

        margin-right: 22px;
        /* padding: 30px 0 30px 0; */
        font-size: 17px;
        font-weight: 600;
        color: var(--color-dark);
        text-transform: capitalize;
    }

    .nav li:hover>a,
    .nav a:hover {
        color: var(--theme-color);
    }

    .nav ul {
        position: absolute;
        z-index: 104;
        left: -999em;
        top: 0;
        opacity: 0;
        transition: opacity 0.5s, left 0s 0.5s, top 0.5s;
    }

    .nav li:hover>ul {
        left: auto;
        top: auto;
        opacity: 1;
        transition: opacity 0.5s linear, top 0.5s;
    }

    @media (max-width: 991px) {
        .nav li:hover>ul {
            left: 17px;
            top: auto;
            opacity: 1;
            transition: opacity 0.5s linear, top 0.5s;
        }
    }

    /* .nav ul ul {
        transition: opacity 0.4s, left 0s 0.4s, margin 0s 0.4s;
        z-index: 103;
    } */

    /* .nav li li:hover ul {
        top: auto;
        margin-left: 100%;
        opacity: 1;
        transition: opacity 0.5s linear, margin 0.5s;
    } */

    /* arrows */
    .nav li a:first-child:not(:last-child) {
        padding-right: 20px;
        /* make space for arrows*/
    }

    .nav li a:first-child:not(:last-child):after {
        content: "";
        position: absolute;
        right: 3px;
        top: 50%;
        margin-top: -6px;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid var(--color-dark);
    }

    .nav>li>a:first-child:not(:last-child):after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid var(--color-dark);
        border-bottom: none;
        margin-top: -3px;
    }

    .nav li:hover a:first-child:not(:last-child):after {
        border-left-color: var(--theme-color);
    }

    .nav>li:hover>a:first-child:not(:last-child):after {
        border-left-color: transparent;
        border-top-color: var(--theme-color);
    }

    /* allow touch to play */
    .nav .touch-only {
        display: none;
        left: -10px;
    }

    .touch-device .nav .touch-only {
        display: inline-block;
    }

    .touch-device .nav .touch-only a {
        /* background: #000; */
        color: var(--color-dark);
    }

    .theme-btn:hover {
        color: var(--color-white) !important;
    }
</style>


<div class="car-area bg pt-25 pb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 mx-auto">
                <div class="site-heading text-center mb-2">
                    <span class="site-title-tagline"><i class="flaticon-drive"></i> Service Centers</span>
                    <!-- <h2 class="site-title">Best Dealers In <span>Your City</span></h2> -->
                    <div class="heading-divider"></div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class=navwrap>
                    <ul id="nav" class="nav">
                        <li>
                            <div class="car-search-form">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Search" id="namefilter"
                                        (keyup)="setNameFilter($event)">
                                    <button type="search" (click)="applyFilter()"><i class="far fa-search"></i></button>
                                </div>
                            </div>
                        </li>
                        <li class="ms-2">
                            <a href="javascript:void(0)">Location</a>
                            <ul>
                                <li>
                                    <div class="car-widget">
                                        <ul style="position: relative; opacity: 1; left: 0px;">
                                            <li>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="location"
                                                        id="location1" (change)="locationChanged('Abu Dhabi');">
                                                    <label class="form-check-label" for="location1"> Abu Dhabi</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="location"
                                                        id="location2" (change)="locationChanged('Al Ain');">
                                                    <label class="form-check-label" for="location2"> Al Ain</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="location"
                                                        id="location3" (change)="locationChanged('Dubai');">
                                                    <label class="form-check-label" for="location3"> Dubai</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="location"
                                                        id="location4" (change)="locationChanged('Sharjah');">
                                                    <label class="form-check-label" for="location4"> Sharjah</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="location"
                                                        id="location5" (change)="locationChanged('Ajman');">
                                                    <label class="form-check-label" for="location5"> Ajman</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="location"
                                                        id="location6" (change)="locationChanged('Umm Al-Quwain');">
                                                    <label class="form-check-label" for="location6"> Umm
                                                        Al-Quwain</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="location"
                                                        id="location7" (change)="locationChanged('Fujairah');">
                                                    <label class="form-check-label" for="location7"> Fujairah</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a href="javascript:void(0)">Brands</a>
                            <ul>
                                <li>
                                    <div class="car-widget">
                                        <div class="search-flter">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Type Keyword..."
                                                    id="brandfilter" (keyup)="listFilter('brand', $event)">
                                            </div>
                                        </div>
                                        <ul
                                            style="height: 200px; overflow-y: auto; position: relative; opacity: 1; left: 0px;">
                                            <li>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="brands"
                                                        id="brand" (change)="brandChanged(null)">
                                                    <label class="form-check-label" for="brand"> All Brands</label>
                                                </div>
                                            </li>
                                            <li *ngFor="let item of filterBrands; let i = index;">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="brands"
                                                        id="brand{{i}}" (change)="brandChanged(item)">
                                                    <label class="form-check-label" for="brand{{i}}">
                                                        {{item.brandname}}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a href="javascript:void(0)">Main Services</a>
                            <ul>
                                <li>
                                    <div class="car-widget">
                                        <div class="row" style="height: 400px; overflow-y: scroll;">
                                            <div class="col-6" *ngFor="let c of categories">
                                                <div class="border rounded m-1 p-2 business-category text-center"
                                                    role="button" [attr.category]="c.categoryid"
                                                    (click)="selectCategory($event)" style="height: 130px;">
                                                    <img [src]="FILESURL +'/appicons/' + getCategoryImage(c.categoryiconpath, 'gray')"
                                                        height="70" width="80" style="height: 70px; width: 80px;"
                                                        alt="{{fixCategoryName(c.categoryname)}}">

                                                    <span class="d-block"
                                                        style="font-size: 9px; white-space: pre-wrap;">{{fixCategoryName(c.categoryname)}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a role="button" class="theme-btn py-1 me-1" style="color: #ffffff;"
                                (click)="applyFilter()">Apply</a>
                        </li>

                        <li>
                            <a role="button" class="theme-btn theme-btn2 py-1" (click)="clearFilter();">Reset</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-lg-3" *ngFor="let business of pageAutoServices"
                            (click)="geoToDetails($event,business)" role="button">
                            <div class="dealer-item wow fadeInUp" data-wow-delay=".25s">
                                <div class="dealer-img">
                                    <!-- <span class="dealer-listing">{{business.autoscount}} Listing</span> -->
                                    <img [src]="USERSFILESURL +'/' + business.businessid + business.businessimagepath1 + randomQueryParam"
                                        alt="{{business.businessname}}">

                                    <div class="car-btns"
                                        *ngIf="currentUser && (currentUser.isadmin || currentUser.userid == 46)">
                                        <!-- <a href="#"><i class="far fa-heart"></i></a> -->
                                        <a href="business/edit/{{business.businessid}}"><i
                                                class="fa-solid fa-pen-to-square"></i></a>
                                    </div>
                                </div>
                                <div class="dealer-content">
                                    <h4><a
                                            href="business/services/details/{{business.businessid}}">{{business.businessname}}</a>
                                    </h4>
                                    <ul>
                                        <!-- <li><a href="https://maps.google.com/maps?q={{business.businesslat}},{{business.businesslng}}"
                                                target="_blank"><i class="far fa-location-dot"></i>
                                                {{business.businessaddress}}</a>
                                        </li> -->
                                        <li class="d-location-item">
                                            <div>
                                                <i class="far fa-phone"></i> <a href="tel:+21236547898">+971
                                                    {{business.businesscontactnumber}}</a>
                                            </div>
                                            <div name="location">
                                                <a href="https://maps.google.com/maps?q={{business.businesslat}},{{business.businesslng}}"
                                                    target="_blank" name="location"><i class="far fa-location-dot"
                                                        name="location"></i> {{business.businesscity}}</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pagination-area" *ngIf="filterAutoServices && filterAutoServices.length > itemsPerPage">
                        <div aria-label="Page navigation example">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link" role="button" aria-label="Previous"
                                        (click)="goToPreviousPage()">
                                        <span aria-hidden="true"><i class="far fa-arrow-left"></i></span>
                                    </a>
                                </li>
                                <li class="page-item me-2" style="width: 70px;">

                                    <!-- <a class="page-link"
                                        role="button" (click)="goToPage(i + 1)">{{i+ 1}}</a> -->

                                    <select class="select " id="list_pagination">
                                        <option value="{{i+ 1}}"
                                            *ngFor="let page of  [].constructor(pageCount); let i = index"
                                            [selected]="i == 0">{{i+ 1}}</option>
                                    </select>
                                </li>
                                <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li> -->
                                <li class="page-item">
                                    <a class="page-link" role="button" aria-label="Next" (click)="goToNextPage()">
                                        <span aria-hidden="true"><i class="far fa-arrow-right"></i></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>