import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, lastValueFrom, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MootoriAppointmentsService {
  private APIURL: string = environment.APIURL;
  private APIKEY: string = environment.APIKEY;

  constructor(private http: HttpClient) { }

  getAllApointments(): Observable<any> {
    const theUrl =
      this.APIURL + "mootoriappointments/read.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getAllApointmentsAsync() {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getAllApointments()).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Not Found
          if (msg.status == 404)
            resolve(null);
          else {
            // Error
            reject(msg);
          }
        });
    });
    return await promise;
  }

  addAppointment(data: any): Observable<any> {
    const theUrl =
      this.APIURL + "mootoriappointments/create.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    });
  }

  async addAppointmentAsync(data: any) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.addAppointment(data)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  updateAppointmentStatus(data: any): Observable<any> {
    const theUrl =
      this.APIURL + "mootoriappointments/update_status.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    });
  }

  async updateAppointmentStatusAsync(data: any) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.updateAppointmentStatus(data)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

}
