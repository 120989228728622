import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  encodeWithKey(data: string, key: string): string {
    const keyBytes = this.textEncoder.encode(key);
    const dataBytes = this.textEncoder.encode(data);
    const encodedBytes = dataBytes.map((byte, index) => byte ^ keyBytes[index % keyBytes.length]);
    const encodedString = this.textDecoder.decode(encodedBytes);
    // return Buffer.from(encodedString, 'utf-8').toString('base64');

    return window.btoa(encodedString);
  }

  decodeWithKey(encodedData: string, key: string): string {
    const keyBytes = this.textEncoder.encode(key);
    const decodedString = window.atob(encodedData);
    // Buffer.from(encodedData, 'base64').toString('utf8'); //
    const decodedBytes = this.textEncoder.encode(decodedString);
    const originalBytes = decodedBytes.map((byte, index) => byte ^ keyBytes[index % keyBytes.length]);
    return this.textDecoder.decode(originalBytes);
  }

  private textEncoder = new TextEncoder();
  private textDecoder = new TextDecoder();
}
