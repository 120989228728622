import { Component } from '@angular/core';
import { EncryptionService } from 'src/services/encryption.service';

@Component({
  selector: 'app-business-links',
  templateUrl: './business-links.component.html',
  styleUrls: ['./business-links.component.css']
})
export class BusinessLinksComponent {
  currentUser: any = null;

  extraData: string = "";

  constructor(private encryptionService: EncryptionService) {
    this.currentUser = JSON.parse(localStorage.getItem('user')!);

    if (this.currentUser && this.currentUser.isbusnessuser && this.currentUser.businesses) {
      this.extraData = this.encryptionService.encodeWithKey(this.currentUser.useremail, "90F4A4D873B3DE283583BCA737551E42");
    }
  }
}
