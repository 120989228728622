<div class="header-top-link" *ngIf="!currentUser">
    <a href="login"><i class="far fa-arrow-right-to-arc"></i> Login</a>
    <a href="register"><i class="far fa-user-vneck"></i> Register</a>
    <app-select-city style="display: none;"></app-select-city>
</div>

<div class="header-top-link" *ngIf="currentUser">
    <span class="me-2"> Welcome {{currentUser.username}}</span>
    <a href="#" (click)="logout()"><i class="far fa-arrow-up-left-from-circle"></i> Logout</a>
    <app-select-city style="display: none;"></app-select-city>
</div>