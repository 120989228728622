<div class="login-area py-20 {{verifyMode ? 'visually-hidden' : ''}}">
    <div class="container">
        <div class="col-md-5 mx-auto">
            <div class="login-form">
                <div class="login-header">
                    <img src="assets/img/logo/mootori_Red.png" alt="Mootori">
                    <p>Create your mootori account</p>
                </div>
                <form class="form w-100" novalidate="novalidate" action="#" (submit)="register($event)">
                    <ul *ngIf="errors.length > 0">
                        <li class="text-danger" *ngFor="let er of errors">{{er}}</li>
                    </ul>

                    <div class="form-group">
                        <label>Full Name</label>
                        <input type="text" class="form-control" placeholder="Your Name" name="username">
                    </div>
                    <div class="form-group">
                        <label>Email Address</label>
                        <div class="input-group mb-3">
                            <input type="email" class="form-control" placeholder="Your Email Address" name="email">
                            <button class="btn btn-outline-secondary" type="button" (click)="setVerifyType('email')"
                                *ngIf="!newUser.emailverified">Verify</button>
                            <span class="input-group-text text-success" *ngIf="newUser.emailverified"><i
                                    class="fa-solid fa-user-check"></i></span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Mobile Number</label>
                        <div class="input-group mb-3">
                            <span class="input-group-text">+971</span>
                            <input type="text" class="form-control" placeholder="Your Mobile Number" name="mobile">
                            <button class="btn btn-outline-secondary" type="button" (click)="setVerifyType('mobile')"
                                *ngIf="!newUser.mobileverified">Verify</button>
                            <span class="input-group-text text-success" *ngIf="newUser.mobileverified"><i
                                    class="fa-solid fa-user-check"></i></span>
                        </div>
                    </div>
                    <div class="form-check form-group">
                        <input class="form-check-input" type="checkbox" value="" id="agree">
                        <label class="form-check-label" for="agree">
                            I agree with the <a href="terms" target="_blank">Terms of Service</a>
                        </label>
                    </div>
                    <div class="d-flex align-items-center">
                        <button type="submit" class="theme-btn"><i class="far fa-paper-plane"></i>
                            Register</button>
                    </div>
                </form>
                <div class="login-footer">
                    <p>Already have an account? <a href="login">Login.</a></p>
                    <div class="social-login" *ngIf="usesocial">
                        <p>Continue with social media</p>
                        <div class="social-login-list">
                            <a href="#"><i class="fab fa-facebook-f"></i></a>
                            <a href="#"><i class="fab fa-google"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-otp [sendTo]="sendTo" (submitOTP)="otpVerify()" (resendOTP)="resendOTP()"
    class="{{verifyMode ? '' : 'visually-hidden'}}"></app-otp>