import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private APIURL: string = environment.APIURL;
  private APIKEY: string = environment.APIKEY;

  constructor(private http: HttpClient) { }

  sendMessage(to: string, subject: string, message: string, parameters?: any) {
    const theUrl = this.APIURL + "mootoriemail/message_send.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    var data = {
      "to": to,
      "subject": subject,
      "message": message,
    };

    if (parameters) {
      data = {
        ...data,
        ...parameters,
      }
    }

    this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    }).subscribe(m => {

    });
  }

  async sendMessageAsync(to: string, subject: string, message: string, parameters?: any) {
    const theUrl = this.APIURL + "mootoriemail/message_send.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    
    var data = {
      "to": to,
      "subject": subject,
      "message": message,
    };

    if (parameters) {
      data = {
        ...data,
        ...parameters,
      }
    }

    var result = this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    })

    let promise = new Promise<void>((resolve, reject) => {
      lastValueFrom(result).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          //reject(msg);
        });
    });
    return await promise;
  }
}
