import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BusinessProfileService } from 'src/services/business-profile.service';
import { DynamicScriptLoaderService } from 'src/services/dynamic-script-loader.service';

declare var jQuery: any;

declare var google: any;

@Component({
  selector: 'app-auto-services-details',
  templateUrl: './auto-services-details.component.html',
  styleUrls: ['./auto-services-details.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AutoServicesDetailsComponent implements OnInit {
  USERSFILESURL: string = environment.USERSFILESURL;
  FILESURL: string = environment.FILESURL;

  currentUser: any = null;

  businessid: number | null = null;
  autoServices: any | null = null;
  autoServicesImages: any[] = [];

  randomQueryParam = `?v=${Math.random().toString(36).substring(7)}`;

  constructor(private route: ActivatedRoute, private titleService: Title, private businessProfileService: BusinessProfileService, private dynamicScriptLoader: DynamicScriptLoaderService, private sanitizer: DomSanitizer) {
    let id = this.route.snapshot.paramMap.get('id');

    if (id)
      this.businessid = parseInt(id);

    this.currentUser = JSON.parse(localStorage.getItem('user')!);

    dynamicScriptLoader.load("google");
  }

  async ngOnInit(): Promise<void> {
    if (this.businessid) {
      this.autoServices = await this.businessProfileService.getBusinessprofileByidAsync(this.businessid);

      if (this.autoServices) {
        this.titleService.setTitle(this.autoServices.businessname + " - Mootori");

        await this.initMap(this.autoServices.businesslat, this.autoServices.businesslng, this.autoServices.businesscity);

        for (let i = 1; i < 5; i++) {
          if (this.autoServices["businessimagepath" + i.toString()]) {
            this.autoServicesImages.push(this.autoServices["businessimagepath" + i.toString()]);
          }
        }
      }
      else
        window.location.href = "home";
    }

    this.dynamicScriptLoader.load("slider");

    setTimeout(() => {
      this.dynamicScriptLoader.load("main");
      document.querySelector(".preloader")?.setAttribute("style", "display: none;");
    }, 200);

    await this.businessProfileService.addHitsAsync({
      "businessid": this.autoServices.businessid,
      "autoid": null,
      "userid": this.currentUser != null ? this.currentUser.userid : null,
      "hitedby": 2
    });
  }


  timeFormat(time: string) {
    if (time != null) {
      var timeArr = time.split(":");

      let hour = parseInt(timeArr[0]);
      let min = parseInt(timeArr[1]);

      let a = "AM";

      if (hour >= 12) {
        a = "PM";
        hour = hour > 12 ? hour - 12 : hour;
      } else if (hour == 0) {
        hour = 12;
      }

      let output = (hour < 10 ? "0" + hour : hour) + ":" + (min < 10 ? "0" + min : min) + " " + a;

      return output;
    }

    return null;
  }

  getDayName(num: number) {
    switch (num) {
      case 0:
        return "Mon";
      case 1:
        return "Tue";
      case 2:
        return "Wed";
      case 3:
        return "Thu";
      case 4:
        return "Fri";
      case 5:
        return "Sat";
      case 6:
        return "Sun";
    }

    return "";
  }

  getCategoryImage(name: string, color: string) {
    if (color.trim() != "") {
      let imageName = name.substring(0, name.lastIndexOf('.'))
      return `autoservices/${imageName} - ${color}.svg${this.randomQueryParam}`;
    } else
      return `autoservices/${name}${this.randomQueryParam}`;
  }

  async initMap(lat: any, lng: any, label: any) {
    // Request needed libraries.
    const { Map } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    const map = new Map(document.getElementById("map"), {
      center: { lat: lat, lng: lng },
      zoom: 14,
      mapId: "37c3af23858be656",
    });
    const priceTag = document.createElement("div");
    if (priceTag) {
      priceTag.className = "price-tag";
      priceTag.textContent = label;
    }

    const marker = new AdvancedMarkerElement({
      map,
      position: { lat: lat, lng: lng },
      content: priceTag,
    });

    this.codeLatLng(lat, lng);
  }

  codeLatLng(lat: any, lng: any) {
    var latlng = new google.maps.LatLng(lat, lng);
    let city: any;
    let geocoder = new google.maps.Geocoder;

    let _self = this;

    geocoder.geocode({ 'latLng': latlng }, function (results: any, status: any) {
      if (status == google.maps.GeocoderStatus.OK) {
        console.log(results);
        if (results[1]) {
          //formatted address
          // alert(results[0].formatted_address)
          //find country name
          for (var i = 0; i < results[0].address_components.length; i++) {
            for (var b = 0; b < results[0].address_components[i].types.length; b++) {

              //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
              if (results[0].address_components[i].types[b] == "administrative_area_level_1") {
                //this is the object you are looking for
                city = results[0].address_components[i];
                break;
              }
            }
          }
          //city data
          // alert(city.short_name + " " + city.long_name);

          const priceTag = <HTMLDivElement>document.querySelector(".price-tag");
          if (priceTag)
            priceTag.textContent = _self.autoServices.businessname + "\n" + city.long_name;
        } else {
          const priceTag = <HTMLDivElement>document.querySelector(".price-tag");
          if (priceTag)
            priceTag.textContent = `${_self.autoServices.businessname}`;
        }
      } else {
        const priceTag = <HTMLDivElement>document.querySelector(".price-tag");
        if (priceTag)
          priceTag.textContent = `${_self.autoServices.businessname}`;
      }
    });
  }

  mapURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl("https://maps.google.com?q=" + this.autoServices.businesslat + "," + this.autoServices.businesslng + "&hl=en&z=14&output=embed");
  }

  getRandomQueryParam() {
    return `?v=${Math.random().toString(36).substring(7)}`;
  }
}
