import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AutoService } from 'src/services/auto.service';
import { BusinessProfileService } from 'src/services/business-profile.service';
import { DynamicScriptLoaderService } from 'src/services/dynamic-script-loader.service';
import { EmailService } from 'src/services/email.service';

declare var jQuery: any;
declare var google: any;

@Component({
  selector: 'app-showroom-details',
  templateUrl: './showroom-details.component.html',
  styleUrls: ['./showroom-details.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ShowroomDetailsComponent implements OnInit {
  USERSFILESURL: string = environment.USERSFILESURL;
  FILESURL: string = environment.FILESURL;

  canEdit: boolean = false;
  currentUser: any = null;

  isListView: boolean = true;

  businessid: number | null = null;
  showroom: any | null = null;
  showroomImages: any[] = [];
  allAutos: any[] | null = [];
  pageAutos: any[] | null = [];
  autoCount: number = 0;

  itemsPerPage: number = 10;
  pageCount: number = 0;
  currentPage: number = 1;

  engineType: any[] = ["Petrol", "Diesel", "Hybrid", "Electric"];

  sortedBy: any =
    {
      "year": null,
      "price": null,
      "added": null,
      "distance": null,
    };

  randomQueryParam = `?v=${Math.random().toString(36).substring(7)}`;

  constructor(private route: ActivatedRoute, private titleService: Title, private businessProfileService: BusinessProfileService, private autoService: AutoService, private emailService: EmailService, private dynamicScriptLoader: DynamicScriptLoaderService, private sanitizer: DomSanitizer) {
    this.currentUser = JSON.parse(localStorage.getItem('user')!);

    let id = this.route.snapshot.paramMap.get('id');
    let cp = this.route.snapshot.paramMap.get('page');

    if (id)
      this.businessid = parseInt(id);

    if (cp)
      this.currentPage = parseInt(cp);
    else
      this.currentPage = 1;

    dynamicScriptLoader.load("google");
  }

  async ngOnInit(): Promise<void> {
    if (this.businessid) {
      this.showroom = await this.businessProfileService.getBusinessprofileByidAsync(this.businessid);

      if (!this.showroom)
        window.location.href = "home";

      this.titleService.setTitle(this.showroom.businessname + " - Mootori");

      await this.initMap(this.showroom.businesslat, this.showroom.businesslng, this.showroom.businesscity);

      for (let i = 1; i < 5; i++) {
        if (this.showroom["businessimagepath" + i.toString()]) {
          this.showroomImages.push(this.showroom["businessimagepath" + i.toString()]);
        }
      }

      this.allAutos = await this.autoService.getBusinessAutoAsync(this.businessid.toString());

      if (this.allAutos) {
        const quotient = Math.floor(this.allAutos.length / this.itemsPerPage);
        const remainder = this.allAutos.length % this.itemsPerPage;

        this.pageCount = quotient + (remainder > 0 ? 1 : 0);

        this.pageAutos = this.paginate(this.allAutos, this.currentPage, this.itemsPerPage);
        this.autoCount = this.allAutos.length;
      }

      this.canEdit = this.currentUser && this.showroom && this.currentUser.userid == this.showroom.userid;
    }

    let _self = this;

    this.dynamicScriptLoader.load("slider");

    setTimeout(() => {
      (function ($) {
        $(".nice-select ul li").on("click", function (e: any) {
          // console.log($(e)[0]);

          _self.sortChanged(e);
        });

        $('#list_pagination').niceSelect();

        $('#list_pagination').on("change", (e: any) => {
          _self.goToPage(e);
        });
      })(jQuery);

      this.dynamicScriptLoader.load("main");

      document.querySelector(".preloader")?.setAttribute("style", "display: none;");
    }, 200);

    await this.businessProfileService.addHitsAsync({
      "businessid": this.showroom.businessid,
      "autoid": null,
      "userid": this.currentUser != null ? this.currentUser.userid : null,
      "hitedby": 2
    });
  }

  paginate(array: any[], pageNumber: number, itemsPerPage: number) {
    var begin = ((pageNumber - 1) * itemsPerPage);
    var end = begin + itemsPerPage;
    return array.slice(begin, end);
  }

  goToNextPage() {
    if (this.allAutos && this.currentPage < this.pageCount) {
      this.currentPage++;

      this.pageAutos = this.paginate(this.allAutos, this.currentPage, this.itemsPerPage);

      let _self = this;
      (function ($) {
        $('#list_pagination').val(_self.currentPage);

        $('#list_pagination').niceSelect('update');

        $("html, body").animate({ scrollTop: 600 }, 500);
        return false;
      })(jQuery);
    }
  }

  goToPage(event: any) {
    let page = parseInt(event.target.value);
    if (this.allAutos) {
      this.currentPage = page;

      this.pageAutos = this.paginate(this.allAutos, this.currentPage, this.itemsPerPage);

      (function ($) {
        $("html, body").animate({ scrollTop: 600 }, 500);
        return false;
      })(jQuery);
    }
  }

  goToPreviousPage() {
    if (this.allAutos && this.currentPage > 1) {
      this.currentPage--;

      this.pageAutos = this.paginate(this.allAutos, this.currentPage, this.itemsPerPage);

      let _self = this;
      (function ($) {
        $('#list_pagination').val(_self.currentPage);

        $('#list_pagination').niceSelect('update');

        $("html, body").animate({ scrollTop: 600 }, 500);
        return false;
      })(jQuery);
    }
  }

  changeView(isListView: boolean) {
    this.isListView = isListView;

    if (isListView) {
      document.querySelector('.car-sort-list')?.classList.add("active");
      document.querySelector('.car-sort-grid')?.classList.remove("active");

      this.itemsPerPage = 10;
    } else {
      document.querySelector('.car-sort-list')?.classList.remove("active");
      document.querySelector('.car-sort-grid')?.classList.add("active");

      this.itemsPerPage = 15;
    }

    const quotient = Math.floor(this.allAutos.length / this.itemsPerPage);
    const remainder = this.allAutos.length % this.itemsPerPage;

    this.pageCount = quotient + (remainder > 0 ? 1 : 0);

    this.currentPage = 1

    this.pageAutos = this.paginate(this.allAutos!, this.currentPage, this.itemsPerPage);

    let _self = this;
    setTimeout(() => {
      (function ($) {
        $('#list_pagination').niceSelect('destroy');

        $('#list_pagination').val(_self.currentPage);

        $('#list_pagination').niceSelect();

        // $('#list_pagination').niceSelect('update');
        return false;
      })(jQuery);
    }, 200);

  }

  sortChanged(event: any) {
    let ctrl = event.target;

    let sortBy = ctrl.attributes["data-value"].value.split('_')[0];
    let type = ctrl.attributes["data-value"].value.split('_')[1];

    // let sortBy = ctrl.value.split('_')[0];
    // let type = ctrl.value.split('_')[1];

    if (sortBy == "reset") {
      this.reOrder("reset", "");
    }
    else {
      this.sortedBy[sortBy] = type;

      this.reOrder("reset", "");

      if (this.sortedBy.year)
        this.reOrder("year", this.sortedBy.year);

      if (this.sortedBy.price)
        this.reOrder("price", this.sortedBy.price);

      if (this.sortedBy.added)
        this.reOrder("added", this.sortedBy.added);

      if (this.sortedBy.distance)
        this.reOrder("distance", this.sortedBy.distance);
    }

    this.pageAutos = this.paginate(this.allAutos!, this.currentPage, this.itemsPerPage);
  }

  reOrder(orderBy: string, type: string) {
    if (this.allAutos) {
      switch (orderBy) {
        case "year":
          if (type == "desc") {
            this.allAutos = this.allAutos.sort((a, b) => b.year - a.year);
          } else {
            this.allAutos = this.allAutos.sort((a, b) => a.year - b.year);
          }
          break;
        case "price":
          if (type == "desc") {
            this.allAutos = this.allAutos.sort((a, b) => b.price - a.price);
          } else {
            this.allAutos = this.allAutos.sort((a, b) => a.price - b.price);
          }
          break;
        case "added":
          if (type == "desc") {
            this.allAutos = this.allAutos.sort((a, b) => b.autoid - a.autoid);
          } else {
            this.allAutos = this.allAutos.sort((a, b) => a.autoid - b.autoid);
          }
          break;
        case "distance":

          break;
        default:
          this.allAutos = this.allAutos.sort((a, b) => b.autoid - a.autoid);
          break;
      }

      let promotedAuto: any[] = this.allAutos.filter((a) => a.isPromoted);

      let otherAuto: any[] = this.allAutos.filter((a) => !a.isPromoted);

      this.allAutos = promotedAuto.concat(otherAuto);
    }
  }

  resetOrder(resetBy: string) {
    this.reOrder("reset", "");

    if (resetBy != "all") {
      this.sortedBy[resetBy] = null;

      if (this.sortedBy.year)
        this.reOrder("year", this.sortedBy.year);

      if (this.sortedBy.price)
        this.reOrder("price", this.sortedBy.price);

      if (this.sortedBy.added)
        this.reOrder("added", this.sortedBy.added);

      if (this.sortedBy.distance)
        this.reOrder("distance", this.sortedBy.distance);
    }
  }

  async addHits(auto: any) {
    // await this.autoService.addHitsAsync({
    //   "businessid": null,
    //   "autoid": auto.autoid,
    //   "userid": null
    // });

    window.location.href = "auto/details/" + auto.autoid;
  }

  async sendMessage() {
    let nameCtrl = document.getElementById("txt_name") as HTMLInputElement;
    let emailCtrl = document.getElementById("txt_email") as HTMLInputElement;
    let messageCtrl = document.getElementById("txt_message") as HTMLInputElement;

    if (nameCtrl.value != "" && emailCtrl.value != "" && messageCtrl.value != "") {

      let name = this.showroom.businessname;

      let email = this.showroom.businessemail;

      let subject = "Inquiry for " + this.showroom.businessname;

      try {
        await this.emailService.sendMessageAsync(email, subject, "u_auto_sale", {
          "name": name,
          "brand": "",
          "model": "",
          "year": "",
          "date": "",
          "bname": nameCtrl.value,
          "bemail": emailCtrl.value,
          "bmessage": messageCtrl.value,
          "link": window.location.href
        });
      } catch (error) {

      }


      nameCtrl.value = "";
      emailCtrl.value = "";
      messageCtrl.value = "";
    } else {
      alert("all fields are requerid");
    }
  }

  timeFormat(time: string) {
    if (time != null) {
      var timeArr = time.split(":");

      let hour = parseInt(timeArr[0]);
      let min = parseInt(timeArr[1]);

      let a = "AM";

      if (hour >= 12) {
        a = "PM";
        hour = hour > 12 ? hour - 12 : hour;
      } else if (hour == 0) {
        hour = 12;
      }

      let output = (hour < 10 ? "0" + hour : hour) + ":" + (min < 10 ? "0" + min : min) + " " + a;

      return output;
    }

    return null;
  }

  getDayName(num: number) {
    switch (num) {
      case 0:
        return "Mon";
      case 1:
        return "Tue";
      case 2:
        return "Wed";
      case 3:
        return "Thu";
      case 4:
        return "Fri";
      case 5:
        return "Sat";
      case 6:
        return "Sun";
    }

    return "";
  }

  async initMap(lat: any, lng: any, label: any) {
    // Request needed libraries.
    const { Map } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    const map = new Map(document.getElementById("map"), {
      center: { lat: lat, lng: lng },
      zoom: 14,
      mapId: "37c3af23858be656",
    });
    const priceTag = document.createElement("div");

    if (priceTag) {
      priceTag.className = "price-tag";
      priceTag.textContent = label;
    }

    const marker = new AdvancedMarkerElement({
      map,
      position: { lat: lat, lng: lng },
      content: priceTag,
    });

    this.codeLatLng(lat, lng);
  }

  codeLatLng(lat: any, lng: any) {
    var latlng = new google.maps.LatLng(lat, lng);
    let city: any;
    let geocoder = new google.maps.Geocoder;

    let _self = this;

    geocoder.geocode({ 'latLng': latlng }, function (results: any, status: any) {
      if (status == google.maps.GeocoderStatus.OK) {
        // console.log(results);
        if (results[1]) {
          //formatted address
          // alert(results[0].formatted_address)
          //find country name
          for (var i = 0; i < results[0].address_components.length; i++) {
            for (var b = 0; b < results[0].address_components[i].types.length; b++) {

              //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
              if (results[0].address_components[i].types[b] == "administrative_area_level_1") {
                //this is the object you are looking for
                city = results[0].address_components[i];
                break;
              }
            }
          }
          //city data
          // alert(city.short_name + " " + city.long_name);

          const priceTag = <HTMLDivElement>document.querySelector(".price-tag");
          if (priceTag)
            priceTag.textContent = _self.showroom.businessname + "\n" + city.long_name;
        } else {
          const priceTag = <HTMLDivElement>document.querySelector(".price-tag");
          if (priceTag)
            priceTag.textContent = `${_self.showroom.businessname}`;
        }
      } else {
        const priceTag = <HTMLDivElement>document.querySelector(".price-tag");
        if (priceTag)
          priceTag.textContent = `${_self.showroom.businessname}`;
      }
    });
  }

  mapURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl("https://maps.google.com?q=" + this.showroom.businesslat + "," + this.showroom.businesslng + "&hl=en&z=14&output=embed");
  }
}
