import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AutoService } from 'src/services/auto.service';
import { DynamicScriptLoaderService } from 'src/services/dynamic-script-loader.service';

declare var jQuery: any;

@Component({
  selector: 'app-my-auto',
  templateUrl: './my-auto.component.html',
  styleUrls: ['./my-auto.component.css']
})
export class MyAutoComponent implements OnInit {
  currentUser: any = null;

  isListView: boolean = true;

  allAutos: any[] | null = [];
  filterAutos: any[] | null = [];
  pageAutos: any[] | null = [];

  vehicleBodyType: any[] | null = [];
  selectedVehicletype: string = "";

  makesList: any[] | null = [];
  filterMakesList: any[] | null = [];
  makeSelectedItem: any;

  modelsTrimsList: any[] | null = [];

  modelsList: any[] | null = [];
  filterModelsList: any[] | null = [];
  modelSelectedItem: any;

  trimsList: any[] | null = [];
  filterTrimsList: any[] | null = [];
  trimSelectedItem: any;

  sortedBy: any =
    {
      "year": null,
      "price": null,
      "added": null,
      "distance": null,
    };

  engineType: any[] = ["Petrol", "Diesel", "Hybrid", "Electric"];

  USERSFILESURL: string = environment.USERSFILESURL;
  FILESURL: string = environment.FILESURL;

  filterApplied: boolean = false;

  priceFrom: string = "";
  priceTo: string = "";

  yearFrom: string = "";
  yearTo: string = "";

  itemsPerPage: number = 10;
  pageCount: number = 0;
  currentPage: number = 1;

  randomQueryParam = `?v=${Math.random().toString(36).substring(7)}`;

  constructor(private route: ActivatedRoute, private autoService: AutoService, private dynamicScriptLoader: DynamicScriptLoaderService) {
    this.currentUser = JSON.parse(localStorage.getItem('user')!);

    let cp = this.route.snapshot.paramMap.get('page');

    if (cp)
      this.currentPage = parseInt(cp);
    else
      this.currentPage = 1;
  }

  async ngOnInit(): Promise<void> {
    if (!this.currentUser)
      window.location.href = "login";

    document.querySelector(".preloader")?.removeAttribute("style");

    try {
      this.allAutos = await this.autoService.getUserAutoAsync(this.currentUser.userid);

      if (this.allAutos && this.allAutos.length > 0) {
        let promotedAuto: any[] = this.allAutos.filter((a) => a.isPromoted);
        promotedAuto = promotedAuto.sort((a, b) => b.autoid - a.autoid);

        let otherAuto: any[] = this.allAutos.filter((a) => !a.isPromoted);
        otherAuto = otherAuto.sort((a, b) => b.autoid - a.autoid);

        this.allAutos = promotedAuto.concat(otherAuto);
      }

      this.filterAutos = this.allAutos;

      if (this.filterAutos) {
        const quotient = Math.floor(this.filterAutos.length / this.itemsPerPage);
        const remainder = this.filterAutos.length % this.itemsPerPage;

        this.pageCount = quotient + (remainder > 0 ? 1 : 0);

        this.pageAutos = this.paginate(this.filterAutos, this.currentPage, this.itemsPerPage);

        let allVehicleBodyType = await this.autoService.getAllVehicleBodyTypeAsync();

        this.vehicleBodyType = this.autoService.identifyDuplicates(allVehicleBodyType!, "vehicletypeen");
      }
    } catch (error) {
      console.log(error);

      let allVehicleBodyType = await this.autoService.getAllVehicleBodyTypeAsync();

      this.vehicleBodyType = this.autoService.identifyDuplicates(allVehicleBodyType!, "vehicletypeen");
    }

    let _self = this;

    setTimeout(() => {
      this.dynamicScriptLoader.load("main");

      //this.replaceToCanvas('.car-img img');
    }, 0);

    setTimeout(() => {
      (function ($) {
        $(".nice-select ul li").on("click", function (e: any) {
          // console.log($(e)[0]);

          _self.sortChanged(e);
        });

        $('.vehicle-type[vtype="Car"]').trigger('click');

        $('#list_pagination').niceSelect();

        $('#list_pagination').on("change", (e: any) => {
          _self.goToPage(e);
        });
      })(jQuery);

      document.querySelector(".preloader")?.setAttribute("style", "display: none;");
    }, 100);
  }

  paginate(array: any[], pageNumber: number, itemsPerPage: number) {
    var begin = ((pageNumber - 1) * itemsPerPage);
    var end = begin + itemsPerPage;
    return array.slice(begin, end);
  }

  goToNextPage() {
    if (this.filterAutos && this.currentPage < this.pageCount) {
      this.currentPage++;

      this.pageAutos = this.paginate(this.filterAutos, this.currentPage, this.itemsPerPage);

      let _self = this;
      (function ($) {
        $('#list_pagination').val(_self.currentPage);

        $('#list_pagination').niceSelect('update');

        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
      })(jQuery);
    }
  }

  goToPage(event: any) {
    let page = parseInt(event.target.value);
    if (this.filterAutos) {
      this.currentPage = page;

      this.pageAutos = this.paginate(this.filterAutos, this.currentPage, this.itemsPerPage);

      (function ($) {
        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
      })(jQuery);
    }
  }

  goToPreviousPage() {
    if (this.filterAutos && this.currentPage > 1) {
      this.currentPage--;

      this.pageAutos = this.paginate(this.filterAutos, this.currentPage, this.itemsPerPage);

      let _self = this;
      (function ($) {
        $('#list_pagination').val(_self.currentPage);

        $('#list_pagination').niceSelect('update');

        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
      })(jQuery);
    }
  }

  changeView(isListView: boolean) {
    this.isListView = isListView;

    if (isListView) {
      document.querySelector('.car-sort-list')?.classList.add("active");
      document.querySelector('.car-sort-grid')?.classList.remove("active");

      this.itemsPerPage = 10;

      const quotient = Math.floor(this.filterAutos!.length / this.itemsPerPage);
      const remainder = this.filterAutos!.length % this.itemsPerPage;

      this.pageCount = quotient + (remainder > 0 ? 1 : 0);
    } else {
      document.querySelector('.car-sort-list')?.classList.remove("active");
      document.querySelector('.car-sort-grid')?.classList.add("active");

      this.itemsPerPage = 15;

      const quotient = Math.floor(this.filterAutos!.length / this.itemsPerPage);
      const remainder = this.filterAutos!.length % this.itemsPerPage;

      this.pageCount = quotient + (remainder > 0 ? 1 : 0);
    }

    this.currentPage = 1

    this.pageAutos = this.paginate(this.filterAutos!, this.currentPage, this.itemsPerPage);

    let _self = this;
    setTimeout(() => {
      (function ($) {
        $('#list_pagination').niceSelect('destroy');

        $('#list_pagination').val(_self.currentPage);

        $('#list_pagination').niceSelect();

        // $('#list_pagination').niceSelect('update');
        return false;
      })(jQuery);
    }, 200);


    // setTimeout(() => {

    //   this.replaceToCanvas('.car-img img');
    // }, 10);
  }

  sortChanged(event: any) {
    let ctrl = event.target;

    let sortBy = ctrl.attributes["data-value"].value.split('_')[0];
    let type = ctrl.attributes["data-value"].value.split('_')[1];

    // let sortBy = ctrl.value.split('_')[0];
    // let type = ctrl.value.split('_')[1];

    if (sortBy == "reset") {
      this.reOrder("reset", "");
    }
    else {
      this.sortedBy[sortBy] = type;

      this.reOrder("reset", "");

      if (this.sortedBy.year)
        this.reOrder("year", this.sortedBy.year);

      if (this.sortedBy.price)
        this.reOrder("price", this.sortedBy.price);

      if (this.sortedBy.added)
        this.reOrder("added", this.sortedBy.added);

      if (this.sortedBy.distance)
        this.reOrder("distance", this.sortedBy.distance);
    }

    this.pageAutos = this.paginate(this.filterAutos!, this.currentPage, this.itemsPerPage);
  }

  reOrder(orderBy: string, type: string) {
    if (this.filterAutos) {
      switch (orderBy) {
        case "year":
          if (type == "desc") {
            this.filterAutos = this.filterAutos.sort((a, b) => b.year - a.year);
          } else {
            this.filterAutos = this.filterAutos.sort((a, b) => a.year - b.year);
          }
          break;
        case "price":
          if (type == "desc") {
            this.filterAutos = this.filterAutos.sort((a, b) => b.price - a.price);
          } else {
            this.filterAutos = this.filterAutos.sort((a, b) => a.price - b.price);
          }
          break;
        case "added":
          if (type == "desc") {
            this.filterAutos = this.filterAutos.sort((a, b) => b.autoid - a.autoid);
          } else {
            this.filterAutos = this.filterAutos.sort((a, b) => a.autoid - b.autoid);
          }
          break;
        case "distance":

          break;
        default:
          this.filterAutos = this.filterAutos.sort((a, b) => b.autoid - a.autoid);
          break;
      }

      let promotedAuto: any[] = this.filterAutos.filter((a) => a.isPromoted);

      let otherAuto: any[] = this.filterAutos.filter((a) => !a.isPromoted);

      this.filterAutos = promotedAuto.concat(otherAuto);
    }
  }

  resetOrder(resetBy: string) {
    this.reOrder("reset", "");

    if (resetBy != "all") {
      this.sortedBy[resetBy] = null;

      if (this.sortedBy.year)
        this.reOrder("year", this.sortedBy.year);

      if (this.sortedBy.price)
        this.reOrder("price", this.sortedBy.price);

      if (this.sortedBy.added)
        this.reOrder("added", this.sortedBy.added);

      if (this.sortedBy.distance)
        this.reOrder("distance", this.sortedBy.distance);
    }
  }

  async getMakes(vehicletype: string) {
    this.selectedVehicletype = vehicletype;

    this.clearSelectionMake();
    this.clearItemsMake();

    try {
      this.makesList = await this.autoService.getMakeByVehicleTypeAsync(this.selectedVehicletype);

      this.filterMakesList = this.makesList;
    } catch (error) {
      this.clearItemsMake();
    }

    document.querySelectorAll('img').forEach(function (img) {
      img.onerror = function () { this.style.display = 'none'; };
    })


    // let _self = this;

    // (function ($) {


    //   // ($('#myDropdown') as any).ddslick('destroy');

    //   // ($('#myDropdown') as any).ddslick({
    //   //   data: _self.makes,
    //   //   with: "100%",
    //   //   selectText: "Select make",
    //   //   defaultSelectedIndex: null,
    //   //   onSelected: function (selectedData: any) {
    //   //     //callback function: do something with selectedData;
    //   //   }
    //   // });

    //   document.querySelectorAll('img').forEach(function (img) {
    //     img.onerror = function () { this.style.display = 'none'; };
    //   })

    // })(jQuery);
  }

  selectVehicleBodyType(event: MouseEvent) {
    let element = event.target as HTMLElement;

    let div = element;

    let img: HTMLImageElement;
    let span: HTMLSpanElement;

    if (element.tagName.toLowerCase() == "img") {
      div = element.parentNode as HTMLElement;
    }

    img = div.firstChild as HTMLImageElement;
    span = div.children[1] as HTMLSpanElement;

    document.querySelectorAll('.vehicle-type').forEach((d) => {
      d.classList.remove("border-danger");

      let i = d.firstChild as HTMLImageElement;
      let s = d.children[1] as HTMLSpanElement;

      i.src = i.src.replace("-red.svg", ".svg");
      s.classList.remove("text-danger");
    });

    div.classList.add("border-danger");
    img!.src = img!.src.replace(".svg", "-red.svg");
    span!.classList.add("text-danger");

    this.applyFilter();
  }

  clearSelectVehicleBodyType() {
    document.querySelectorAll('.vehicle-type').forEach((d) => {
      d.classList.remove("border-danger");

      let i = d.firstChild as HTMLImageElement;
      let s = d.children[1] as HTMLSpanElement;

      i.src = i.src.replace("-red.svg", ".svg");
      s.classList.remove("text-danger");
    });

    this.clearItemsMake();
    this.clearSelectionMake();
  }

  async makeChanged(make: any) {
    this.modelsList = [];

    if (make) {
      this.makeSelectedItem = make.make;

      this.modelsTrimsList = await this.autoService.getModelByVehicleTypeAsync(this.selectedVehicletype, this.makeSelectedItem);

      this.modelsList = this.autoService.identifyDuplicates(this.modelsTrimsList!, "model");

      this.filterModelsList = this.modelsList;
    } else
      this.makeSelectedItem = null;

    this.clearSelectionModel();

    this.clearSelectionTrim();
    this.clearItemsTrim();

    this.applyFilter();
  }

  modelChanged(model: any) {
    this.trimsList = [];

    if (model) {
      this.modelSelectedItem = model.model;
      let list = this.modelsTrimsList!.filter((m: any) => {
        return m.model == this.modelSelectedItem;
      });

      this.trimsList = this.autoService.identifyDuplicates(list, "trim");

      this.filterTrimsList = this.trimsList;
    } else
      this.modelSelectedItem = null;

    this.applyFilter();
  }

  trimChanged(trim: any) {
    if (trim)
      this.trimSelectedItem = trim.trim;
    else
      this.trimSelectedItem = null;
  }

  // unFocusMake() {
  //   this.mySelectMake.blur();
  // }

  clearSelectionMake() {
    this.makeSelectedItem = null;

    this.clearSelectionModel();
    this.clearItemsModel();

    this.clearSelectionTrim();
    this.clearItemsTrim();
  }

  clearItemsMake() {
    this.makesList = [];
    this.filterMakesList = this.makesList;
    this.clearItemsModel();
  }

  // unFocusModel() {
  //   this.mySelectModel.blur();
  // }

  clearSelectionModel() {
    this.modelSelectedItem = null;
  }

  clearItemsModel() {
    this.modelsList = [];

    this.filterModelsList = this.modelsList;
  }

  // unFocusTrim() {
  //   this.mySelectTrim.blur();
  // }

  clearSelectionTrim() {
    this.trimSelectedItem = null;
  }

  clearItemsTrim() {
    this.trimsList = [];

    this.filterTrimsList = this.trimsList;
  }

  setPriceFrom(event: Event) {
    let input = event.target as HTMLInputElement;

    this.priceFrom = input.value;

    this.applyFilter();
  }

  setPriceTo(event: Event) {
    let input = event.target as HTMLInputElement;

    this.priceTo = input.value;

    this.applyFilter();
  }

  clearPrice() {
    this.priceFrom = "";
    this.priceTo = "";

    this.applyFilter();
  }

  setYearFrom(event: Event) {
    let input = event.target as HTMLInputElement;

    this.yearFrom = input.value;

    this.applyFilter();
  }

  setYearTo(event: Event) {
    let input = event.target as HTMLInputElement;

    this.yearTo = input.value;

    this.applyFilter();
  }

  clearYear() {
    this.yearFrom = "";
    this.yearTo = "";

    this.applyFilter();
  }

  listFlter(type: string, ctrl: any) {
    let val: string = ctrl.target.value;

    switch (type) {
      case "makes":
        this.filterMakesList = this.makesList!.filter((m) => {
          return m.make.toLowerCase().startsWith(val);
        });
        break;
      case "models":
        this.filterModelsList = this.modelsList!.filter((m) => {
          return m.model.toLowerCase().startsWith(val);
        });
        break;
      case "trims":
        this.filterTrimsList = this.trimsList!.filter((m) => {
          return m.trim.toLowerCase().startsWith(val);
        });
        break;
    }
  }

  applyFilter() {
    this.filterApplied = false;

    this.filterAutos = this.allAutos

    if (this.selectedVehicletype) {
      this.filterAutos = this.filterAutos!.filter((a: any) => {
        return a.vehicletype == this.selectedVehicletype;
      });

      this.filterApplied = true;
    }

    if (this.makeSelectedItem) {
      this.filterAutos = this.filterAutos!.filter((a: any) => {
        return a.makemodeltrim[0].make == this.makeSelectedItem;
      });

      this.filterApplied = true;
    }

    if (this.modelSelectedItem) {
      this.filterAutos = this.filterAutos!.filter((a: any) => {
        return a.makemodeltrim[0].model == this.modelSelectedItem;
      });

      this.filterApplied = true;
    }

    if (this.trimSelectedItem) {
      this.filterAutos = this.filterAutos!.filter((a: any) => {
        return a.makemodeltrim[0].trim == this.trimSelectedItem;
      });

      this.filterApplied = true;
    }

    if (this.priceFrom.trim() != "") {
      this.filterAutos = this.filterAutos!.filter((a: any) => {
        return a.price >= parseFloat(this.priceFrom.trim());
      });

      this.filterApplied = true;
    }

    if (this.priceTo.trim() != "") {
      this.filterAutos = this.filterAutos!.filter((a: any) => {
        return a.price <= parseFloat(this.priceTo.trim());
      });

      this.filterApplied = true;
    }

    if (this.yearFrom.trim() != "") {
      this.filterAutos = this.filterAutos!.filter((a: any) => {
        return a.year >= parseFloat(this.yearFrom.trim());
      });

      this.filterApplied = true;
    }

    if (this.yearTo.trim() != "") {
      this.filterAutos = this.filterAutos!.filter((a: any) => {
        return a.year <= parseFloat(this.yearTo.trim());
      });

      this.filterApplied = true;
    }

    this.pageAutos = this.paginate(this.filterAutos!, this.currentPage, this.itemsPerPage);

    let _self = this;
    setTimeout(() => {
      (function ($) {
        $('#list_pagination').niceSelect('destroy');

        $('#list_pagination').val(_self.currentPage);

        $('#list_pagination').niceSelect();
      })(jQuery);
    }, 100);
  }

  clearFilter() {
    if (this.filterApplied) {
      this.clearSelectVehicleBodyType();

      this.clearPrice();

      this.clearYear();

      this.filterApplied = false;
    }

    document.querySelectorAll(".search-flter .form-control").forEach((txt) =>
      (txt as HTMLInputElement).value = "");

    this.filterAutos = this.allAutos;

    const quotient = Math.floor(this.filterAutos!.length / this.itemsPerPage);
    const remainder = this.filterAutos!.length % this.itemsPerPage;

    this.pageCount = quotient + (remainder > 0 ? 1 : 0);

    this.currentPage = 1;

    this.pageAutos = this.paginate(this.filterAutos!, this.currentPage, this.itemsPerPage);

    let _self = this;
    setTimeout(() => {
      (function ($) {
        $('#list_pagination').niceSelect('destroy');

        $('#list_pagination').val(_self.currentPage);

        $('#list_pagination').niceSelect();
      })(jQuery);
    }, 100);
  }

  replaceToCanvas(images: string) {
    let imgs = document.querySelectorAll(images);

    // console.log(imgs);

    for (let i = 0; i < imgs.length; i++) {
      const img = imgs.item(i) as HTMLImageElement;

      const parent = img!.parentNode as HTMLElement;

      const newWidth = parent.clientWidth;
      const newHeight = parent.clientHeight;

      let canvas = document.createElement('canvas');
      let ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

      canvas.width = newWidth;
      canvas.height = newHeight;
      ctx!.drawImage(img, 0, 0, newWidth, newHeight);

      const logo = new Image();
      logo.src = 'assets/img/logo/mootori_Red.png';


      logo.style.opacity = "0.5";
      logo.onload = () => {
        const logoWidth = newWidth / 4;
        const logoHeight = logoWidth / logo.width * logo.height;
        ctx!.drawImage(logo, newWidth - logoWidth - 10, newHeight - logoHeight - 10, logoWidth, logoHeight);

        ctx!.getImageData
      };

      img!.remove();

      //parent.removeAttribute("data-thumb");

      parent.appendChild(canvas);
    }
  }

  async addHits(auto: any) {
    // await this.autoService.addHitsAsync({
    //   "businessid": null,
    //   "autoid": auto.autoid,
    //   "userid": null
    // });

    window.location.href = "auto/details/" + auto.autoid;
  }

}
