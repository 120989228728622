import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';

import * as OTPAuth from 'otpauth';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private APIURL: string = environment.APIURL;
  private APIKEY: string = environment.APIKEY;

  constructor(private http: HttpClient) { }

  private _isStringNumber(str: string) {
    var parsed = parseFloat(str);
    var casted = +str;
    return parsed === casted && !isNaN(parsed) && !isNaN(casted);
  }

  getUser(input: string): Observable<any> {
    const theUrl =
      this.APIURL + ((this._isStringNumber(input)) ? "authentication/read_one_mobile.php?mobile=" + input : "authentication/read_one_email.php?email=" + input);

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getUserAsync(input: string) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getUser(input)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Not Found
          if (msg.status == 404)
            resolve(null);
          else {
            // Error
            reject(msg);
          }
        });
    });
    return await promise;
  }

  isBusnessUser(userid: number): Observable<any> {
    const theUrl = this.APIURL + "businessuser/is_business_user.php?userid=" + userid;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };

    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async isBusnessUserAsync(userid: number) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.isBusnessUser(userid)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  getUserBusiness(userid: number): Observable<any> {
    const theUrl = this.APIURL + "businessprofile/read_user_business_profiles.php?userid=" + userid;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };

    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getUserBusinessAsync(userid: number) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getUserBusiness(userid)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  register(user: any) {
    const theUrl = this.APIURL + "authentication/create.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    var data = user;

    return this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    });
  }

  async registerAsync(user: any) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.register(user)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  generateOTP() {
    let totp = new OTPAuth.TOTP({
      issuer: 'ACME',
      label: 'AzureDiamond',
      algorithm: 'SHA256',
      digits: 4,
      period: 1,
      secret: 'JBSWY3DPEHPK3PXP' // or "OTPAuth.Secret.fromBase32('NB2W45DFOIZA')"
    });

    return totp.generate();
  }

  sendOTP(to: string, otp: string) {
    const theUrl = this.APIURL + "mootoriemail/message_send.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    var data = {
      "to": to,
      "subject": "Mootori OTP",
      "message":
        "Dear Customer,<br/><br/>Your OTP is <b>" + otp + "</b>.<br/><br/>Use this Passcode to complete your transaction.",
    };

    this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    }).subscribe(m => {

    });
  }

  async sendOTPAsync(to: string, otp: string) {
    const theUrl = this.APIURL + "mootoriemail/message_send.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    var data = {
      "to": to,
      "subject": "Mootori OTP",
      "message":
        "Dear Customer,<br/><br/>Your OTP is <b>" + otp + "</b>.<br/><br/>Use this Passcode to complete your transaction.",
    };

    var result = this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    })

    let promise = new Promise<void>((resolve, reject) => {
      lastValueFrom(result).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  smsOTP(number: string) {
    const theUrl = this.APIURL + "sms/sent_otp.php?number=00" + number;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };

    this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    }).subscribe(m => {

    });
  }

  async smsOTPAsync(number: string) {
    const theUrl = this.APIURL + "sms/sent_otp.php?number=00" + number;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };

    var result = this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    })

    let promise = new Promise<void>((resolve, reject) => {
      lastValueFrom(result).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  verifyOTP(number: string, otp: string) {
    const theUrl = this.APIURL + "sms/verify_otp.php?number=00" + number + "&otp=" + otp;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };

    this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    }).subscribe(m => {

    });
  }

  async verifyOTPAsync(number: string, otp: string) {
    const theUrl = this.APIURL + "sms/verify_otp.php?number=00" + number + "&otp=" + otp;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };

    var result = this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    })

    let promise = new Promise<void>((resolve, reject) => {
      lastValueFrom(result).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  sendSMS(number: string, message: string) {
    const theUrl = this.APIURL + "sms/sent_sms.php?number=" + number + "&message=" + message;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };

    this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    }).subscribe(m => {

    });
  }

  async sendSMSAsync(number: string, message: string) {
    const theUrl = this.APIURL + "sms/sent_sms.php?number=" + number + "&message=" + message;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };

    var result = this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    })

    let promise = new Promise<void>((resolve, reject) => {
      lastValueFrom(result).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }
}
