import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PolicyComponent {
  constructor(private route: ActivatedRoute) {
    document.querySelector(".preloader")?.setAttribute("style", "display: none;");
  }
}
