import { Component } from '@angular/core';
import { EmailService } from 'src/services/email.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
  constructor(private emailService: EmailService) {
    document.querySelector(".preloader")?.setAttribute("style", "display: none;");
  }

  async sendMessage() {
    let nameCtrl = <HTMLInputElement>document.getElementById("txt_name");
    let emailCtrl = <HTMLInputElement>document.getElementById("txt_email");
    let subjectCtrl = <HTMLInputElement>document.getElementById("txt_subject");
    let messageCtrl = <HTMLInputElement>document.getElementById("txt_message");

    if (nameCtrl.value != "" && emailCtrl.value != "" && subjectCtrl.value != "" && messageCtrl.value != "") {
      let name = nameCtrl.value;
      let email = emailCtrl.value;
      let subject = "Inquiry from user [" + subjectCtrl.value + "]";
      let message = messageCtrl.value;

      try {
        await this.emailService.sendMessageAsync("info@mootori.com", subject, "u_contact", {
          "uname": name,
          "uemail": email,
          "usubject": subjectCtrl.value,
          "umessage": message,
        });
      } catch (error) {

      }


      nameCtrl.value = "";
      emailCtrl.value = "";
      subjectCtrl.value = "";
      messageCtrl.value = "";
    } else {
      alert("all fields are requerid");
    }
  }
}
