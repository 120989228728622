<div class="main-navigation">
    <nav class="navbar navbar-expand-lg">
        <div class="container position-relative">

            <a class="navbar-brand" href="home">
                <img src="assets/img/logo/mootori_Red.png" alt="Mootori">
            </a>

            <div class="mobile-menu-right">
                <!-- <div class="search-btn">
                    <button type="button" class="nav-right-link"><i class="far fa-search"></i></button>
                </div> -->

                <button class="theme-btn" style="padding: 5px" (click)="downloadAction()" *ngIf="isAndroid() || isIOS()"> <i
                        class="far fa-arrow-down"></i>
                    Download App</button>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-mobile-icon"><i class="far fa-bars"></i></span>
                </button>


            </div>

            <div class="collapse navbar-collapse" id="main_nav">
                <ul class="navbar-nav">
                    <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle active" href="#" data-bs-toggle="dropdown">Home</a>
            <ul class="dropdown-menu fade-down">
                <li><a class="dropdown-item" href="index.html">Home Page 01</a></li>
                <li><a class="dropdown-item" href="index-2.html">Home Page 02</a></li>
                <li><a class="dropdown-item" href="index-3.html">Home Page 03</a></li>
            </ul>
        </li> -->
                    <!-- <li class="nav-item"><a class="nav-link" href="home" [routerLink]="['/home']"
                            [routerLinkActive]="['active']" [ngClass]="{'active': getClass('home') }">Home</a></li> -->
                    <!-- <li class="nav-item"><a class="nav-link" href="home#about">About</a></li>
        <li class="nav-item"><a class="nav-link" href="home#motorists">Motorists</a></li> -->
                    <li class="nav-item"><a class="nav-link" href="motors" [routerLink]="['/motors']"
                            [routerLinkActive]="['active']"
                            [ngClass]="{'active': getClass('motors') || getClass('details') }">Explore Motors</a></li>

                    <li class="nav-item"><a class="nav-link" href="business/showrooms"
                            [routerLink]="['business/showrooms']" [routerLinkActive]="['active']"
                            [ngClass]="{'active': getClass('business/showrooms') }">Showrooms
                        </a></li>

                    <li class="nav-item"><a class="nav-link" href="business/services"
                            [routerLink]="['business/services']" [routerLinkActive]="['active']"
                            [ngClass]="{'active': getClass('business/services') }">Service Centers
                        </a></li>

                    <li class="nav-item"><a class="nav-link" href="business/spareparts"
                            [routerLink]="['business/spareparts']" [routerLinkActive]="['active']"
                            [ngClass]="{'active': getClass('business/spareparts') }">Spare Parts
                        </a></li>

                    <li class="nav-item"><a class="nav-link" href="mymotors" [routerLink]="['/mymotors']"
                            [routerLinkActive]="['active']" [ngClass]="{'active': getClass('mymotors') }">My Motors
                        </a></li>

                    <li class="nav-item"><a class="nav-link" href="contact" [routerLink]="['/contact']"
                            [routerLinkActive]="['active']" [ngClass]="{'active': getClass('contact') }">Contact</a>
                    </li>

                    <li class="nav-item"><a class="nav-link" href="about" [routerLink]="['/about']"
                        [routerLinkActive]="['active']" [ngClass]="{'active': getClass('about') }">About</a></li>
                </ul>
                <div class="nav-right">
                    <!-- <div class="search-btn">
            <button type="button" class="nav-right-link"><i class="far fa-search"></i></button>
        </div>
        <div class="cart-btn">
            <a href="#" class="nav-right-link"><i class="far fa-cart-plus"></i><span>0</span></a>
        </div> -->
                    <div class="nav-right-btn mt-2">
                        <button class="theme-btn" (click)="sellAction()"> <i class="flaticon-drive"></i>
                            Sell My Motor</button>
                    </div>


                    <div class="sidebar-btn">
                        <button type="button" class="nav-right-link"><i class="far fa-bars-sort"></i></button>
                    </div>
                </div>
            </div>

            <!-- search area -->
            <!-- <div class="search-area">
                <form action="#">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Type Keyword...">
                        <button type="submit" class="search-icon-btn"><i class="far fa-search"></i></button>
                    </div>
                </form>
            </div> -->
            <!-- search area end -->
        </div>
    </nav>
</div>