<div class="pt-25 pb-120">
    <div class="row">
        <div class="col-lg-3">
            <div class="user-profile-sidebar">
                <ul class="user-profile-sidebar-list">
                    <li>
                        <a class="tablinks active" role="button" (click)="tapChange($event, 'businessactivity', null)"
                            id="tap_businessactivity">
                            Business Activity <i class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('businessactivity')"></i></a>
                    </li>
                    <li>
                        <a class="tablinks" role="button" (click)="tapChange($event, 'tradeNumber', 'businessactivity')"
                            id="tap_tradeNumber">Trade Business Number <i
                                class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('tradeNumber')"></i></a>
                    </li>
                    <li>
                        <a class="tablinks" role="button" (click)="tapChange($event, 'info', 'tradeNumber')"
                            id="tap_info">Basic
                            Information <i class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('info')"></i></a>
                    </li>
                    <li>
                        <a class="tablinks" role="button" (click)="tapChange($event, 'contactinfo', 'info')"
                            id="tap_contactinfo">Contact
                            Information <i class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('contactinfo')"></i></a>
                    </li>
                    <li>
                        <a class="tablinks" role="button" (click)="tapChange($event, 'location', 'contactinfo')"
                            id="tap_location">Location <i class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('location')"></i></a>
                    </li>
                    <li>
                        <a class="tablinks" role="button" (click)="tapChange($event, 'businessdetails', 'location')"
                            id="tap_businessdetails">Working Hours <i
                                class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('businessdetails')"></i></a>
                    </li>
                    <li>
                        <a class="tablinks" role="button" (click)="tapChange($event, 'images', 'businessdetails')"
                            id="tap_images">Logo & Images <i class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('images')"></i></a>
                    </li>
                    <li>
                        <a class="tablinks" role="button" (click)="tapChange($event, 'bankdetails', 'images')"
                            id="tap_bankdetails">Bank Details <i
                                class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('bankdetails')"></i></a>
                    </li>
                    <li>
                        <a class="tablinks" role="button" (click)="tapChange($event, 'termsconditions', 'bankdetails')"
                            id="tap_termsconditions">Terms & Conditions <i
                                class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('termsconditions')"></i></a>
                    </li>

                    <li *ngIf="[1, 2, 3, 4].includes(businessProfile.businessactivitytype!)">
                        <a class="tablinks" role="button"
                            (click)="tapChange($event, 'areaofspeciality1', 'termsconditions')"
                            id="tap_areaofspeciality1">Area of Speciality 1 <i
                                class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('areaofspeciality1')"></i></a>
                    </li>
                    <li *ngIf="[1, 2, 4].includes(businessProfile.businessactivitytype!)">
                        <a class="tablinks" role="button"
                            (click)="tapChange($event, 'areaofspeciality2', 'areaofspeciality1')"
                            id="tap_areaofspeciality2">Area of Speciality 2 <i
                                class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('areaofspeciality2')"></i></a>
                    </li>
                    <li *ngIf="[1, 2, 4].includes(businessProfile.businessactivitytype!)">
                        <a class="tablinks" role="button"
                            (click)="tapChange($event, 'areaofspeciality3', 'areaofspeciality2')"
                            id="tap_areaofspeciality3">Area of Speciality 3 <i
                                class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('areaofspeciality3')"></i></a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-lg-9">
            <div id="businessactivity" class="tabcontent" style="display: block;">
                <div class="widget">
                    <div class="text-right mt-1">
                        <button type="button" class="theme-btn forward" (click)="validate('businessactivity');
                            tapChange($event, 'tradeNumber', 'businessactivity')">Next <i
                                class="fas fa-arrow-right-long"></i></button>
                    </div>

                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <h6 class="fw-bold my-4">Business Activity Type <span class="text-danger">*</span></h6>
                    <div class="category-list">
                        <a role="button" *ngFor="let t of option; let i = index;">
                            <input class="form-check-input" name="businessactivity" type="radio" value="{{i}}"
                                id="businessactivity{{i}}" style="margin-right: 10px;" (change)="getBusinessData(i + 1); validate('businessactivity');
                                tapChange($event, 'tradeNumber', 'businessactivity');">
                            <label class="form-check-label" for="businessactivity{{i}}">
                                {{t}}
                            </label>
                        </a>

                    </div>

                    <div class="text-right mt-5">
                        <button type="button" class="theme-btn forward" (click)="validate('businessactivity');
                            tapChange($event, 'tradeNumber', 'businessactivity')">Next <i
                                class="fas fa-arrow-right-long"></i></button>
                    </div>
                </div>
            </div>

            <div id="tradeNumber" class="tabcontent">
                <div class="widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="text-right mt-1">
                        <button type="button" class="theme-btn forward" (click)="tradeNumberBtnClick($event)">Next <i
                                class="fas fa-arrow-right-long"></i></button>
                    </div>

                    <h6 class="fw-bold my-4">Trade Business Number <span class="text-danger">*</span></h6>
                    <div class="input-group mb-3">
                        <select class="select" id="ddl_locations">
                            <option value="">Location</option>
                            <option value="{{l}}" *ngFor="let l of locations; let i = index;">{{l}}
                            </option>
                        </select>
                        <input type="text" id="txt_tradeNumber" class="form-control" placeholder="Trade Number">
                    </div>

                    <div class="text-right mt-5">
                        <button type="button" class="theme-btn forward" (click)="tradeNumberBtnClick($event)">Next <i
                                class="fas fa-arrow-right-long"></i></button>
                    </div>
                </div>
            </div>

            <div id="info" class="tabcontent">
                <div class="widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="checkout-form">
                        <div class="text-right mt-1">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('info'); tapChange($event, 'contactinfo', 'info')">Next <i
                                    class="fas fa-arrow-right-long"></i></button>
                        </div>

                        <form action="#">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Business Name <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" placeholder="Business Name"
                                            id="txt_businessname" [value]="businessProfile.businessname">
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Business Description <span class="text-danger">*</span></label>
                                        <textarea cols="30" rows="5" class="form-control"
                                            placeholder="Business Description" id="txt_businessdescription"
                                            [value]="businessProfile.businessdescription"></textarea>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Business Name (Arabic) <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" placeholder="Business Name (Arabic)"
                                            id="txt_businessnamear" [value]="businessProfile.businessnamear">
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Business Description (Arabic) <span class="text-danger">*</span></label>
                                        <textarea cols="30" rows="5" class="form-control"
                                            placeholder="Business Description (Arabic)" id="txt_businessdescriptionar"
                                            [value]="businessProfile.businessdescriptionar"></textarea>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="text-right mt-5">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('info'); tapChange($event, 'contactinfo', 'info')">Next <i
                                    class="fas fa-arrow-right-long"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="contactinfo" class="tabcontent">
                <div class="widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="checkout-form">
                        <div class="text-right mt-1">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('contactinfo'); tapChange($event, 'location', 'contactinfo')">Next
                                <i class="fas fa-arrow-right-long"></i></button>
                        </div>

                        <form action="#">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>Business Email <span class="text-danger">*</span></label>
                                        <input type="email" class="form-control" placeholder="Business Email"
                                            id="txt_email" [value]="businessProfile.businessemail">
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Business Mobile Number <span class="text-danger">*</span></label>

                                        <div class="input-group mb-3">
                                            <span class="input-group-text">+971</span>
                                            <input type="number" class="form-control"
                                                placeholder="Business Mobile Number" id="txt_mobile"
                                                [value]="businessProfile.businessmobilenumber">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Business Work Number <span class="text-danger">*</span></label>

                                        <div class="input-group mb-3">
                                            <span class="input-group-text">+971</span>
                                            <input type="number" class="form-control" placeholder="Business Work Number"
                                                id="txt_work" [value]="businessProfile.businesscontactnumber">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Business Secondary Mobile Number</label>

                                        <div class="input-group mb-3">
                                            <span class="input-group-text">+971</span>
                                            <input type="number" class="form-control"
                                                placeholder="Business Secondary Mobile Number" id="txt_smobile"
                                                [value]="businessProfile.businesssecondarymobilenumber">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Business Whatsapp Number</label>

                                        <div class="input-group mb-3">
                                            <span class="input-group-text">+971</span>
                                            <input type="number" class="form-control"
                                                placeholder="Business Whatsapp Number" id="txt_whatsapp"
                                                [value]="businessProfile.businesswhatsappnumber">
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>

                        <div class="text-right mt-5">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('contactinfo'); tapChange($event, 'location', 'contactinfo')">Next
                                <i class="fas fa-arrow-right-long"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="location" class="tabcontent">
                <div class="widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="checkout-form">
                        <div class="text-right mt-1">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('location'); tapChange($event, 'businessdetails', 'location')">Next <i
                                    class="fas fa-arrow-right-long"></i></button>
                        </div>

                        <div class="car-single-map">
                            <div class="contact-map">
                                <!-- <iframe [src]="mapURL()" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->

                                <div class="form-group">
                                    <label>Enter address</label>
                                    <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
                                        placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off"
                                        spellcheck="off" type="text" #search>
                                </div>

                                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom"
                                    [restriction]="componentRestrictions" (mapClick)="onMapClicked($event)">
                                    <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                                </agm-map>

                                <!-- <div>Latitude: {{latitude}}</div>
                                <div>Longitude: {{longitude}}</div> -->
                                <div class="mt-2">Selected Location: {{currentLocation}}</div>
                                <div class="mt-1">City: {{city}}</div>
                                <div class="mt-1">Country: {{country}}</div>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Address Details <span class="text-danger">*</span></label>
                                    <textarea class="form-control" cols="30" rows="5" id="txt_address"
                                        placeholder="Address Details"></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="text-right mt-5">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('location'); tapChange($event, 'businessdetails', 'location')">Next <i
                                    class="fas fa-arrow-right-long"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="businessdetails" class="tabcontent">
                <div class="widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="text-right mt-1">
                        <button type="button" class="theme-btn forward"
                            (click)="validate('businessdetails'); tapChange($event, 'images', 'businessdetails')">Next
                            <i class="fas fa-arrow-right-long"></i></button>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <h6 class="fw-bold my-4">Are you Trademark Owner/Attorney/Agency <span
                                    class="text-danger">*</span></h6>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-check">
                                        <input class="form-check-input" name="trademarkowner" type="radio" value="0"
                                            id="trademarkowner0" checked>
                                        <label class="form-check-label" for="trademarkowner0">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-check">
                                        <input class="form-check-input" name="trademarkowner" type="radio" value="1"
                                            id="trademarkowner1">
                                        <label class="form-check-label" for="trademarkowner1">
                                            Yes
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <h6 class="fw-bold my-4">Working Hours <span class="text-danger">*</span></h6>
                            <span>Tap on <span class="badge text-bg-success" style="width: 40px;">day</span> to mark as
                                weekend</span>

                            <div class="form-check form-switch" style="padding-left: 0px;">
                                <label class="form-check-label" for="open24hours">Open 24 hours</label>
                                <input class="form-check-input" name="open24hours" value="1" type="checkbox"
                                    role="switch" id="open24hours" style="float: right;" (change)="chenge24Hours()"
                                    [checked]="is24Hours">
                            </div>

                            <div class="form-check form-switch" style="padding-left: 0px;" *ngIf="canSetSameTime">
                                <label class="form-check-label" for="applytoall">Tap
                                    to apply the timing to all
                                    days</label>
                                <input class="form-check-input" name="applytoall" value="1" type="checkbox"
                                    role="switch" id="applytoall" style="float: right;" (change)="setSameToAll($event)">
                            </div>

                            <div *ngIf="!is24Hours">
                                <div class="row" *ngFor="let day of weekDay; let i = index;">
                                    <div class="col-1 d-flex" style="align-items: center;" role="button">
                                        <span class="badge text-bg-{{isWeekEnd(i) ? 'danger' : 'success'}}"
                                            style="width: 40px;" (click)="markAsWeekend(i)">{{day}}</span>
                                    </div>
                                    <div class="col-11" *ngIf="isWeekEnd(i)">
                                        <div class="container-fluid">
                                            <div class="row ms-2 w-100">
                                                <div class="col-2 d-flex" style="align-items: center; color: red;">
                                                    Weekend </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-11" style="align-items: center;" *ngIf="!isWeekEnd(i)">
                                        <div class="container-fluid">
                                            <div class="row ms-2 w-100">
                                                <div class="col-2 d-flex" style="align-items: center; color: green;">
                                                    Work
                                                    From </div>
                                                <div class="col-3">
                                                    <select class="select" id="ddl_from_{{i}}"
                                                        (change)="changeWorkHours($event, i)">
                                                        <option value=""></option>
                                                        <option value="{{o.value}}" *ngFor="let o of hours">
                                                            {{o.text}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-2 d-flex" style="align-items: center; color: green;"> To
                                                </div>
                                                <div class="col-3">
                                                    <select class="select" id="ddl_to_{{i}}"
                                                        (change)="changeWorkHours($event, i)">
                                                        <option value=""></option>
                                                        <option value="{{o.value}}" *ngFor="let o of hours">
                                                            {{o.text}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-fluid">
                                            <div class="row ms-2 my-1 w-100">
                                                <div class="col-2 d-flex" style="align-items: center; color: red;">Break
                                                    From </div>
                                                <div class="col-3">
                                                    <select class="select" id="ddl_bfrom_{{i}}"
                                                        (change)="changeWorkHours($event, i)">
                                                        <option value=""></option>
                                                        <option value="{{o.value}}" *ngFor="let o of hours">
                                                            {{o.text}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-2 d-flex" style="align-items: center; color: red;"> To
                                                </div>
                                                <div class="col-3">
                                                    <select class="select" id="ddl_bto_{{i}}"
                                                        (change)="changeWorkHours($event, i)">
                                                        <option value=""></option>
                                                        <option value="{{o.value}}" *ngFor="let o of hours">
                                                            {{o.text}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-right mt-5">
                        <button type="button" class="theme-btn forward"
                            (click)="validate('businessdetails'); tapChange($event, 'images', 'businessdetails')">Next
                            <i class="fas fa-arrow-right-long"></i></button>
                    </div>
                </div>
            </div>

            <div id="images" class="tabcontent">
                <div class="widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="checkout-form">
                        <div class="text-right mt-1">
                            <button type="button" class="theme-btn forward"
                                (click)="uploadDraftFiles(); validate('images'); tapChange($event, 'bankdetails', 'images')">Next
                                <i class="fas fa-arrow-right-long"></i></button>
                        </div>

                        <div class="row mt-5" *ngIf="hasDraftFiles">
                            <div class="col-md-6 col-lg-3" *ngIf="businessProfile.businesslogopath">
                                <div class="team-item wow fadeInUp" data-wow-delay=".25s" style="margin-bottom: 10px;">
                                    <div class="team-img">
                                        <img [src]="USERSFILESURL + '/draftfiles/' + draftid + businessProfile.businesslogopath + randomQueryParam"
                                            alt="" style="margin-top: auto;">
                                    </div>

                                    <div class="team-content">
                                        <div class="team-bio">
                                            <h5>Business Logo</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-3" *ngIf="businessProfile.businessimagepath1">
                                <div class="team-item wow fadeInUp" data-wow-delay=".25s" style="margin-bottom: 10px;">
                                    <div class="team-img">
                                        <img [src]="USERSFILESURL + '/draftfiles/' + draftid + businessProfile.businessimagepath1 + randomQueryParam"
                                            alt="" style="margin-top: auto;">
                                    </div>

                                    <div class="team-content">
                                        <div class="team-bio">
                                            <h5>Business Image 1</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-3" *ngIf="businessProfile.businessimagepath2">
                                <div class="team-item wow fadeInUp" data-wow-delay=".25s" style="margin-bottom: 10px;">
                                    <div class="team-img">
                                        <img [src]="USERSFILESURL + '/draftfiles/' + draftid + businessProfile.businessimagepath2 + randomQueryParam"
                                            alt="" style="margin-top: auto;">
                                    </div>

                                    <div class="team-content">
                                        <div class="team-bio">
                                            <h5>Business Image 2</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-3" *ngIf="businessProfile.businessimagepath3">
                                <div class="team-item wow fadeInUp" data-wow-delay=".25s" style="margin-bottom: 10px;">
                                    <div class="team-img">
                                        <img [src]="USERSFILESURL + '/draftfiles/' + draftid + businessProfile.businessimagepath3 + randomQueryParam"
                                            alt="" style="margin-top: auto;">
                                    </div>

                                    <div class="team-content">
                                        <div class="team-bio">
                                            <h5>Business Image 3</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-3" *ngIf="businessProfile.businessimagepath4">
                                <div class="team-item wow fadeInUp" data-wow-delay=".25s" style="margin-bottom: 10px;">
                                    <div class="team-img">
                                        <img [src]="USERSFILESURL + '/draftfiles/' + draftid + businessProfile.businessimagepath4 + randomQueryParam"
                                            alt="" style="margin-top: auto;">
                                    </div>

                                    <div class="team-content">
                                        <div class="team-bio">
                                            <h5>Business Image 4</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-3" *ngIf="businessProfile.businesslicensepath">
                                <div class="team-item wow fadeInUp" data-wow-delay=".25s" style="margin-bottom: 10px;">
                                    <div class="team-img">
                                        <img [src]="USERSFILESURL + '/draftfiles/' + draftid + businessProfile.businesslicensepath + randomQueryParam"
                                            alt="" style="margin-top: auto;">
                                    </div>

                                    <div class="team-content">
                                        <div class="team-bio">
                                            <h5>Business License</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Business/Company Logo <span class="text-danger">*</span></label>
                                    <input class="form-control" type="file" accept="image/*"
                                        (change)="onChange($event, '_BusinessLogo')">
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Cover Image <span class="text-danger">*</span></label>
                                    <input class="form-control" type="file" accept="image/*"
                                        (change)="onChange($event, '_BusinessImage', true)">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Business Images(upto 3 images only) <span
                                            class="text-danger">*</span></label>
                                    <input class="form-control" type="file" accept="image/*" multiple
                                        (change)="onChange($event, '_BusinessImage')">
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Business Trader License <span class="text-danger">*</span></label>
                                    <input class="form-control" type="file" accept="image/*, .pdf"
                                        (change)="onChange($event, '_BusinessLicense')" checkSize="false">
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Website</label>
                                    <input type="text" class="form-control" placeholder="Website" id="txt_website">
                                </div>
                            </div>



                        </div>

                        <div class="text-right mt-5">
                            <button type="button" class="theme-btn forward"
                                (click)="uploadDraftFiles(); validate('images'); tapChange($event, 'bankdetails', 'images')">Next
                                <i class="fas fa-arrow-right-long"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="bankdetails" class="tabcontent">
                <div class="widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="checkout-form">
                        <div class="text-right mt-1">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('bankdetails'); tapChange($event, 'termsconditions', 'bankdetails')">Next
                                <i class="fas fa-arrow-right-long"></i></button>
                        </div>

                        <form action="#">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>IBAN Number</label>
                                        <input type="text" class="form-control" placeholder="IBAN Number"
                                            id="txt_ibannumber" [value]="businessProfile.businessibannumber">
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Bank Name</label>
                                        <input type="text" class="form-control" placeholder="Bank Name"
                                            id="txt_bankname" [value]="businessProfile.businessbankname">
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Beneficiary Name</label>
                                        <input type="text" class="form-control" placeholder="Beneficiary Name"
                                            id="txt_beneficiaryname"
                                            [value]="businessProfile.businessbankbeneficiaryname">
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="text-right mt-5">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('bankdetails'); tapChange($event, 'termsconditions', 'bankdetails')">Next
                                <i class="fas fa-arrow-right-long"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="termsconditions" class="tabcontent">
                <div class="widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="checkout-form">
                        <div class="text-right mt-1">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('termsconditions'); tapChange($event, 'areaofspeciality1', 'termsconditions')">Next
                                <i class="fas fa-arrow-right-long"></i></button>
                        </div>

                        <form action="#">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Terms & Conditions</label>
                                        <textarea cols="30" rows="8" class="form-control"
                                            placeholder="Terms & Conditions" id="txt_termsconditions"
                                            [value]="businessProfile.termsandconditions"></textarea>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <h6 class="fw-bold my-4">Accept Cash Payment <span class="text-danger">*</span></h6>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-check">
                                                <input class="form-check-input" name="acceptcashpayment" type="radio"
                                                    value="1" id="acceptcashpayment0"
                                                    [checked]="businessProfile.acceptcashondelivery == 1">
                                                <label class="form-check-label" for="acceptcashpayment0">
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-check">
                                                <input class="form-check-input" name="acceptcashpayment" type="radio"
                                                    value="0" id="acceptcashpayment1"
                                                    [checked]="!businessProfile.acceptcashondelivery || businessProfile.acceptcashondelivery == 0">
                                                <label class="form-check-label" for="acceptcashpayment1">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="text-right mt-5">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('termsconditions'); tapChange($event, 'areaofspeciality1', 'termsconditions')">Next
                                <i class="fas fa-arrow-right-long"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="areaofspeciality{{[2, 4].includes(businessProfile.businessactivitytype!) ? '3' : '1'}}"
                class="tabcontent" *ngIf="[1, 2, 4].includes(businessProfile.businessactivitytype!)">
                <div class="widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="text-right mt-1">
                        <button type="button" class="theme-btn forward"
                            (click)="validate('areaofspeciality1'); tapChange($event, 'areaofspeciality2', 'areaofspeciality1')"
                            *ngIf="businessProfile.businessactivitytype == 1">Next
                            <i class="fas fa-arrow-right-long"></i></button>

                        <button type="button" class="theme-btn forward" (click)="validate('areaofspeciality3'); save();"
                            *ngIf="[2, 4].includes(businessProfile.businessactivitytype!)">Save</button>
                    </div>

                    <form action="#">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <h6 class="fw-bold my-4">Select one or more Category <span
                                            class="text-danger">*</span></h6>
                                    <div class="row">
                                        <div class="col-3" *ngFor="let c of Categories">
                                            <div class="border rounded m-1 p-2 business-category text-center"
                                                role="button" [attr.category]="c.categoryid"
                                                (click)="selectCategory($event)">
                                                <img [src]="FILESURL +'/appicons/' + getCategoryImage(c.categoryiconpath, 'gray')"
                                                    height="70" width="80" style="height: 70px; width: 80px;">

                                                <span class="d-block text-nowrap"
                                                    style="font-size: 9px;">{{fixCategoryName(c.categoryname)}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="text-right mt-5">
                        <button type="button" class="theme-btn forward"
                            (click)="validate('areaofspeciality1'); tapChange($event, 'areaofspeciality2', 'areaofspeciality1')"
                            *ngIf="businessProfile.businessactivitytype == 1">Next
                            <i class="fas fa-arrow-right-long"></i></button>

                        <button type="button" class="theme-btn forward" (click)="validate('areaofspeciality3'); save();"
                            *ngIf="[2, 4].includes(businessProfile.businessactivitytype!)">Save</button>
                    </div>
                </div>
            </div>

            <div id="areaofspeciality2" class="tabcontent" *ngIf="businessProfile.businessactivitytype == 1">
                <div class="widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="text-right mt-1">
                        <button type="button" class="theme-btn forward"
                            (click)="validate('areaofspeciality2'); tapChange($event, 'areaofspeciality3', 'areaofspeciality2')">Next
                            <i class="fas fa-arrow-right-long"></i></button>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <h6 class="fw-bold my-4">Engine Type <span class="text-danger">*</span></h6>
                            <div class="row">
                                <div class="col-12" *ngFor="let et of engineType; let i = index;">
                                    <div class="form-check">
                                        <input class="form-check-input" name="enginetype" type="checkbox" value="{{i}}"
                                            id="enginetype{{i}}" [checked]="isChecked('engineType',i)">
                                        <label class="form-check-label" for="enginetype{{i}}">
                                            {{et}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <h6 class="fw-bold my-4">Delivery Channel <span class="text-danger">*</span></h6>
                            <div class="row">
                                <div class="col-12" *ngFor="let dc of deliveryChannel; let i = index;">
                                    <div class="form-check">
                                        <input class="form-check-input" name="deliverychannel" type="checkbox"
                                            value="{{i}}" id="deliverychannel{{i}}"
                                            [checked]="isChecked('deliveryChannel',i)">
                                        <label class="form-check-label" for="deliverychannel{{i}}">
                                            {{dc}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <h6 class="fw-bold my-4">Accept Appointment <span class="text-danger">*</span></h6>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-check">
                                        <input class="form-check-input" name="acceptappointment" type="radio" value="1"
                                            id="acceptappointment0" [checked]="businessProfile.appointment == 1">
                                        <label class="form-check-label" for="acceptappointment0">
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-check">
                                        <input class="form-check-input" name="acceptappointment" type="radio" value="0"
                                            id="acceptappointment1" [checked]="businessProfile.appointment == 0">
                                        <label class="form-check-label" for="acceptappointment1">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-right mt-5">
                        <button type="button" class="theme-btn forward"
                            (click)="validate('areaofspeciality2'); tapChange($event, 'areaofspeciality3', 'areaofspeciality2')">Next
                            <i class="fas fa-arrow-right-long"></i></button>
                    </div>
                </div>
            </div>

            <div id="areaofspeciality{{[2, 3].includes( businessProfile.businessactivitytype!) ? '1' : '3'}}"
                class="tabcontent" *ngIf="[1, 2, 3].includes( businessProfile.businessactivitytype!)">
                <div class="widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="text-right mt-1">
                        <button type="button" class="theme-btn forward" (click)="validate('areaofspeciality3'); save();"
                            *ngIf="businessProfile.businessactivitytype == 1">Save</button>

                        <button type="button" class="theme-btn forward"
                            (click)="validate('areaofspeciality1'); tapChange($event, 'areaofspeciality2', 'areaofspeciality1')"
                            *ngIf="businessProfile.businessactivitytype == 2">Next
                            <i class="fas fa-arrow-right-long"></i></button>

                        <button type="button" class="theme-btn forward" (click)="validate('areaofspeciality1'); save();"
                            *ngIf="businessProfile.businessactivitytype == 3">Save</button>
                    </div>

                    <div class="checkout-form">

                        <h6 class="fw-bold my-4">Vehicle Type <span class="text-danger">*</span></h6>
                        <div class="row">
                            <div class="col-6" *ngFor="let vt of vehicleTypes">
                                <div class="border rounded m-1 p-2 vehicle-type text-center" role="button"
                                    [attr.vtype]="vt.vehicletypeen" (click)="selectVehicleType($event); validate('vehicleType');
                                    tapChange($event, 'images', 'vehicleType');">
                                    <img [src]="'assets/img/mootori/bodytypes/' + vt.vehicletypeimage" height="70"
                                        width="80" style="width: 80px; height: 70px;">
                                    <span class="d-block text-nowrap"
                                        style="font-size: 9px;">{{vt.vehicletypeen}}</span>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Brand <span class="text-danger">*</span></label>
                                    <div>
                                        <ng-select2 [data]="brandsList" [placeholder]="'Select Brand'"
                                            [options]="options" [value]="brandValues"
                                            (valueChanged)="brandChange($event)">
                                        </ng-select2>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6" *ngIf="businessProfile.businessactivitytype == 3">
                                <h6 class="fw-bold my-4">Conditions <span class="text-danger">*</span></h6>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" name="conditions" type="checkbox" value="0"
                                                id="conditions0">
                                            <label class="form-check-label" for="conditions0">
                                                New
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" name="conditions" type="checkbox" value="1"
                                                id="conditions1">
                                            <label class="form-check-label" for="conditions1">
                                                Used
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-right mt-5">
                        <button type="button" class="theme-btn forward" (click)="validate('areaofspeciality3'); save();"
                            *ngIf="businessProfile.businessactivitytype == 1">Save</button>

                        <button type="button" class="theme-btn forward"
                            (click)="validate('areaofspeciality1'); tapChange($event, 'areaofspeciality2', 'areaofspeciality1')"
                            *ngIf="businessProfile.businessactivitytype == 2">Next
                            <i class="fas fa-arrow-right-long"></i></button>

                        <button type="button" class="theme-btn forward" (click)="validate('areaofspeciality1'); save();"
                            *ngIf="businessProfile.businessactivitytype == 3">Save</button>
                    </div>
                </div>
            </div>

            <div id="areaofspeciality2" class="tabcontent" *ngIf="businessProfile.businessactivitytype == 2">
                <div class="widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="text-right mt-1">
                        <button type="button" class="theme-btn forward"
                            (click)="validate('areaofspeciality2'); tapChange($event, 'areaofspeciality3', 'areaofspeciality2')">Next
                            <i class="fas fa-arrow-right-long"></i></button>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <h6 class="fw-bold mt-4">Engine Type <span class="text-danger">*</span></h6>
                            <div class="row">
                                <div class="col-12" *ngFor="let et of engineType; let i = index;">
                                    <div class="form-check">
                                        <input class="form-check-input" name="enginetype" type="checkbox" value="{{i}}"
                                            id="enginetype{{i}}" [checked]="isChecked('engineType',i)">
                                        <label class="form-check-label" for="enginetype{{i}}">
                                            {{et}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <h6 class="fw-bold mt-4">Delivery Channel <span class="text-danger">*</span></h6>
                            <div class="row">
                                <div class="col-12" *ngFor="let dc of deliveryChannel; let i = index;">
                                    <div class="form-check">
                                        <input class="form-check-input" name="deliverychannel" type="checkbox"
                                            value="{{i}}" id="deliverychannel{{i}}" [checked]="isChecked('deliveryChannel',i)">
                                        <label class="form-check-label" for="deliverychannel{{i}}">
                                            {{dc}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <h6 class="fw-bold mt-4">Conditions <span class="text-danger">*</span></h6>
                            <div class="row">
                                <div class="col-6">
                                    <h6 class="fw-bold">New</h6>
                                    <div class="row">
                                        <div class="col-12" *ngFor="let nc of newCons; let i = index;">
                                            <div class="form-check">
                                                <input class="form-check-input" name="newCons" type="checkbox"
                                                    value="{{i}}" id="newCons{{i}}" [checked]="isChecked('newCons',i)">
                                                <label class="form-check-label" for="newCons{{i}}">
                                                    {{nc}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <h6 class="fw-bold">Used</h6>
                                    <div class="row">
                                        <div class="col-12" *ngFor="let uc of useCons; let i = index;">
                                            <div class="form-check">
                                                <input class="form-check-input" name="useCons" type="checkbox"
                                                    value="{{i}}" id="useCons{{i}}" [checked]="isChecked('useCons',i)">
                                                <label class="form-check-label" for="useCons{{i}}">
                                                    {{uc}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-group">
                                <h6 class="fw-bold mt-4 mb-2">Delivery Charge <span class="text-danger">*</span></h6>

                                <div class="input-group mb-3">
                                    <input type="number" class="form-control" placeholder="Delivery Charge"
                                        id="txt_deliverycharge" [value]="businessProfile.deliverycharges">
                                    <span class="input-group-text">AED</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-right mt-5">
                        <button type="button" class="theme-btn forward"
                            (click)="validate('areaofspeciality2'); tapChange($event, 'areaofspeciality3', 'areaofspeciality2')">Next
                            <i class="fas fa-arrow-right-long"></i></button>
                    </div>
                </div>
            </div>

            <div id="areaofspeciality1" class="tabcontent" *ngIf="businessProfile.businessactivitytype == 4">
                <div class="widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="text-right mt-1">
                        <button type="button" class="theme-btn forward"
                            (click)="validate('areaofspeciality1'); tapChange($event, 'areaofspeciality2', 'areaofspeciality1')">Next
                            <i class="fas fa-arrow-right-long"></i></button>
                    </div>

                    <div class="checkout-form">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Brand <span class="text-danger">*</span></label>
                                    <div>
                                        <ng-select2 [data]="brandsList" [placeholder]="'Select Brand'"
                                            [options]="options" (valueChanged)="brandChange($event)">
                                        </ng-select2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-right mt-5">
                        <button type="button" class="theme-btn forward"
                            (click)="validate('areaofspeciality1'); tapChange($event, 'areaofspeciality2', 'areaofspeciality1')">Next
                            <i class="fas fa-arrow-right-long"></i></button>
                    </div>
                </div>
            </div>

            <div id="areaofspeciality2" class="tabcontent" *ngIf="businessProfile.businessactivitytype == 4">
                <div class="widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="text-right mt-1">
                        <button type="button" class="theme-btn forward"
                            (click)="validate('areaofspeciality2'); tapChange($event, 'areaofspeciality3', 'areaofspeciality2')">Next
                            <i class="fas fa-arrow-right-long"></i></button>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <h6 class="fw-bold mt-4">Conditions <span class="text-danger">*</span></h6>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-check">
                                        <input class="form-check-input" name="conditions" type="checkbox" value="0"
                                            id="conditions0">
                                        <label class="form-check-label" for="conditions0">
                                            New
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-check">
                                        <input class="form-check-input" name="conditions" type="checkbox" value="1"
                                            id="conditions1">
                                        <label class="form-check-label" for="conditions1">
                                            Used
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <h6 class="fw-bold mt-4">Delivery Channel <span class="text-danger">*</span></h6>
                            <div class="row">
                                <div class="col-12" *ngFor="let dc of deliveryChannel; let i = index;">
                                    <div class="form-check">
                                        <input class="form-check-input" name="deliverychannel" type="checkbox"
                                            value="{{i}}" id="deliverychannel{{i}}">
                                        <label class="form-check-label" for="deliverychannel{{i}}">
                                            {{dc}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-group">
                                <h6 class="fw-bold mt-4 mb-2">Delivery Charge <span class="text-danger">*</span></h6>

                                <div class="input-group mb-3">
                                    <input type="number" class="form-control" placeholder="Delivery Charge"
                                        id="txt_deliverycharge">
                                    <span class="input-group-text">AED</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-right mt-5">
                        <button type="button" class="theme-btn forward"
                            (click)="validate('areaofspeciality2'); tapChange($event, 'areaofspeciality3', 'areaofspeciality2')">Next
                            <i class="fas fa-arrow-right-long"></i></button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>