// import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component } from '@angular/core';
import { DynamicScriptLoaderService } from 'src/services/dynamic-script-loader.service';

@Component({
  selector: 'app-extra-bar',
  templateUrl: './extra-bar.component.html',
  styleUrls: ['./extra-bar.component.css']
})
export class ExtraBarComponent {
  currentUser: any = null;

  constructor(private dynamicScriptLoader: DynamicScriptLoaderService/*, private socialAuthService: SocialAuthService*/) {
    this.currentUser = JSON.parse(localStorage.getItem('user')!);
  }

  logout() {
    localStorage.removeItem('user');

    // try {
    //   this.signOut();
    // } catch (error) {

    // }

    window.location.href = "home";
  }

  signOut(): void {
    // this.socialAuthService.signOut();
  }
}
