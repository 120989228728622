import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BusinessProfileService } from 'src/services/business-profile.service';

declare var jQuery: any;

@Component({
  selector: 'app-auto-spare-parts',
  templateUrl: './auto-spare-parts.component.html',
  styleUrls: ['./auto-spare-parts.component.css']
})
export class AutoSparePartsComponent {
  USERSFILESURL: string = environment.USERSFILESURL;
  FILESURL: string = environment.FILESURL;

  currentUser: any = null;

  allAutoSpareParts: any[] = [];
  filterAutoSpareParts: any[] = [];
  pageAutoSpareParts: any[] | null = [];

  itemsPerPage: number = 12;
  pageCount: number = 0;
  currentPage: number = 1;

  filterApplied: boolean = false;
  locationSelectedItem: any;

  nameFilter: string = "";

  categories: any[] = [];
  selectedCatrgories: any[] = [];

  brands: any[] = [];
  branSelectedItem: any;
  filterBrands: any[] = [];

  randomQueryParam = `?v=${Math.random().toString(36).substring(7)}`;

  constructor(private businessProfileService: BusinessProfileService) {
    this.currentUser = JSON.parse(localStorage.getItem('user')!);
  }

  async ngOnInit(): Promise<void> {
    document.querySelector(".preloader")?.removeAttribute("style");

    let selectedCity = localStorage.getItem('city') ?? "All";

    this.allAutoSpareParts = await this.businessProfileService.getBunissProfileByTypeAsync(2, -1, -1, selectedCity != "All" ? [{ "key": "location", "value": selectedCity }] : null);

    if (this.allAutoSpareParts && this.allAutoSpareParts.length > 0) {
      let promotedAutoServices: any[] = this.allAutoSpareParts.filter((a) => a.isPromoted);
      promotedAutoServices = promotedAutoServices.sort((a, b) => b.businessid - a.businessid);

      let otherAutoServices: any[] = this.allAutoSpareParts.filter((a) => !a.isPromoted);
      otherAutoServices = otherAutoServices.sort((a, b) => b.businessid - a.businessid);

      this.allAutoSpareParts = promotedAutoServices.concat(otherAutoServices);

      this.categories = await this.businessProfileService.getAllCategoriesAsync(2);

      /**,
       "Motorcycle",
       "ATV/UTV",
       "Scooter",
       "Personal Watercraft",
       "Golf Cart",
       "Heavy Vehicle" */
      this.brands = await this.businessProfileService.getBransByTypesAsync(["Car"]);

      this.brands = this.brands.filter(b => b.brandname != "All");

      this.filterBrands = this.brands;
    }

    this.filterAutoSpareParts = this.allAutoSpareParts;

    if (this.filterAutoSpareParts) {
      const quotient = Math.floor(this.filterAutoSpareParts.length / this.itemsPerPage);
      const remainder = this.filterAutoSpareParts.length % this.itemsPerPage;

      this.pageCount = quotient + (remainder > 0 ? 1 : 0);

      this.pageAutoSpareParts = this.paginate(this.filterAutoSpareParts, this.currentPage, this.itemsPerPage);
    }

    let _self = this;
    setTimeout(() => {
      (function ($) {
        $('#list_pagination').niceSelect();

        $('#list_pagination').on("change", (e: any) => {
          _self.goToPage(e);
        });
      })(jQuery);
    }, 100);

    document.querySelector(".preloader")?.setAttribute("style", "display: none;");
  }

  geoToDetails(event: any, services: any) {
    let ctrl = event.target;

    if (ctrl.getAttribute("name") != "location")
      window.location.href = "business/spareparts/details/" + services.businessid;
  }

  paginate(array: any[], pageNumber: number, itemsPerPage: number) {
    var begin = ((pageNumber - 1) * itemsPerPage);
    var end = begin + itemsPerPage;
    return array.slice(begin, end);
  }

  goToNextPage() {
    if (this.filterAutoSpareParts && this.currentPage < this.pageCount) {
      this.currentPage++;

      this.pageAutoSpareParts = this.paginate(this.filterAutoSpareParts, this.currentPage, this.itemsPerPage);

      let _self = this;
      (function ($) {
        $('#list_pagination').val(_self.currentPage);

        $('#list_pagination').niceSelect('update');

        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
      })(jQuery);
    }
  }

  goToPage(event: any) {
    let page = parseInt(event.target.value);
    if (this.filterAutoSpareParts) {
      this.currentPage = page;

      this.pageAutoSpareParts = this.paginate(this.filterAutoSpareParts, this.currentPage, this.itemsPerPage);

      (function ($) {
        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
      })(jQuery);
    }
  }

  goToPreviousPage() {
    if (this.filterAutoSpareParts && this.currentPage > 1) {
      this.currentPage--;

      this.pageAutoSpareParts = this.paginate(this.filterAutoSpareParts, this.currentPage, this.itemsPerPage);

      let _self = this;
      (function ($) {
        $('#list_pagination').val(_self.currentPage);

        $('#list_pagination').niceSelect('update');

        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
      })(jQuery);
    }
  }

  setNameFilter(event: any) {
    var val = event.target.value;
    this.nameFilter = val.trim();
  }

  clearNameFilter(val: any) {
    (<HTMLInputElement>document.getElementById("namefilter")).value = "";
    this.nameFilter = "val";
  }

  locationChanged(location: any) {
    if (location)
      this.locationSelectedItem = location;
    else
      this.locationSelectedItem = null;
  }

  clearSelectionLoaction() {
    this.locationSelectedItem = null;

    document.getElementsByName("location").forEach(el => {
      (<HTMLInputElement>el).checked = false;
    })
  }

  listFilter(type: string, ctrl: any) {
    let val: string = ctrl.target.value;

    switch (type) {
      case "brand":
        this.filterBrands = this.brands!.filter((m) => {
          return m.brandname.toLowerCase().startsWith(val);
        });
        break;
    }
  }

  brandChanged(brand: any) {
    if (brand) {
      this.branSelectedItem = {
        "brandid": brand.brandid,
        "brandname": brand.brandname
      };
    } else {
      this.branSelectedItem = null;
    }
  }

  clearSelectionBrand() {
    this.branSelectedItem = null;

    document.getElementsByName("brands").forEach(el => {
      (<HTMLInputElement>el).checked = false;
    })
  }

  fixCategoryName(name: string) {
    return name.replaceAll(/\/n\/r/g, '');
  }

  async selectCategory(event: MouseEvent) {
    let element = event.target as HTMLElement;

    let div = element;

    let img: HTMLImageElement;
    let span: HTMLSpanElement;

    if (element.tagName.toLowerCase() == "img" || element.tagName.toLowerCase() == "span") {
      div = element.parentNode as HTMLElement;
    }

    img = div.firstChild as HTMLImageElement;
    span = div.children[1] as HTMLSpanElement;

    let categoryid = div.getAttribute("category");

    let exist = this.selectedCatrgories!.find((c: any) => c.categoryid == categoryid);

    if (exist) {
      let index = this.selectedCatrgories.indexOf(exist);
      this.selectedCatrgories.splice(index, 1);

      div.classList.remove("border-danger");
      img!.src = img!.src.replace("-%20red.svg", "-%20gray.svg");
      span!.classList.remove("text-danger");
    } else {
      let category = this.categories!.find((c: any) => c.categoryid == categoryid);
      if (category) {
        this.selectedCatrgories.push({
          "categoryid": category.categoryid,
          "categoryname": category.categoryname
        });

        div.classList.add("border-danger");
        img!.src = img!.src.replace("-%20gray.svg", "-%20red.svg");
        span!.classList.add("text-danger");
      }
    }
  }

  clearSelectionCategory() {
    this.selectedCatrgories = [];

    document.querySelectorAll(".business-category").forEach(el => {
      (<HTMLInputElement>el).classList.remove("border-danger");
    })

    document.querySelectorAll(".business-category span").forEach(el => {
      (<HTMLInputElement>el).classList.remove("text-danger");
    })
  }

  applyFilter() {
    this.filterApplied = false;

    this.filterAutoSpareParts = this.allAutoSpareParts;

    if (this.nameFilter != "") {
      this.filterAutoSpareParts = this.filterAutoSpareParts!.filter((a: any) => {
        return a.businessname.toLowerCase().indexOf(this.nameFilter.toLowerCase()) >= 0;
      });

      this.filterApplied = true;
    }

    if (this.locationSelectedItem) {
      this.filterAutoSpareParts = this.filterAutoSpareParts!.filter((a: any) => {
        return a.businesscity.toLowerCase() == this.locationSelectedItem.toLowerCase();
      });

      this.filterApplied = true;
    }

    if (this.branSelectedItem) {
      var id = parseInt(this.branSelectedItem.brandid);

      this.filterAutoSpareParts = this.filterAutoSpareParts.filter((a: any) => {
        for (const b of a.businessbrands) {
          if (id == b.brandid) {
            return true;
          }
        }
        return false;
      });

      this.filterApplied = true;
    }

    if (this.selectedCatrgories.length > 0) {
      var ids = this.selectedCatrgories.map(c => c.categoryid);
      this.filterAutoSpareParts = this.filterAutoSpareParts.filter((a: any) => {
        for (const c of a.businesscategories) {
          if (ids.includes(c.categoryid)) {
            return true;
          }
        }
        return false;
      });

      this.filterApplied = true;
    }

    const quotient = Math.floor(this.filterAutoSpareParts.length / this.itemsPerPage);
    const remainder = this.filterAutoSpareParts.length % this.itemsPerPage;

    this.pageCount = quotient + (remainder > 0 ? 1 : 0);

    this.currentPage = 1;

    this.pageAutoSpareParts = this.paginate(this.filterAutoSpareParts!, this.currentPage, this.itemsPerPage);

    let _self = this;
    setTimeout(() => {
      (function ($) {
        $('#list_pagination').niceSelect('destroy');

        $('#list_pagination').val(_self.currentPage);

        $('#list_pagination').niceSelect();
      })(jQuery);
    }, 100);
  }

  clearFilter() {
    if (this.filterApplied) {
      this.filterAutoSpareParts = this.allAutoSpareParts;

      const quotient = Math.floor(this.filterAutoSpareParts.length / this.itemsPerPage);
      const remainder = this.filterAutoSpareParts.length % this.itemsPerPage;

      this.pageCount = quotient + (remainder > 0 ? 1 : 0);

      this.currentPage = 1;

      this.pageAutoSpareParts = this.paginate(this.filterAutoSpareParts!, this.currentPage, this.itemsPerPage);

      this.clearSelectionLoaction();

      this.clearSelectionBrand();

      this.clearSelectionCategory();

      this.filterApplied = false;
    }

    this.filterBrands = this.brands;

    (<HTMLInputElement>document.getElementById("namefilter")).value = "";
    (<HTMLInputElement>document.getElementById("brandfilter")).value = "";

    let _self = this;
    setTimeout(() => {
      (function ($) {
        $('#list_pagination').niceSelect('destroy');

        $('#list_pagination').val(_self.currentPage);

        $('#list_pagination').niceSelect();
      })(jQuery);
    }, 100);
  }

  getCategoryImage(name: string, color: string) {
    if (color.trim() != "") {
      let imageName = name.substring(0, name.lastIndexOf('.'))
      return `autospareparts/${imageName} - ${color}.svg${this.randomQueryParam}`;
    } else
      return `autospareparts/${name}${this.randomQueryParam}`;
  }

  getRandomQueryParam() {
    return `?v=${Math.random().toString(36).substring(7)}`;
  }
}
