<div class="pt-25 pb-120">
    <div class="row">
        <div class="col-lg-3">
            <div class="user-profile-sidebar">
                <ul class="user-profile-sidebar-list">
                    <li>
                        <a class="tablinks active" role="button" (click)="tapChange($event, 'vehicleType', null)"
                            id="tap_vehicleType" completed="true">
                            Vehicle Type <i class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('vehicleType')"></i></a>
                    </li>
                    <li>
                        <a class="tablinks" role="button" (click)="tapChange($event, 'images', 'vehicleType')"
                            id="tap_images" completed="true">Price & Photos <i
                                class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('images')"></i></a>
                    </li>
                    <li>
                        <a class="tablinks" role="button" (click)="tapChange($event, 'info', 'images')" id="tap_info"
                            completed="true">Basic
                            Information <i class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('info')"></i></a>
                    </li>
                    <li>
                        <a class="tablinks" role="button" (click)="tapChange($event, 'specification', 'info')"
                            id="tap_specification" completed="true">Specification <i
                                class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('specification')"></i></a>
                    </li>
                    <li>
                        <a class="tablinks" role="button" (click)="tapChange($event, 'bodyStyle', 'specification')"
                            id="tap_bodyStyle" completed="true">Body Style <i
                                class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('bodyStyle')"></i></a>
                    </li>
                    <li *ngIf="!hasShowrooms">
                        <a class="tablinks" role="button" (click)="tapChange($event, 'location', 'bodyStyle')"
                            id="tap_location" completed="true">Location
                            <i class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('location')"></i></a>
                    </li>
                    <li>
                        <a class="tablinks" role="button" (click)="tapChange($event, 'conditions', 'location')"
                            id="tap_conditions" completed="true">Conditions <i
                                class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('conditions')"></i></a>
                    </li>
                    <li>
                        <a class="tablinks" role="button" (click)="tapChange($event, 'highlights', 'conditions')"
                            id="tap_highlights" completed="true">Highlights <i
                                class="fa-duotone fa-circle-check text-success float-end"
                                *ngIf="isCompleted('highlights')"></i></a>
                    </li>

                </ul>
            </div>
        </div>
        <div class="col-lg-9">
            <div id="vehicleType" class="tabcontent" style="display: block;">
                <div class="checkout-widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="checkout-form">
                        <div class="text-right mt-1">
                            <button type="button" class="theme-btn forward" (click)="validate('vehicleType');
                                tapChange($event, 'images', 'vehicleType')">Next <i
                                    class="fas fa-arrow-right-long"></i></button>
                        </div>

                        <div class="row" *ngIf="hasShowrooms">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>My ShowRooms <span class="text-danger">*</span></label>
                                    <select class="select" id="ddl_showrooms">
                                        <option value="">Select ShowRoom</option>
                                        <option value="{{s.businessid}}" *ngFor="let s of showRooms; let i = index;"
                                            [selected]="i == 0">{{s.businessname}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <h6 class="fw-bold my-4">Vehicle Type <span class="text-danger">*</span></h6>
                        <div class="row">
                            <div class="col-6" *ngFor="let vt of vehicleTypes">
                                <div class="border rounded m-1 p-2 vehicle-type text-center" role="button"
                                    [attr.vtype]="vt.vehicletypeen" (click)="selectVehicleType($event); validate('vehicleType');
                                    tapChange($event, 'images', 'vehicleType');">
                                    <img [src]="'assets/img/mootori/bodytypes/' + vt.vehicletypeimage" height="70"
                                        width="80" style="width: 80px; height: 70px;">
                                    <span class="d-block text-nowrap"
                                        style="font-size: 9px;">{{vt.vehicletypeen}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="text-right mt-5">
                            <button type="button" class="theme-btn forward" (click)="validate('vehicleType');
                                tapChange($event, 'images', 'vehicleType')">Next <i
                                    class="fas fa-arrow-right-long"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="images" class="tabcontent">
                <div class="checkout-widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="checkout-form">
                        <div class="text-right mt-1">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('images'); tapChange($event, 'info', 'images')">Next
                                <i class="fas fa-arrow-right-long"></i></button>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Mailage <span class="text-danger">*</span></label>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" placeholder="Mailage"
                                            id="txt_mailage">
                                        <span class="input-group-text">KM</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label>Price <span class="text-danger">*</span></label>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" placeholder="Price" id="txt_price">
                                        <span class="input-group-text">AED</span>
                                    </div>

                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Cover Photo <span class="text-danger">*</span></label>
                                    <input class="form-control" type="file" accept="image/*"
                                        (change)="onChange($event, '_AutoPhoto', true)">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Photos <span class="text-danger">*</span></label>
                                    <input class="form-control" type="file" accept="image/*" multiple
                                        (change)="onChange($event, '_AutoPhoto')">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Inspection Report</label>
                                    <input class="form-control" type="file" accept="image/*, application/pdf"
                                        (change)="onChange($event, '_InspectionReport')">
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Color <span class="text-danger">*</span></label>
                                    <div>
                                        <div class="form-check form-check-inline"
                                            *ngFor="let c of colorList; let i = index;">
                                            <input class="form-check-input" type="radio" name="vcolor"
                                                id="inlineRadio{{i}}" value="{{i}}"
                                                [style]="'height: 25px; width: 25px; background-color:' + c + ';'">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="text-right mt-5">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('images'); tapChange($event, 'info', 'images')">Next
                                <i class="fas fa-arrow-right-long"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="info" class="tabcontent">
                <div class="checkout-widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="checkout-form">
                        <div class="text-right mt-1">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('info'); tapChange($event, 'specification', 'info')">Next <i
                                    class="fas fa-arrow-right-long"></i></button>
                        </div>

                        <form action="#">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Year <span class="text-danger">*</span></label>
                                        <div>
                                            <ng-select2 [(ngModel)]="selectedYear" [formControl]="formControl"
                                                [data]="years" [placeholder]="'Select Year'">
                                            </ng-select2>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Make <span class="text-danger">*</span></label>
                                        <div>
                                            <ng-select2 id="ddlMake" [data]="makesList" [value]="makeSelectedItem"
                                                [placeholder]="'Select Make'" (valueChanged)="makeChange($event)">
                                            </ng-select2>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Model <span class="text-danger">*</span></label>
                                        <div>
                                            <ng-select2 [data]="modelsList" [value]="modelSelectedItem?.id"
                                                [placeholder]="'Select Model'" (valueChanged)="modelChanged($event)">
                                            </ng-select2>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Trim</label>
                                        <div>
                                            <ng-select2 [data]="trimsList" [value]="trimSelectedItem?.id"
                                                [placeholder]="'Select Trim'" (valueChanged)="trimChanged($event)">
                                            </ng-select2>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>Desctiption <span class="text-danger">*</span></label>
                                        <textarea class="form-control" cols="30" rows="5" id="txt_desctiption"
                                            placeholder="Desctiption"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>Mobile Number <span class="text-danger">*</span></label>

                                        <div class="input-group mb-3">
                                            <span class="input-group-text">+971</span>
                                            <input type="number" class="form-control" placeholder="Your Mobile Number"
                                                id="txt_mobile">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>Email Address <span class="text-danger">*</span></label>
                                        <input type="email" class="form-control" placeholder="Your Email"
                                            id="txt_email">
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="text-right mt-5">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('info'); tapChange($event, 'specification', 'info')">Next <i
                                    class="fas fa-arrow-right-long"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="specification" class="tabcontent">
                <div class="checkout-widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="checkout-form">
                        <div class="text-right mt-1">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('specification'); tapChange($event, 'bodyStyle', 'specification')">Next
                                <i class="fas fa-arrow-right-long"></i></button>
                        </div>

                        <h6 class="fw-bold my-4">Car Specification <span class="text-danger">*</span></h6>
                        <div class="row">
                            <div class="col-12" *ngFor="let s of carSpe; let i = index;">
                                <div class="form-check">
                                    <input class="form-check-input" name="specification" type="radio" value="{{i}}"
                                        id="specification{{i}}">
                                    <label class="form-check-label" for="specification{{i}}">
                                        {{s}}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <h6 class="fw-bold my-4">Engine Type <span class="text-danger">*</span></h6>
                        <div class="row">
                            <div class="col-12" *ngFor="let et of engineType; let i = index;">
                                <div class="form-check">
                                    <input class="form-check-input" name="engineType" type="radio" value="{{i}}"
                                        id="engineType{{i}}">
                                    <label class="form-check-label" for="engineType{{i}}">
                                        {{et}}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="text-right mt-5">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('specification'); tapChange($event, 'bodyStyle', 'specification')">Next
                                <i class="fas fa-arrow-right-long"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="bodyStyle" class="tabcontent">
                <div class="checkout-widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="checkout-form">
                        <div class="text-right mt-1">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('bodyStyle'); tapChange($event, hasShowrooms ? 'conditions' : 'location', 'bodyStyle')">Next
                                <i class="fas fa-arrow-right-long"></i></button>
                        </div>

                        <h6 class="fw-bold my-4" *ngIf="vehicleBodyTypes.length > 0">Body Style <span
                                class="text-danger">*</span></h6>
                        <div class="row" *ngIf="vehicleBodyTypes.length > 0">
                            <div class="col-6" *ngFor="let vt of vehicleBodyTypes">
                                <div class="border rounded m-1 p-2 vehicle-body-type text-center" role="button"
                                    [attr.vtype]="vt.bodytypeen" (click)="selectVehicleBodyType($event)">
                                    <img [src]="'assets/img/mootori/bodytypes/' + vt.bodytypeimage" height="70"
                                        width="80" style="width: 80px; height: 70px;">
                                    <span class="d-block text-nowrap" style="font-size: 9px;">{{vt.bodytypeen}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Horse Power</label>
                                    <div class="input-group mb-3">
                                        <input type="number" class="form-control" placeholder="Horse Power"
                                            id="txt_power">
                                        <span class="input-group-text">HP</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>No of Cylinders</label>
                                    <select class="select" id="ddl_cylinders">
                                        <option value="">Select No of Cylinders</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="8">8</option>
                                        <option value="10">10</option>
                                        <option value="12">12</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="text-right mt-5">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('bodyStyle'); tapChange($event, hasShowrooms ? 'conditions' : 'location', 'bodyStyle')">Next
                                <i class="fas fa-arrow-right-long"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="location" class="tabcontent" *ngIf="!hasShowrooms">
                <div class="checkout-widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="checkout-form">
                        <div class="text-right mt-1">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('location'); tapChange($event, 'conditions', 'location')">Next <i
                                    class="fas fa-arrow-right-long"></i></button>
                        </div>

                        <div class="car-single-map">
                            <div class="contact-map">
                                <!-- <iframe [src]="mapURL()" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->

                                <div class="form-group">
                                    <label>Enter address</label>
                                    <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
                                        placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off"
                                        spellcheck="off" type="text" #search>
                                </div>

                                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom"
                                    [restriction]="componentRestrictions" (mapClick)="onMapClicked($event)">
                                    <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                                </agm-map>

                                <!-- <div>Latitude: {{latitude}}</div>
                                <div>Longitude: {{longitude}}</div> -->
                                <div class="mt-2">Selected Location: {{currentLocation}}</div>
                                <div class="mt-1">City: {{city}}</div>
                                <div class="mt-1">Country: {{country}}</div>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Address Details <span class="text-danger">*</span></label>
                                    <textarea class="form-control" cols="30" rows="5" id="txt_address"
                                        placeholder="Address Details"></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="text-right mt-5">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('location'); tapChange($event, 'conditions', 'location')">Next <i
                                    class="fas fa-arrow-right-long"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="conditions" class="tabcontent">
                <div class="checkout-widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="checkout-form">
                        <div class="text-right mt-1">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('conditions'); tapChange($event, 'highlights', 'conditions')">Next <i
                                    class="fas fa-arrow-right-long"></i></button>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <h6 class="fw-bold my-4">Body condition <span class="text-danger">*</span></h6>
                                <div class="row">
                                    <div class="col-12" *ngFor="let bc of bodycondition; let i = index;">
                                        <div class="form-check">
                                            <input class="form-check-input" name="bconditions" type="radio"
                                                value="{{i}}" id="bconditions{{i}}">
                                            <label class="form-check-label" for="bconditions{{i}}">
                                                {{bc}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <h6 class="fw-bold my-4">Mechanical Condition <span class="text-danger">*</span></h6>
                                <div class="row">
                                    <div class="col-12" *ngFor="let mc of mechanicalcondition; let i = index;">
                                        <div class="form-check">
                                            <input class="form-check-input" name="mconditions" type="radio"
                                                value="{{i}}" id="mconditions{{i}}">
                                            <label class="form-check-label" for="mconditions{{i}}">
                                                {{mc}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <h6 class="fw-bold my-4">Warranty <span class="text-danger">*</span></h6>
                                <div class="row">
                                    <div class="col-12" *ngFor="let w of warranty; let i = index;">
                                        <div class="form-check">
                                            <input class="form-check-input" name="warranty" type="radio" value="{{i}}"
                                                id="warranty{{i}}">
                                            <label class="form-check-label" for="warranty{{i}}">
                                                {{w}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <h6 class="fw-bold my-4">Steering Side <span class="text-danger">*</span></h6>
                                <div class="row">
                                    <div class="col-12" *ngFor="let ss of steeringside; let i = index;">
                                        <div class="form-check">
                                            <input class="form-check-input" name="steeringside" type="radio"
                                                value="{{i}}" id="steeringside{{i}}">
                                            <label class="form-check-label" for="steeringside{{i}}">
                                                {{ss}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <h6 class="fw-bold my-4">Transmission <span class="text-danger">*</span></h6>
                                <div class="row">
                                    <div class="col-12" *ngFor="let t of transmission; let i = index;">
                                        <div class="form-check">
                                            <input class="form-check-input" name="transmission" type="radio"
                                                value="{{i}}" id="transmission{{i}}">
                                            <label class="form-check-label" for="transmission{{i}}">
                                                {{t}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <h6 class="fw-bold my-4">No of Doors</h6>
                                    <input type="number" class="form-control" placeholder="No of Doors" id="txt_doors">
                                </div>
                            </div>
                        </div>

                        <div class="text-right mt-5">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('conditions'); tapChange($event, 'highlights', 'conditions')">Next <i
                                    class="fas fa-arrow-right-long"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="highlights" class="tabcontent">
                <div class="checkout-widget">
                    <ul *ngIf="errorsList.length > 0">
                        <li class="text-danger" *ngFor="let er of errorsList">{{er}}</li>
                    </ul>

                    <div class="checkout-form">
                        <div class="text-right mt-1">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('highlights'); save()">Save</button>
                        </div>

                        <form action="#">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Highlights</label>
                                        <div class="row">
                                            <div class="col-3" *ngFor="let f of features">
                                                <div class="border rounded m-1 p-2 vehicle-feature text-center"
                                                    role="button" [attr.feature]="f.featureid"
                                                    (click)="selectVehicleFeature($event)">
                                                    <img [src]="'assets/img/mootori/autofeatures/' + f.featureimagepath.replace('.svg', ' - gray.svg')"
                                                        height="70" width="80">

                                                    <span class="d-block text-nowrap"
                                                        style="font-size: 9px;">{{f.featurename}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="text-right mt-5">
                            <button type="button" class="theme-btn forward"
                                (click)="validate('highlights'); save()">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>