<div class="pt-20 pb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 mx-auto">
                <div class="site-heading text-center">
                    <h2 class="site-title text-nowrap">Garage Management System (GMS)</h2>
                    <div class="heading-divider"></div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="terms-content">
                    <div class="d-flex">
                        <img src="assets/img/icons/noun-5513022.svg" style="height: 30px; margin-right: 5px;" />
                        <h3>Mootori (GMS)</h3>
                    </div>
                    <p>
                        A comprehensive solution designed to streamline and enhance the operations of automotive repair
                        shops and garages. By integrating advanced features and user-friendly interfaces, Mootori
                        ensures that every aspect of garage management is covered, from customer relations to financial
                        reporting. This system is tailored to improve efficiency, increase customer satisfaction, and
                        drive business growth.
                    </p>

                    <div class="d-flex align-items-center justify-content-center">
                        <img src="assets/img/mootori/02.png" style="width: 80%;" />
                    </div>

                </div>

                <div class="terms-content">
                    <div class="d-flex">
                        <img src="assets/img/icons/noun-5513022.svg" style="height: 30px; margin-right: 5px;" />
                        <h3>Why Mootori?</h3>
                    </div>

                    <div class="d-flex">
                        <img src="assets/img/icons/noun-automotive-6821262.svg" style="height: 30px; margin-right: 5px;" />
                        <h3>Expert Automotive Solutions</h3>
                    </div>
                    <p>
                        At Mootori, we specialize in providing cutting-edge automotive digital systems that transform
                        how motorist services are delivered. Our solutions are designed to enhance every aspect of the
                        automotive experience.
                    </p>

                    <div class="d-flex">
                        <img src="assets/img/icons/noun-car-insurance-5453332.svg" style="height: 30px; margin-right: 5px;" />
                        <h3>All-in-One Motorist Services</h3>
                    </div>
                    <p>
                        Discover a comprehensive range of services with Mootori. From buying and selling vehicles to
                        maintenance, repair, and access to essential parts and tools, we have you covered.
                    </p>
                   
                    <div class="d-flex">
                        <img src="assets/img/icons/noun-trust-5832068.svg" style="height: 30px; margin-right: 5px;" />
                        <h3>Trusted Providers Network</h3>
                    </div>
                    <p>
                        Connect seamlessly with reliable motor business providers through our extensive network. We
                        ensure you find trustworthy service options that meet your needs.
                    </p>

                    <div class="d-flex">
                        <img src="assets/img/icons/noun-browser-5183262.svg" style="height: 30px; margin-right: 5px;" />
                        <h3>Easy Comparisons</h3>
                    </div>
                    <p>
                        Easily compare the best service options nearby to make informed decisions. With Mootori, finding
                        the right service is quick and simple.
                    </p>
                </div>

                <div class="terms-content">
                    <div class="d-flex">
                        <img src="assets/img/icons/noun-5513022.svg" style="height: 30px; margin-right: 5px;" />
                        <h3>Why Choose Mootori GMS?</h3>
                    </div>
                    <ul>
                        <li style="list-style:inside;">
                            <b>Local Presence & Support: </b> Enjoy the convenience of local support tailored to your
                            needs.
                        </li>
                        <li style="list-style:inside;">
                            <b>No Setup Fees: </b> Get started without any initial costs.
                        </li>
                        <li style="list-style:inside;">
                            <b>Mobile Accessibility: </b> Manage your garage from anywhere with our mobile-friendly
                            interface.
                        </li>
                        <li style="list-style:inside;">
                            <b>Competitive Price: </b> Benefit from our cost-effective solutions.
                        </li>
                        <li style="list-style:inside;">
                            <b>Free Directory Listing: </b> Gain visibility with a free listing in our directory.
                        </li>
                        <li style="list-style:inside;">
                            <b>Multi-Branch Management: </b> Efficiently manage multiple branches from a single
                            platform.
                        </li>
                    </ul>
                </div>

                <div class="terms-content">
                    <div class="d-flex">
                        <img src="assets/img/icons/noun-5513022.svg" style="height: 30px; margin-right: 5px;" />
                        <h3>Key Features</h3>
                    </div>

                    <ul>
                        <li style="list-style:inside;">
                            <b>Work Estimation: </b> Generate precise work estimates effortlessly.
                        </li>
                        <li style="list-style:inside;">
                            <b>Manage Job Cards: </b> Keep track of all job details in one place.
                        </li>
                        <li style="list-style:inside;">
                            <b>Digital Invoicing: </b> Simplify billing with digital invoicing solutions.
                        </li>
                        <li style="list-style:inside;">
                            <b>Manage Payments: </b> Efficiently handle all payment processes.
                        </li>
                        <li style="list-style:inside;">
                            <b>Customer Management: </b> Enhance customer relations with effective management tools.
                        </li>
                        <li style="list-style:inside;">
                            <b>Service Warranty: </b> Provide reliable service warranties.
                        </li>
                        <li style="list-style:inside;">
                            <b>Service Reminders: </b> Send timely service reminders via email and SMS.
                        </li>
                        <li style="list-style:inside;">
                            <b>Email and SMS: </b> Communicate seamlessly with your customers.
                        </li>
                        <li style="list-style:inside;">
                            <b>Manage Inventory: </b> Maintain accurate inventory records.
                        </li>
                        <li style="list-style:inside;">
                            <b>Car Inspection: </b> Conduct thorough car inspections with ease.
                        </li>
                        <li style="list-style:inside;">
                            <b>Service History: </b> Access comprehensive service histories.
                        </li>
                        <li style="list-style:inside;">
                            <b>Dashboards and Reporting: </b> Get insightful analytics with detailed dashboards and
                            reports.
                        </li>
                        <li style="list-style:inside;">
                            <b>Unlimited Users: </b> Add as many users as needed without restrictions.
                        </li>
                        <li style="list-style:inside;">
                            <b>Support and Upgrades: </b> Enjoy continuous support and regular upgrades.
                        </li>
                    </ul>
                </div>

                <div class="terms-content">
                    <div class="d-flex">
                        <img src="assets/img/icons/noun-5513022.svg" style="height: 30px; margin-right: 5px;" />
                        <h3>Ready to Transform Your Garage Management?</h3>
                    </div>
                    <p>
                        Discover how Mootori Garage Management System can revolutionize your operations. <b>Join Now</b>
                        or <b>Book an Appointment</b> to learn more and get started on your journey to better garage
                        management.

                    </p>
                </div>

                <div class="terms-content">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="download-btn">
                            <a role="button" style="height: 66px; color: #fff;" data-bs-toggle="modal"
                                data-bs-target="#AppointmentModal">
                                <i class="fa-regular fa-calendar-check fs-5"></i>
                                <div class="download-btn-content">
                                    <strong>Book an Appointment</strong>
                                </div>
                            </a>

                            <a href="https://gms.mootori.com/register/c" target="_blank" style="height: 66px;">
                                <i class="fa-solid fa-briefcase fs-5"></i>
                                <div class="download-btn-content">
                                    <strong>Join Now</strong>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="AppointmentModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="AppointmentModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="AppointmentModalLabel">Book an Appointment</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="checkout-form" *ngIf="!bookCompleted">
                    <form id="appointment_form">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Name <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" id="txt_name" name="txt_name"
                                        placeholder="Enter Name">
                                </div>
                            </div>


                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="txt_garage_name" class="form-label">Garage Name <span
                                            class="text-danger">*</span></label>
                                    <input type="text" class="form-control" id="txt_garage_name" name="txt_garage_name"
                                        placeholder="Enter Garage Name">
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="txt_mobile_number" class="form-label">Mobile Number <span
                                            class="text-danger">*</span></label>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text">+971</span>
                                        <input type="number" class="form-control" id="txt_mobile_number"
                                            name="txt_mobile_number" placeholder="Enter Mobile Number" appOnlyNumbers>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="txt_email_address" class="form-label">Email Address <span
                                            class="text-danger">*</span></label>
                                    <input type="text" class="form-control" id="txt_email_address"
                                        name="txt_email_address" placeholder="Enter Email Address">
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ddl_emirate" class="form-label">Emirate <span
                                            class="text-danger">*</span></label>
                                    <select class="form-select" id="ddl_emirate" name="ddl_emirate"
                                        (change)="setEmirate($event)">
                                        <option value="" selected>Select Emirate</option>
                                        <option value="{{l}}" *ngFor="let l of locations">{{l}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="txt_email_address" class="form-label">Meeting Type <span
                                            class="text-danger">*</span></label>
                                    <div class="meeting-type">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="meeting_type"
                                                id="meeting_type1" value="1" (change)="setMeetingType($event)">
                                            <label class="form-check-label" for="meeting_type1">Online</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="meeting_type"
                                                id="meeting_type2" value="2" (change)="setMeetingType($event)">
                                            <label class="form-check-label" for="meeting_type2">Physical</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <label for="txt_date" class="form-label">Date <span class="text-danger">*</span></label>
                                <div class="input-group date">
                                    <input type="text" class="form-control" id="txt_date" readonly>
                                    <span class="input-group-text input-group-addon">
                                        <i class="fa-regular fa-calendar-days"></i>
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="txt_email_address" class="form-label">Time <span
                                            class="text-danger">*</span></label>
                                    <select class="form-select" id="ddl_time" name="ddl_time">
                                        <option value="" selected>Select Time</option>
                                        <option value="16" *ngIf="canSelect(16) && !isBooked(16)">
                                            04:00 PM - 05:00 PM
                                        </option>
                                        <option value="17" *ngIf="canSelect(17) && !isBooked(17)">
                                            05:00 PM - 06:00 PM
                                        </option>
                                        <option value="18" *ngIf="canSelect(18) && !isBooked(18)">
                                            06:00 PM - 07:00 PM
                                        </option>
                                        <option value="19" *ngIf="canSelect(19) && !isBooked(19)">
                                            07:00 PM - 08:00 PM
                                        </option>
                                        <option value="20" *ngIf="canSelect(20) && !isBooked(20)">
                                            08:00 PM - 09:00 PM
                                        </option>
                                        <option value="21" *ngIf="canSelect(21) && !isBooked(21)">
                                            09:00 PM - 10:00 PM
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="alert alert-success d-flex align-items-center" role="alert" *ngIf="bookCompleted">
                    <i class="bi bi-check-circle-fill"></i>
                    <div>
                        Your appointment request has been completed. Please wait for confirmation.
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-red" (click)="save()">Submit</button>
            </div>
        </div>
    </div>
</div>