<div class="about-area py-120" id="about">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-left wow fadeInLeft" data-wow-delay=".25s">
                    <div class="about-img">
                        <img src="assets/img/mootori/about-01.png" alt="Mootori">
                    </div>
                    <!-- <div class="about-experience">
                        <div class="about-experience-icon">
                            <i class="flaticon-car"></i>
                        </div>
                        <b>30 Years Of <br> Quality Service</b>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-right wow fadeInRight" data-wow-delay=".25s">
                    <div class="site-heading mb-3">
                        <span class="site-title-tagline justify-content-start">
                            <i class="flaticon-drive"></i> About Us
                        </span>
                        <h2 class="site-title">
                            Everything <span>A Motorist</span> Needs
                        </h2>
                    </div>
                    <p class="about-text">
                        Mootori provides everything a motorist needs to discover, buy and sell, maintain, repair, spare
                        parts, accessories, garage tools and services at your doorstep.
                    </p>
                    <p class="about-text">
                        We connect car lovers and owners to a wide range of trusted motor business providers.
                    </p>
                    <p class="about-text">
                        The platform brings together the nearby service centers, spare parts shops, showrooms, and tools
                        to users and helps them find and compare the best options they need.
                    </p>

                    <div class="site-heading my-3">
                        <span class="site-title-tagline justify-content-start">
                            <i class="flaticon-drive"></i> VISION
                        </span>
                    </div>
                    <p class="about-text">
                        The most trusted digital network of motor business providers that can easily fulfill all
                        motorists' needs.
                    </p>

                    <div class="site-heading my-3">
                        <span class="site-title-tagline justify-content-start">
                            <i class="flaticon-drive"></i> MISSION
                        </span>
                    </div>
                    <p class="about-text">
                        To empower motor business providers and motorists digitally and deliver a unique experience by
                        providing easily accessible services.
                    </p>



                    <!-- <div class="about-list-wrapper">
                        <ul class="about-list list-unstyled">
                            <li>
                                At vero eos et accusamus et iusto odio.
                            </li>
                            <li>
                                Established fact that a reader will be distracted.
                            </li>
                            <li>
                                Sed ut perspiciatis unde omnis iste natus sit.
                            </li>
                        </ul>
                    </div>
                    <a href="about.html" class="theme-btn mt-4">Discover More<i class="fas fa-arrow-right-long"></i></a> -->
                </div>
            </div>
        </div>
    </div>
</div>


<div class="counter-area pt-30 pb-30">
    <div class="container align-items-center">
        <div class="row">
            <div class="fivecolumns col-sm-6">
                <div class="counter-box">
                    <div class="icon">
                        <img src="assets/img/mootori/car_service.svg" style="height: 75px;" alt="car service">
                    </div>
                    <div>
                        <!-- <span class="counter" data-count="+" data-to="500" data-speed="3000">500</span> -->
                        <h6 class="title">Service Centers </h6>
                    </div>
                </div>
            </div>
            <div class="fivecolumns col-sm-6">
                <div class="counter-box">
                    <div class="icon">
                        <img src="assets/img/mootori/spare_part.svg" style="height: 75px;" alt="spare part">
                    </div>
                    <div>
                        <!-- <span class="counter" data-count="+" data-to="900" data-speed="3000">900</span> -->
                        <h6 class="title">Spare Parts</h6>
                    </div>
                </div>
            </div>
            <div class="fivecolumns col-sm-6">
                <div class="counter-box">
                    <div class="icon">
                        <img src="assets/img/mootori/car_sale.svg" style="height: 75px;" alt="car sale">
                    </div>
                    <div>
                        <!-- <span class="counter" data-count="+" data-to="1500" data-speed="3000">1500</span> -->
                        <h6 class="title">Motor Listing</h6>
                    </div>
                </div>
            </div>
            <div class="fivecolumns col-sm-6">
                <div class="counter-box">
                    <div class="icon">
                        <img src="assets/img/mootori/showrooms.svg" style="height: 75px;" alt="showrooms">
                    </div>
                    <div>
                        <!-- <span class="counter" data-count="+" data-to="30" data-speed="3000">30</span> -->
                        <h6 class="title">Showrooms</h6>
                    </div>
                </div>
            </div>
            <div class="fivecolumns col-sm-6">
                <div class="counter-box">
                    <div class="icon">
                        <img src="assets/img/mootori/car_tools.svg" style="height: 75px;" alt="car tools">
                    </div>
                    <div>
                        <!-- <span class="counter" data-count="+" data-to="30" data-speed="3000">30</span> -->
                        <h6 class="title">Garage Tools</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-area py-120" id="motorists">
    <div class="container">
        <div class="contact-wrapper">
            <div class="row">
                <div class="col-lg-6 align-self-center">
                    <div class="contact-img">
                        <img src="assets/img/contact/01.jpg" alt="Mootori">
                    </div>
                </div>
                <div class="col-lg-6 align-self-center">
                    <div class="contact-form">
                        <div class="contact-form-header">
                            <h2>What we offer Motorists?</h2>
                        </div>
                        <div class="about-list-wrapper">
                            <ul class="about-list list-unstyled">
                                <li>
                                    Unlimited Listing for Motors.
                                </li>
                                <li>
                                    Access to Listed Motors from Dealers and Individuals.
                                </li>
                                <li>
                                    Find Best Estimations for Motor Services.
                                </li>
                                <li>
                                    Get Variety of Quotations for Spare Parts and Tools.
                                </li>
                                <li>
                                    Benefit from a Wide Range of Promotions and Offers.
                                </li>
                                <li>
                                    Flexible Secure Digital Payment Options.
                                </li>
                                <li>
                                    Request for Quick Services and Packages.
                                </li>
                                <li>
                                    Keep Track of Services and Routine Maintenance.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="cta-area pt-25 mb-40" id="businesses">
    <div class="container">
        <div class="cta-wrapper" style="background-image: url(assets/img/cta/01.jpg);">
            <div class="row align-items-center">
                <div class="col-lg-12 text-center text-lg-start">
                    <div class="cta-text">
                        <h1>Why businesses join Mootori?</h1>
                    </div>
                    <div class="about-list-wrapper">
                        <ul class="about-list list-unstyled">
                            <li>
                                Unlimited Listing for Motors.
                            </li>
                            <li>
                                Access to Listed Motors from Dealers and Individuals.
                            </li>
                            <li>
                                Find Best Estimations for Motor Services.
                            </li>
                            <li>
                                Get Variety of Quotations for Spare Parts and Tools.
                            </li>
                            <li>
                                Benefit from a Wide Range of Promotions and Offers.
                            </li>
                            <li>
                                Flexible Secure Digital Payment Options.
                            </li>
                            <li>
                                Request for Quick Services and Packages.
                            </li>
                            <li>
                                Keep Track of Services and Routine Maintenance.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>