import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DraftDataService {

  private APIURL: string = environment.APIURL;
  private APIKEY: string = environment.APIKEY;

  constructor(private http: HttpClient) { }

  getBusinessDraftData(tradelicensenumber: string, tradelicensecity: string): Observable<any> {
    const theUrl =
      this.APIURL + "draftdata/read_business_draft.php?tradelicensenumber=" + tradelicensenumber + "&tradelicensecity=" + tradelicensecity;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getBusinessDraftDataAsync(tradelicensenumber: string, tradelicensecity: string) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getBusinessDraftData(tradelicensenumber, tradelicensecity)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Not Found
          if (msg.status == 404)
            resolve(null);
          else {
            // Error
            reject(msg);
          }
        });
    });
    return await promise;
  }

  addDraftData(data: any): Observable<any> {
    const theUrl =
      this.APIURL + "draftdata/create.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    });
  }

  async addDraftDataAsync(data: any) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.addDraftData(data)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  updateDraftData(data: any): Observable<any> {
    const theUrl =
      this.APIURL + "draftdata/update.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    });
  }

  async updateDraftDataAsync(data: any) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.updateDraftData(data)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  deleteDraftData(draftid: number): Observable<any> {
    const theUrl =
      this.APIURL + "draftdata/delete.php?draftid=" + draftid;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.post<any>(theUrl, null, {
      headers: headers,
      observe: 'response'
    });
  }

  async deleteDraftDataAsync(draftid: number) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.deleteDraftData(draftid)).then(res => {
        debugger;
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          debugger;
          reject(msg);
        });
    });
    return await promise;
  }
}
