<style type="text/css">
    .navwrap {
        width: 100%;
        max-width: calc(100% - 10px);
        margin: auto;
        overflow-y: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        /* for iOS */
        padding-bottom: 14px;

        background: var(--color-white);
        margin-bottom: 25px;
        padding: 10px 10px 10px 15px;
        border-radius: 10px;
    }

    .nav {
        display: table;
        /*white-space fix*/
        width: 100%;
        text-align: left;
        word-spacing: -9em;
        /*white-space fix*/
    }

    .nav li {
        display: inline-block;
        text-align: left;
        word-spacing: normal;
        vertical-align: middle;
        /*white-space fix*/
    }

    .nav li>a {
        position: relative;
    }

    .nav li:hover,
    .nav>li>a:hover {
        z-index: 105;
    }

    .nav li li {
        display: block;
    }

    .nav a {
        display: block;
        padding: 7px 10px;
        /* background: red; */
        /* color: #fff;
        border: 1px solid #000; */
        text-decoration: none;
        white-space: nowrap;
        position: relative;

        margin-right: 22px;
        /* padding: 30px 0 30px 0; */
        font-size: 17px;
        font-weight: 600;
        color: var(--color-dark);
        text-transform: capitalize;
    }

    .nav li:hover>a,
    .nav a:hover {
        color: var(--theme-color);
    }

    .nav ul {
        position: absolute;
        z-index: 104;
        left: -999em;
        top: 0;
        opacity: 0;
        transition: opacity 0.5s, left 0s 0.5s, top 0.5s;
    }

    .nav li:hover>ul {
        left: auto;
        top: auto;
        opacity: 1;
        transition: opacity 0.5s linear, top 0.5s;
    }

    @media (max-width: 991px) {
        .nav li:hover>ul {
            left: 17px;
            top: auto;
            opacity: 1;
            transition: opacity 0.5s linear, top 0.5s;
        }
    }

    /* .nav ul ul {
        transition: opacity 0.4s, left 0s 0.4s, margin 0s 0.4s;
        z-index: 103;
    } */

    /* .nav li li:hover ul {
        top: auto;
        margin-left: 100%;
        opacity: 1;
        transition: opacity 0.5s linear, margin 0.5s;
    } */

    /* arrows */
    .nav li a:first-child:not(:last-child) {
        padding-right: 20px;
        /* make space for arrows*/
    }

    .nav li a:first-child:not(:last-child):after {
        content: "";
        position: absolute;
        right: 3px;
        top: 50%;
        margin-top: -6px;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid var(--color-dark);
    }

    .nav>li>a:first-child:not(:last-child):after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid var(--color-dark);
        border-bottom: none;
        margin-top: -3px;
    }

    .nav li:hover a:first-child:not(:last-child):after {
        border-left-color: var(--theme-color);
    }

    .nav>li:hover>a:first-child:not(:last-child):after {
        border-left-color: transparent;
        border-top-color: var(--theme-color);
    }

    /* allow touch to play */
    .nav .touch-only {
        display: none;
        left: -10px;
    }

    .touch-device .nav .touch-only {
        display: inline-block;
    }

    .touch-device .nav .touch-only a {
        /* background: #000; */
        color: var(--color-dark);
    }

    .theme-btn:hover {
        color: var(--color-white) !important;
    }
</style>

<div class="car-area {{isListView ? 'list' : 'grid'}} bg pt-25 pb-120">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class=navwrap>
                    <ul id="nav" class="nav">
                        <li>
                            <a href="javascript:void(0)">Vehicle Type</a>
                            <ul>
                                <li>
                                    <div class="car-widget">
                                        <div class="row">
                                            <div class="col-6" *ngFor="let vt of vehicleBodyType">
                                                <div class="border rounded m-1 p-2 vehicle-type text-center"
                                                    role="button" [attr.vtype]="vt.vehicletypeen"
                                                    (click)="getMakes(vt.vehicletypeen); selectVehicleBodyType($event);">
                                                    <img [src]="'assets/img/mootori/bodytypes/' + vt.vehicletypeimage"
                                                        height="70" width="80" style="width: 80px; height: 70px;"
                                                        alt="{{vt.vehicletypeen}}">
                                                    <span class="d-block text-nowrap"
                                                        style="font-size: 9px;">{{vt.vehicletypeen}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a href="javascript:void(0)">Makes</a>
                            <ul>
                                <li>
                                    <div class="car-widget">
                                        <div class="search-flter">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Type Keyword..."
                                                    (keyup)="listFlter('makes', $event)">
                                            </div>
                                        </div>
                                        <ul
                                            style="height: 200px; overflow-y: auto; position: relative; opacity: 1; left: 0px;">
                                            <li>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="makes" id="make"
                                                        (change)="makeChanged(null);">
                                                    <label class="form-check-label" for="make"> All Makes</label>
                                                </div>
                                            </li>
                                            <li *ngFor="let item of filterMakesList; let i = index;">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="makes"
                                                        id="make{{i}}" (change)="makeChanged(item);">
                                                    <label class="form-check-label" for="make{{i}}">
                                                        {{item.make}}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a href="javascript:void(0)">Models</a>
                            <ul>
                                <li>
                                    <div class="car-widget">
                                        <div class="search-flter">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Type Keyword..."
                                                    (keyup)="listFlter('models', $event)">
                                            </div>
                                        </div>
                                        <ul
                                            style="height: 200px; overflow-y: auto; position: relative; opacity: 1; left: 0px;">
                                            <li>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="models"
                                                        id="model" (change)="modelChanged(null);">
                                                    <label class="form-check-label" for="model"> All Models</label>
                                                </div>
                                            </li>
                                            <li *ngFor="let item of filterModelsList; let i = index;">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="models"
                                                        id="model{{i}}" (change)="modelChanged(item);">
                                                    <label class="form-check-label" for="model{{i}}">
                                                        {{item.model}}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a href="javascript:void(0)">Trims</a>
                            <ul>
                                <li>
                                    <div class="car-widget">
                                        <div class="search-flter">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Type Keyword..."
                                                    (keyup)="listFlter('trims', $event)">
                                            </div>
                                        </div>
                                        <ul
                                            style="height: 200px; overflow-y: auto; position: relative; opacity: 1; left: 0px;">
                                            <li>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="trims" id="trim"
                                                        (change)="trimChanged(null);">
                                                    <label class="form-check-label" for="trim"> All Trims</label>
                                                </div>
                                            </li>
                                            <li *ngFor="let item of filterTrimsList; let i = index;">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="trims"
                                                        id="trim{{i}}" (change)="trimChanged(item);">
                                                    <label class="form-check-label" for="trim{{i}}">
                                                        {{item.trim}}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a href="javascript:void(0)">Price Range</a>
                            <ul>
                                <li>
                                    <div class="car-widget">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="number" class="form-control" placeholder="From"
                                                        (keyup)="setPriceFrom($event)" [value]="priceFrom">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="number" class="form-control" placeholder="To"
                                                        (keyup)="setPriceTo($event)" [value]="priceTo">
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="price-range-box">
                                            <div class="price-range-input">
                                                <input type="text" id="price-amount" readonly>
                                            </div>
                                            <div class="price-range"></div>
                                        </div> -->
                                    </div>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a href="javascript:void(0)">Year Range</a>
                            <ul>
                                <li>
                                    <div class="car-widget">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="number" class="form-control" placeholder="From"
                                                        (keyup)="setYearFrom($event)" [value]="yearFrom">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="number" class="form-control" placeholder="To"
                                                        (keyup)="setYearTo($event)" [value]="yearTo">
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="price-range-box">
                            <div class="price-range-input">
                                <input type="text" id="price-amount" readonly>
                            </div>
                            <div class="price-range"></div>
                        </div> -->
                                    </div>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a role="button" class="theme-btn py-1 me-1" style="color: #ffffff;"
                                (click)="applyFilter()">Apply</a>
                        </li>

                        <li>
                            <a role="button" class="theme-btn theme-btn2 py-1" (click)="clearFilter();">Reset</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="col-md-12">
                    <div class="car-sort">
                        <!-- <h6>Showing 1-10 of 50 Results</h6> -->
                        <div class="car-sort-list-grid">
                            <a class="car-sort-grid me-1" role="button" (click)="changeView(false)"><i
                                    class="far fa-grid-2"></i></a>
                            <a class="car-sort-list active" role="button" (click)="changeView(true)"><i
                                    class="far fa-list-ul"></i></a>
                        </div>
                        <div class="col-md-3 car-sort-box">
                            <select class="select" (change)="sortChanged($event)">
                                <option value="reset_none">Sort By Default</option>
                                <option value="year_desc">Sort By Newest Year</option>
                                <option value="year_asc">Sort By Oldest Year</option>
                                <option value="price_asc">Sort By Low Price</option>
                                <option value="price_desc">Sort By High Price</option>
                                <option value="added_asc">Sort By Latest Added</option>
                                <option value="added_desc">Sort By Oldest Added</option>
                                <option value="distance_desc">Sort By Nearest Distance</option>
                                <option value="distance_asc">Sort By Farthest Distance</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="isListView">
                    <div class="col-md-6 col-lg-12" *ngFor="let auto of pageAutos">
                        <div class="car-item" (click)="addHits(auto)">
                            <div class="stm-badge-directory heading-font"
                                *ngIf="auto.isPromoted || auto.isBusinessPromoted"> Promoted </div>

                            <div class="car-img">
                                <span class="car-status status-1">{{auto.mileage > 0 ? "Used" : "New"}}</span>
                                <img [src]="USERSFILESURL + '/' + auto.autoid + auto.autophotopath1 + randomQueryParam"
                                    alt="{{auto.makemodeltrim[0].autodisplayname}}">
                                <div class="car-btns">
                                    <i class="fa-solid fa-user" style="color: #ec4d47; font-size: 30px;"
                                        *ngIf="!auto.businessid"></i>
                                    <img [src]="USERSFILESURL + '/' + auto.businessid + auto.business[0].businesslogopath + randomQueryParam"
                                        style="height: 30px !important;" *ngIf="auto.businessid"
                                        alt="{{auto.business[0].businessname}}" />
                                </div>
                            </div>
                            <div class="car-content">
                                <div class="car-top">
                                    <h4><a role="button" (click)="addHits(auto)"><img
                                                [src]="FILESURL + '/brandslogo/' + auto.makemodeltrim[0].makelogo + randomQueryParam"
                                                style="height: 15px !important; padding-right: 5px;"
                                                alt="{{auto.makemodeltrim[0].autodisplayname}}">{{auto.makemodeltrim[0].autodisplayname}}</a>
                                    </h4>
                                    <!-- <div class="car-rate">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <span>5.0 (58.5k Review)</span>
                                    </div> -->
                                </div>
                                <ul class="car-list">
                                    <li>
                                        <!-- <i class="far fa-steering-wheel"></i> -->
                                        <img src="assets/img/mootori/gearservices.svg" style="height: 14px;"
                                            alt="gear services">
                                        {{ auto.transmission == 1
                                        ? "Automatic"
                                        : "Manual"}}
                                    </li>
                                    <li><i class="far fa-road"></i>{{auto.mileage | number}}km</li>
                                    <li><i class="fa-regular fa-calendar-days"></i>Model: {{auto.year}}</li>
                                    <li><i class="far fa-gas-pump"></i>{{engineType[auto.enginetype - 1]}}</li>
                                </ul>
                                <p style="min-height: 75px;">
                                    {{auto.autodescription}}
                                </p>
                                <div class="car-footer">
                                    <span class="car-price">AED {{auto.price | number: '1.0-0'}}</span>
                                    <a role="button" class="theme-btn" (click)="addHits(auto)"><span
                                            class="far fa-eye"></span>Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="!isListView">
                    <div class="col-md-6 col-lg-4" *ngFor="let auto of pageAutos">
                        <div class="car-item" (click)="addHits(auto)">
                            <div class="stm-badge-directory heading-font"
                                *ngIf="auto.isPromoted || auto.isBusinessPromoted"> Promoted </div>

                            <div class="car-img">
                                <span class="car-status status-1">{{auto.mileage > 0 ?"Used" : "New"}}</span>
                                <img [src]="USERSFILESURL + '/' + auto.autoid + auto.autophotopath1 + randomQueryParam"
                                    alt="{{auto.makemodeltrim[0].autodisplayname}}">
                                <div class="car-btns">
                                    <i class="fa-solid fa-user" style="color: #ec4d47; font-size: 30px;"
                                        *ngIf="!auto.businessid"></i>
                                    <img [src]="USERSFILESURL + '/' + auto.businessid + auto.business[0].businesslogopath + randomQueryParam"
                                        style="height: 30px !important;" *ngIf="auto.businessid"
                                        alt="{{auto.business[0].businessname}}" />
                                </div>
                            </div>
                            <div class="car-content">
                                <div class="car-top" style="height: 40px;">
                                    <h4><a role="button" (click)="addHits(auto)"><img
                                                [src]="FILESURL + '/brandslogo/' + auto.makemodeltrim[0].makelogo + randomQueryParam"
                                                style="height: 15px !important; padding-right: 5px;"
                                                alt="{{auto.makemodeltrim[0].autodisplayname}}">{{auto.makemodeltrim[0].autodisplayname}}</a>
                                    </h4>
                                    <!-- <div class="car-rate">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <span>5.0 (58.5k Review)</span>
                                    </div> -->
                                </div>
                                <ul class="car-list" style="height: 65px;">
                                    <li>
                                        <!-- <i class="far fa-steering-wheel"></i> -->
                                        <img src="assets/img/mootori/gearservices.svg" style="height: 14px;"
                                            alt="gear services">
                                        {{ auto.transmission == 1
                                        ? "Automatic"
                                        : "Manual"}}
                                    </li>
                                    <li><i class="far fa-road"></i>{{auto.mileage | number}}km</li>
                                    <li><i class="fa-regular fa-calendar-days"></i>Model: {{auto.year}}</li>
                                    <li><i class="far fa-gas-pump"></i>{{engineType[auto.enginetype - 1]}}</li>
                                </ul>
                                <div class="car-footer">
                                    <span class="car-price">AED {{auto.price | number: '1.0-0'}}</span>
                                    <a role="button" class="theme-btn" (click)="addHits(auto)"><span
                                            class="far fa-eye"></span>Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pagination-area" *ngIf="filterAutos && filterAutos.length > itemsPerPage">
                    <div aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="page-link" role="button" aria-label="Previous" (click)="goToPreviousPage()">
                                    <span aria-hidden="true"><i class="far fa-arrow-left"></i></span>
                                </a>
                            </li>
                            <li class="page-item me-2" style="width: 70px;">

                                <!-- <a class="page-link"
                                    role="button" (click)="goToPage(i + 1)">{{i+ 1}}</a> -->

                                <select class="select " id="list_pagination">
                                    <option value="{{i+ 1}}"
                                        *ngFor="let page of  [].constructor(pageCount); let i = index"
                                        [selected]="i == 0">{{i+ 1}}</option>
                                </select>
                            </li>

                            <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li> -->
                            <li class="page-item">
                                <a class="page-link" role="button" aria-label="Next" (click)="goToNextPage()">
                                    <span aria-hidden="true"><i class="far fa-arrow-right"></i></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>