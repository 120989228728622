import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TermsComponent {
  constructor() {
    document.querySelector(".preloader")?.setAttribute("style", "display: none;");
  }
}
