import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

declare var jQuery: any;
declare var bootstrap: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Mootori';

  constructor(private router: Router, private route: ActivatedRoute) {

  }
  ngOnInit(): void {
    setTimeout(() => {
      this.route.fragment.subscribe(fragment => {
        if (fragment === 'AD') {
          this.scrollToElement(fragment);
        } else {
          this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
              (function ($) {
                $("html, body").animate({ scrollTop: 0 }, 500);
                return false;
              })(jQuery);
            }
          });
        }
      });

      document.body.addEventListener('click', function (event) {
        try {
          var navbar = document.getElementById('main_nav').parentElement.parentElement;
          var navbarToggler = navbar.querySelector('.navbar-toggler');
          var targetElement: any = event.target; // Element that triggered the event

          // Check if the clicked element is not within the navbar or navbar toggler
          if (!navbar.contains(targetElement) && targetElement !== navbarToggler) {
            // Collapse the navbar
            var collapse = new bootstrap.Collapse(navbar.querySelector('.collapse'), {
              toggle: false
            });
            collapse.hide();
          }
        } catch (error) {

        }
      });
    }, 500);
  }

  scrollToElement(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
