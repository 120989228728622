import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

export interface PageMetadata {
  title: string;
  imageUrl: string;
  description: string;
  author: string;
  keywords: string[];
  type: string;
}

const defaultMetadata: PageMetadata = {
  title: 'Mootori Portal',
  imageUrl: 'https://mootori.com/assets/img/logo/favicon.png',
  description: `Mootori offers a comprehensive range of services and products to support motorists in all aspects of their vehicle ownership experience. From discovering, purchasing and selling vehicles, to maintaining and repairing them, we provide convenient access to a wide variety of spare parts, accessories, garage tools, and services that can be delivered directly to your doorstep.

  We connect car lovers and owners to a wide range of trusted motor business providers.
  
  The platform brings together the nearby service centers, spare parts shops, showrooms, and tools to users and helps them find and compare the best options they need.`,
  author: 'Mootori.com',
  keywords: ['Mootori', 'Auto', 'Auto Service', 'Auto Tools', 'Auto Sale', 'Motors', 'Cars'],
  type: 'website',
}

@Injectable()
export class MetadataService {
  constructor(private metaTagService: Meta,
    private titleService: Title,
    private router: Router) { }

  public updateMetadata(metadata: Partial<PageMetadata>, index: boolean = true): void {
    const pageMetadata: PageMetadata = { ...defaultMetadata, ...metadata };
    const metatags: MetaDefinition[] = this.generateMetaDefinitions(pageMetadata);

    metatags.forEach(tag => {
      const existingTag = this.metaTagService.getTag(`property="${tag.property}"`);

      if (existingTag) {
        this.metaTagService.updateTag({ property: tag.property!, content: tag.content! });
      } else {
        this.metaTagService.addTag({ property: tag.property!, content: tag.content! });
      }
    });

    // Append a unique timestamp as a query parameter
    // const updatedUrl = `${window.location.href}?timestamp=${new Date().getTime()}`;

    // Use the updated URL for sharing
    // window.history.replaceState({}, '', updatedUrl);

    // Update the href attribute of the canonical link tag
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute('href', window.location.href);
    }

    // this.metaTagService.addTags([
    //   ...metatags,
    //   { property: 'og:url', content: window.location.href },
    //   { name: 'robots', content: index ? 'index, follow' : 'noindex' },
    //   // { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    //   // { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
    // ]);

    this.titleService.setTitle(pageMetadata.title + " - Mootori");
  }

  private generateMetaDefinitions(metadata: PageMetadata): MetaDefinition[] {
    return [
      { name: 'title', content: metadata.title },
      { property: 'og:title', content: metadata.title },
      { property: 'twitter:title', content: metadata.title },

      { name: 'description', content: metadata.description },
      { property: 'og:description', content: metadata.description },
      { property: 'twitter:description', content: metadata.description },

      { name: 'author', content: metadata.author },
      { property: 'og:author', content: metadata.author },

      { name: 'keywords', content: metadata.keywords.join(', ') },

      { property: 'og:type', content: metadata.type },

      { property: 'og:image', content: metadata.imageUrl, itemprop: 'image' },
      { property: 'og:image:url', content: metadata.imageUrl, itemprop: 'image' },
      { property: 'twitter:image', content: metadata.imageUrl, itemprop: 'image' },

      { property: 'twitter:card', content: 'summary_large_image' },
    ];
  }
}