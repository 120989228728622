<!-- terms of service -->
<div class="pt-20 pb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 mx-auto">
                <div class="site-heading text-center">
                    <h2 class="site-title">Terms and Conditions</h2>
                    <div class="heading-divider"></div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="terms-content">
                    <h3>Ratification of the Agreement</h3>
                    <p>
                        By entering, connecting, using or creating a profile (for either a consumer or a service
                        provider)
                        on this
                        website and its associated applications on iPhone, iPad, Android, and any mobile application
                        (hereinafter
                        collectively referred to as the "Platform") including without limitation to any services offered
                        on
                        the
                        Platform, you acknowledge that you have read and understood the following Terms and Conditions
                        (collectively the
                        "Terms"), including these Terms, terms of our Privacy Policy available at www.mootori.com and
                        you
                        agree to be
                        bound by them and to comply with all the applicable laws and regulations regarding your use of
                        the
                        Platform. You
                        acknowledge that these Terms constitute a binding and enforceable legal contract between
                        yourself
                        and Mootori
                        Portal (hereinafter referred to as "Mootori", "Company", "we", "us", "our") with reference to
                        the
                        use of this
                        Platform. Please read these terms before ordering any items from our application.
                    </p>
                    <p>
                        You become a user of the Platform ("User" or "you"), by entering, using, browsing, or
                        registering on
                        the
                        Platform. As a User, you agree and undertake to comply with the provisions of these Terms.
                    </p>
                    <p>
                        You are responsible for making all arrangements necessary for you to have access to the
                        Platform.
                        You are also
                        responsible for ensuring that all persons who access the Platform through your Internet
                        connection
                        are aware of
                        these Terms and that they comply with them. By viewing, using, accessing, browsing, or
                        submitting
                        any content or
                        material on the Platform, you agree to these Terms as a binding legal agreement, without
                        limitation
                        or
                        qualification. The term "you" or "You" shall include any person or entity, who views, uses,
                        accesses, browses,
                        or submits any content or material to the Platform.
                    </p>
                    <p>
                        If you do not agree to these Terms, then you may not use the Platform. Mootori reserves the
                        right to
                        modify
                        these Terms at any time without prior notice. You agree that each visit you make to the Platform
                        shall be
                        subject to the Terms as displayed, and your continued use of the Platform now or following the
                        modifications in
                        these Terms confirms that you have read, accepted, and agreed to be bound by such modifications.

                    </p>
                </div>

                <div class="terms-content">
                    <h3>Our Services</h3>
                    <p>
                        The Platform allows its Users who agree to the Terms to order or request a service or product
                        from
                        its partners
                        such as motors service centres, garages, spare parts shops, showrooms, and garages tools shops
                        (hereinafter
                        referred to as the "Service Providers").
                    </p>
                    <p>
                        The purpose of this Platform is to provide a simple and convenient service to Users, linking
                        them to
                        Service
                        Providers in their area or beyond for their motor needs (our "Service") to Get Estimation for
                        Motor
                        Maintenance
                        and Repairs, Quick Motor Services, Get Quotation for Spare Parts, Buy Spare Parts, Buy and Sell
                        Motors, Explore
                        Showrooms Motors, Get Quotation for Garage Tools, and Buy Tools (hereinafter collectively
                        referred
                        to as
                        "Services"). Interactive options allow Users to build and submit orders or requests at the click
                        of
                        a button.
                        When you place an order or submit a request from a Service Provider, Mootori acts as a
                        facilitator
                        on behalf of
                        that Service Provider to conclude your order or request from the Platform and to manage your
                        experience
                        throughout the order processing. We have created the Platform to serve as a marketplace for its
                        Users.
                    </p>
                    <p>
                        Mootori does not sell or interfere in any way in the provision of any services or service
                        provided
                        by the
                        Service Providers. What this online market offers to the Users is the ability to search for and
                        find
                        local
                        Service Providers that provide or deliver these Services and enables ordering or requesting them
                        through this
                        Platform. The Service Providers are obligated to comply with the country's local laws, rules,
                        regulations, and
                        standards pertaining to products and services provision, sale, marketing, and safety.
                    </p>
                    <p>
                        However, it is important for Users to understand that Mootori does not in any way verify or
                        guarantee the
                        products and services offered or delivered by Service Providers, or that a Service Provider is
                        in
                        compliance
                        with applicable laws. Users must make themselves comfortable through information provided by
                        Service
                        Providers
                        on the Platform or as requested by Users directly from the Service Providers as to the quality
                        and
                        reliability
                        of the Service Providers, as well as to their compliance with applicable laws.
                    </p>
                    <p>
                        We reserve the right, but are not obligated, to restrict or limit the sales of our Services to
                        any
                        person,
                        geographic region, or jurisdiction. Due to the laws in some countries, we shall not offer for
                        sale
                        certain
                        Services, or restricted items to you. Any offer for any Services made on the Platform is void
                        when
                        it is
                        prohibited by law.
                    </p>
                    <p>
                        Mootori does not in any way guarantee the quality of any Services or Products or that any
                        Services
                        or Products
                        complies with applicable laws and does not guarantee matching the list of Services or Products
                        displayed on the
                        Platform to what is actually provided to the Users. In addition, a Service Provider may
                        represent
                        those products
                        and services provision is in accordance with special standards, for example (including without
                        limitation) to
                        "genuine," "speciality" or "authorised dealer". However, Mootori is not obligated to provide
                        safety
                        information
                        or warranty or guarantee information on the Platform. Further, Mootori does not guarantee that
                        the
                        Services or
                        Products sold by Service Providers. If you have any clarification or specific requirements,
                        please
                        contact the
                        Service Provider before placing an Order on our Platform.
                    </p>
                    <p>
                        Mootori liability shall be limited to connect customers to their preferred Service Providers.
                        Mootori shall not
                        be liable or responsible for any services or products offered or may result from a service
                        provided
                        by Service
                        Providers that is the cause of physical or non-physical or financial or non-financial losses or
                        accidents or
                        injuries, that is otherwise unacceptable to Users or that does not meet the expectation of Users
                        in
                        any manner.
                    </p>
                    <p>
                        Users are solely responsible for verifying the accuracy of his personal information, and Mootori
                        shall have no
                        liability or responsibility for any such erroneous information. All products and services
                        provisioning are the
                        sole responsibility of the Service Providers accepting the orders or requests. Please be aware
                        that
                        the Service
                        Providers will do everything they can to fulfil your requirements, however service time may be
                        affected by
                        factors beyond their control and therefore cannot be guaranteed.

                    </p>
                </div>

                <div class="terms-content">
                    <h3>Creating User Profile</h3>
                    <p>
                        A User can create a profile on the Platform and can determine their own "mobile" and "email"
                        which
                        are
                        determined by the User herself/himself. The "mobile" and "email" are personal to the User and
                        the
                        same "mobile"
                        and "email" are not given to two different Users.
                    </p>
                    <p>
                        Mootori is not liable for any problems, which may arise from the use of "mobile" or "email".
                        Mootori
                        informs the
                        user about the promotions, which take place in the user's area and from which the user may
                        benefit,
                        by emails.
                        Mootori may share your profile with the third-party companies for the purpose of providing our
                        Service for
                        example, the mobile may be shared with the Service Providers which deals with take-out products
                        and
                        services, to
                        provide a faster and correct provision of services. For the user to connect to the services,
                        which
                        require a
                        Profile of the Platform, the user must login with "mobile" or "email".
                    </p>
                    <p>
                        By using this Platform, you warrant that any personal information provided by you is true,
                        accurate,
                        current and
                        complete in all respects and that you will notify us immediately of any changes to your personal
                        information by
                        updating your User profile on the Platform or by contacting us, you agree not to impersonate any
                        other person or
                        entity or to use a false name or a name that you are not authorized to use.
                    </p>
                    <p>
                        You are not allowed to register multiple profiles for use by the same person. Violation of this
                        clause may
                        result in termination of your profile. You are responsible for all activities and orders that
                        occur
                        or are
                        submitted through your Profile.
                    </p>
                    <p>
                        You may not use the Platform for any unlawful or unauthorized purpose, including without
                        limitation
                        to:
                    </p>
                    <ul>
                        <li>Distributing any unlawful, libellous, abusive, threatening, harmful, obscene, or otherwise
                            objectionable
                            material. Transmitting material that constitutes a criminal offense, results in civil
                            liability,
                            or
                            otherwise breaches any laws, regulations, or codes of practice.
                        <li>Interfering with any other persons use or enjoyment of the Platform.
                        <li>
                            Damaging, disabling, or impairing the operation of this Platform or attempting to gain
                            unauthorized access
                            to this Platform or to networks connected to it, through hacking, spoofing or other such
                            similar
                            means.
                    </ul>
                </div>

                <div class="terms-content">
                    <h3>How to Transact through Mootori</h3>
                    <p>
                        Once you have selected your Products or Services from the list of your chosen Service Providers
                        you
                        will be
                        given the opportunity to submit your request or place your order by clicking on the "Next"
                        button to
                        review and
                        confirm the transaction.
                    </p>
                    <p>
                        On receipt of your transaction, the Platform will begin processing your transaction and we will
                        send
                        a
                        notification once it is confirmed by Service Provider. At this stage, you will be able to
                        proceed
                        with
                        confirming the transaction and payment as applicable.

                    </p>
                </div>

                <div class="terms-content">
                    <h3>Products Returns</h3>
                    <p>
                        Products that can be fully or partially returned within 15 days of delivery by submitting a
                        return
                        request in
                        Mootori unless otherwise mentioned by the Service Provider.
                    </p>
                    <p>
                        The return request will be processed by the Service Provider when the Service Provider approved
                        the
                        return
                        request.
                    </p>
                    <p>
                        In case of rejecting return request by Service Provider, users can only dispute in case the
                        payment
                        was
                        completed through Mootori.

                    </p>
                </div>

                <div class="terms-content">
                    <h3>Promotions and Discounts</h3>
                    <p>
                        Mootori is not liable for any discounts or promotions published by Service Providers.
                    </p>
                </div>

                <div class="terms-content">
                    <h3>Transaction Cancellation </h3>
                    <p>
                        In terms of cancellation for motor maintenance, repairs and quick services, cancellation is only
                        applicable for
                        services prior to creating job cards by Service Providers.
                    </p>
                    <p>
                        Whereas cancellation for products orders is only applicable before creating the invoice by
                        Service
                        Providers.
                        Once the invoice is created, the user cannot cancel the order and instead he may request for
                        return
                        through
                        Mootori Portal.

                    </p>
                </div>

                <div class="terms-content">
                    <h3>Refund Policy</h3>
                    <p>
                        Full or partial Refunds are only applicable for transactions paid digitally (Credit Card / Debit
                        Card) through
                        Mootori Portal subject to Service Provider approval. Mootori is not liable to any payments or
                        refunds completed
                        outside Mootori Portal. Mootori is not liable for any cash transactions completed between
                        Service
                        Providers and
                        customers.
                    </p>
                    <p>
                        Most refunds are issued to the payment method used at the time of purchase. The following are
                        the
                        refund
                        timelines associated with each payment method.
                    </p>
                    <table style="border: 1px solid gray;padding: 10px; border-collapse: collapse;">
                        <tr>
                            <th style="border: 1px solid gray;padding: 10px;">Payment Method</th>
                            <th style="border: 1px solid gray;padding: 10px;">Refund Method</th>
                            <th style="border: 1px solid gray;padding: 10px;">Refund Timeframe</th>
                        </tr>
                        <tr>
                            <td style="border: 1px solid gray;padding: 10px;">Credit Card / Debit Card</td>
                            <td style="border: 1px solid gray;padding: 10px;">Credit Card / Debit Card</td>
                            <td style="border: 1px solid gray;padding: 10px;">7 - 14 business days</td>
                        </tr>
                        <tr>
                            <td style="border: 1px solid gray;padding: 10px;">Credit Card / Debit Card</td>
                            <td style="border: 1px solid gray;padding: 10px;">Mootori Wallet</td>
                            <td style="border: 1px solid gray;padding: 10px;">1 business day</td>
                        </tr>
                    </table>
                </div>

                <div class="terms-content">
                    <h3>Delivery</h3>
                    <p>
                        Mootori is not liable for any delays or failed or wrong deliveries of products. The delivery is
                        the
                        responsibility of Service Providers. All arrangements and communications are done directly
                        between
                        the customer
                        and Service Providers without Mootori interference.
                    </p>
                </div>

                <div class="terms-content">
                    <h3>Acceptable Use Policy</h3>
                    <p>
                        You may not access or use the Platform for any other purpose other than that for which the
                        Platform
                        makes it
                        available. The Platform is for the personal use of users only and may not be used in connection
                        with
                        any
                        commercial endeavours except those that are specifically endorsed or approved by the Platform.
                        In
                        case of any
                        prohibited activities committed by the user, the company has the right to suspend, block or
                        terminate the user's
                        Profile and prevent his access to log in the service. Prohibited activities include but not
                        limited
                        to the
                        following:
                    </p>

                    <ul>

                        <li>Violate any law, statute, ordinance, or regulation
                        <li>Violate applicable laws or industry regulations regarding the sale of alcoholic drinks or
                            prescription drugs
                            and devices
                        <li>Violate copyright, trademark, right of publicity or privacy or any other proprietary right
                            under
                            the laws of
                            account owner's jurisdiction
                        <li>Any illegal products, services, or activities, including encouraging, promoting,
                            facilitating,
                            or
                            instructing others to engage in illegal activities
                        <li>Payments to or from any listed sanctioned countries. Businesses or businesses with persons
                            subject to
                            international/national sanctions, suspected or convicted of terrorism, financial crime, or
                            any
                            other offence
                        <li>Providing false information relating to the customer's identity or business activities
                        <li>Any product, service or activity that finance terrorism
                        <li>Narcotics, steroids, certain controlled substances, or other products that present a risk to
                            consumer
                            safety, products including THC, CBD or any other similar substance, any activity that relate
                            to
                            drug
                            paraphernalia
                        <li>Products, services, or activities including media and journalism, that could be considered
                            as
                            being of a
                            discriminatory, hateful, racist, or exploitative nature, or of inciting harassment,
                            bestiality,
                            mutilation,
                            or violence
                        <li>Connected to services where the service provider collects money on behalf of others as a
                            payment
                            services
                            provider, cheque encashment agencies, foreign exchange providers, money transmitters,
                            traveller’s cheques or
                            money orders
                        <li>Involving ammunition, explosives, arms, defence or weapons, replica weapons, gun parts or
                            related parts or
                            accessories, hunting, manufacture/distribution of weapons
                        <li>Services relating to hospitality timeshares or issuing of travel visas
                        <li>Promotion or advertising of products or services other than your own without appropriate
                            authorisation
                        <li>Collecting money without a license when compulsory in the account owner's jurisdiction
                        <li>Multi-level marketing programs, network sales, matrix programs, outbound and inbound
                            telemarketing or other
                            similar programs including pyramid and Ponzi schemes
                        <li>Gambling, betting activities, casinos, lotteries, sweepstakes, penny auctions, services
                            related
                            to fantasy
                            sports or sports forecasting, or sale of value usable "chips" or vouchers connected to this
                            services,
                            certain in-purchasing games
                        <li>Programs, materials, or services related to get-rich courses or related to increasing your
                            wealth or
                            gambling
                        <li>Factoring, lending, investment services, financial, escrow or mortgage services, pawn shops
                        <li>Private business directories, businesses specializing in warning letters, liquidation, or
                            proxy
                            services
                        <li>Buying or selling stocks, bonds, securities, options, futures, binary options or investment
                            interest in any
                            entity or property, services related to raffles, debt consolidation, insurance brokers,
                            payday
                            lenders,
                            medical collections, scholarship programs, payment protection insurance, currency exchange
                            or
                            FOREX
                            activities
                        <li>Modification chips (e.g. to modify a games console in order to use non-producer hardware or
                            software)
                        <li>Ticket agencies without a license, ticket resale
                        <li>Replica clothes and products, or counterfeit goods
                        <li>Objectionable, offensive, indecent, harassing, threatening, embarrassing, distressing,
                            vulgar,
                            hateful,
                            racially, ethnically, or sexually offensive, or otherwise inappropriate behaviour towards
                            the
                            Mootori
                            personnel.
                        <li>Highly rated politically exposed persons (PEP).
                    </ul>
                    <p>
                        Mootori reserves the right but is not obligated to do any or all of the following (with no
                        limitation to other
                        actions):
                    </p>
                    <ul>
                        <li>Investigate an allegation that any content posted on the Platform does not conform to these
                            Terms and
                            determine in its sole discretion to remove or request the removal of the content.
                        <li>Remove content which is abusive, illegal, or disruptive, or that otherwise fails to conform
                            with
                            these
                            Terms.
                        <li>Monitor, edit, or disclose any content on the Platform; and
                        <li>Edit or delete any content posted on the Platform, regardless of whether such content
                            violates
                            these Terms.
                        <li>Cancel any Order and/or suspend, deactivate, or terminate your Profile in its sole
                            discretion if
                            it
                            reasonably suspects or detects fraudulent behaviour or activity associated with your Profile
                            and/or with
                            your Order and/or any of the reasons named in section 4 of these Terms.
                    </ul>
                </div>

                <div class="terms-content">
                    <h3>Authority of Mootori</h3>
                    <p>
                        Mootori may temporarily suspend or completely cease the system operation at any time. the
                        Platform
                        shall not
                        have any responsibilities against the Users of Mootori or any third parties due to the temporary
                        suspension or
                        complete cessation of the system operation.
                    </p>
                    <p>
                        Mootori has the right to back-up and delete all or some of the files, messages which are
                        provided by
                        the Users
                        during the use of services for some periods deemed suitable by the Platform. Mootori shall not
                        be
                        held
                        responsible for back-up and delete processes.
                    </p>
                    <p>
                        The Platform has the ownership and copyrights arising from the ownership of the information,
                        documents,
                        software, designs, graphics etc. which are produced by itself and/or bought from outside.
                    </p>
                    <p>
                        Mootori has right to post any information, documents, software, designs, graphics, etc. (such as
                        the
                        messages,
                        poets, reports/news, file posted to the bulletin board) which are User generated and uploaded to
                        the
                        system by
                        the Users and/or to transmit them to another place within the Platform deemed as appropriate by
                        Mootori. It is
                        probable that this information is copied and/or posted by other users. In such cases, the users
                        shall not demand
                        any royalty from Mootori.
                    </p>
                    <p>
                        The obligation to change the information in relation to the price and product specifications,
                        which
                        are provided
                        to sell in Mootori, belongs to the companies which have cyber–Service Providers in the Platform.
                        In
                        the event of
                        any misinformation relating to the price and specifications of products, Mootori may correct
                        this
                        mistake by
                        cancelling the order.
                    </p>
                    <p>
                        Mootori may provide transition for a User to other Platforms. In this case, the User agrees that
                        Mootori shall
                        not be responsible for the context of the Platforms, which the User may access through its
                        Platform.
                    </p>
                    <p>
                        Mootori may change in time the services for which any Account is not required to a form, which
                        requires Account.
                        Mootori may provide additional services, change some of the services partially or completely, or
                        transform into
                        a paid service.
                    </p>
                    <p>
                        This Platform may be unavailable at certain times to allow for maintenance and upgrades.
                        Although we
                        will
                        endeavour to notify Users in advance of any service unavailability, this cannot be guaranteed,
                        and
                        we reserve
                        the right to alter or withdraw the service at any time.

                    </p>
                </div>

                <div class="terms-content">
                    <h3>Guidelines for Reviews</h3>
                    <p>
                        Reviews are approved based on the following criteria:
                    </p>
                    <ul>
                        <li>Relevancy: Reviewers must have had an experience with the Service Provider first-hand.
                        <li>Acceptable Content: Mootori is not obligated to approve reviews on the Platform. A subset of
                            the
                            potential
                            reasons for rejection are:
                            <ul>
                                <li>Offensive Language: Profanity, abusive, racist, and hate language is not tolerated
                                    in
                                    our
                                    interactive sections.
                                <li>Irrelevant or non-indicative content.
                                <li>Discrimination based on the grounds of religion, race, gender, national origin, age,
                                    marital status,
                                    or disability.
                                <li>References to illegal activity or uncorrelated references towards society's
                                    traditions
                                    and values.
                                <li>Conflicts of interest: Competing Merchants are not allowed to post negative reviews.
                                    Violation of
                                    this might result in permanent removal from the Platform.
                                <li>Allegations of health code violations.
                                <li>Outdated and obsolete information.
                            </ul>
                        <li>Not Endorsed: Reviews are not endorsed by Mootori, and do not represent the views of
                            Mootori,
                            our parent
                            companies, our subsidiaries. Mootori does not assume liability for any review or for any
                            claims,
                            liabilities
                            or losses resulting from any use of the Platform, or information on the Platform.
                        <li>Owned by Mootori: Reviews are owned by Mootori exclusively in perpetuity. We have the right
                            to
                            reproduce,
                            modify, translate, transmit, and/or distribute all materials relating to reviews. Mootori is
                            under no
                            obligation to pay the author or anyone else for their reviews.
                    </ul>

                </div>

                <div class="terms-content">
                    <h3>Termination of Agreement</h3>
                    <p>
                        Mootori has the right to terminate, suspend or delete your Profile and your access to or use of
                        the
                        Platform,
                        including any delivery service we provide to you in respect of an Order, for any reason,
                        including,
                        without
                        limitation, if Mootori, in its sole discretion, considers your use to be unacceptable, or in the
                        event of any
                        breach by you of these Terms. Where appropriate, Mootori will give you the opportunity to state
                        your
                        position,
                        before any termination, suspension, or deletion of your account.
                    </p>
                </div>

                <div class="terms-content">
                    <h3>Restrictions for Commercial Usage</h3>
                    <p>
                        You agree that the consequences of commercial use or re-publication of content or information of
                        Mootori may
                        lead to serious and incalculable monetary compensation that may not be a sufficient or
                        appropriate
                        remedy and
                        that Mootori will be entitled to temporary and permanent injunctive relief to prohibit such use.
                    </p>
                </div>

                <div class="terms-content">
                    <h3>Price and Payment</h3>
                    <p>
                        Once your order has been accepted, this represents an agreement between you the User and the
                        Service
                        Provider.
                        The Service Provider has sole responsibility for this order. Cash payments are made directly to
                        the
                        Service
                        Provider upon delivery to fulfil the order.
                    </p>
                    <p>
                        Mootori does not provide any payment service either to you or to third parties. All online
                        payments
                        are
                        processed via your own issuer / payment service provider and the payment service providers in
                        partnership with
                        Mootori.
                    </p>
                    <p>
                        Prices quoted on the Platform shall be displayed in the applicable country's national currency
                        and
                        subject to
                        applicable tax. You accept that prices and offers on the Platform may vary from the prices and
                        offers offered by
                        our Service Provider elsewhere (including their own websites, mobile applications, or at their
                        outlets).
                    </p>
                    <p>
                        The way we display the prices of our goods may vary depending on the Service Provider, and,
                        subject
                        to
                        applicable laws, the prices reflected on our Platform. Prices and additional charges (including
                        but
                        not limited
                        to Delivery or Service fees) indicated on the Platform are as at the time of each Order and may
                        be
                        subject to
                        change later. Mootori reserves the right to charge fees (including but not limited to Delivery
                        or
                        Service fees)
                        for our services as we deem necessary for our business. The breakdown of the prices and
                        additional
                        charges
                        (including but not limited to Delivery or Service fees) are displayed before checkout. When you
                        place an Order,
                        you agree to all amounts, additional charges and the final ‘Total' amount which is displayed to
                        you.

                    </p>
                </div>

                <div class="terms-content">
                    <h3>Payment Methods</h3>
                    <p>
                        When using your Profile as payment instrument you may choose from different payment methods as
                        available and
                        indicated on the Platform.
                    </p>
                    <p>
                        Mootori reserves the right to offer additional payment methods and/or remove existing payment
                        methods at any
                        time at its sole discretion. If you choose to pay using an online payment method, the payment
                        shall
                        be processed
                        by our third-party payment service provider(s). With your consent, your credit card / payment
                        information will
                        be stored with our third-party payment service provider(s) for future Orders. Mootori does not
                        store
                        your credit
                        card or payment information.
                    </p>
                    <p>
                        You must ensure that you have sufficient funds on your credit and debit card to fulfil payment
                        of an
                        Order.
                        Insofar as required, Mootori takes responsibility for payments made digitally (Credit Card /
                        Debit
                        Card) on our
                        Platform including refunds, and dispute resolution, provided if reasonable and justifiable and
                        in
                        accordance
                        with these Terms.
                    </p>
                    <p>
                        At the time of checkout, please read the applicable payment terms linked and disclaimers
                        carefully
                        before using
                        any payment method.

                    </p>
                </div>

                <div class="terms-content">
                    <h3>Intellectual Property Rights</h3>
                    <p>
                        All trademarks, logos, images, and service marks, including these Terms as displayed on the
                        Platform
                        or in our
                        marketing material are the Intellectual Property of Mootori and/or third parties who have
                        authorised
                        us with the
                        right to use such proprietary rights (collectively the "Intellectual Property"). You may not
                        use,
                        copy,
                        reproduce, republish, upload, post, transmit, distribute, or modify these Intellectual Property
                        in
                        any way
                        without our prior express written consent. The use of our Intellectual Property rights,
                        including
                        but not
                        limited to the Mootori’s trademarks and copyrights to the Mootori logo on any other website not
                        approved by us
                        is strictly prohibited. Mootori will aggressively fully enforce its Intellectual Property rights
                        of
                        the laws,
                        including criminal prosecution. Mootori neither warrants nor represents that your use of
                        materials
                        displayed on
                        the Platform will not infringe or constitute a misappropriation of any rights of third parties
                        not
                        owned by
                        Mootori or its Affiliates. Use of any materials on the Platform is at your own risk.
                    </p>
                </div>

                <div class="terms-content">
                    <h3>External Links</h3>
                    <p>
                        As a courtesy to you, the Platform may offer links to other websites/applications. Some of these
                        websites/applications may be affiliated with Mootori while others are not. Mootori is not
                        responsible for the
                        contents of any website/application pages created and maintained by organizations independent of
                        Mootori.
                        Visiting any such third-party website/application pages is at your own risk. Mootori has no
                        control
                        of these
                        third-party website pages, nor can it guarantee the accuracy, completeness, or timeliness of
                        information in
                        third-party website pages. Your use of such information is voluntary, and your reliance on such
                        information
                        should be made only after independent review. References to commercial products or services
                        within
                        any such
                        third-party website pages do not constitute or imply an endorsement by Mootori. By using the
                        Platform, you
                        acknowledge that Mootori is responsible neither for the availability of, nor the content located
                        on
                        or through
                        any third-party website pages.
                    </p>
                </div>

                <div class="terms-content">
                    <h3>Compensation</h3>
                    <p>
                        You agree to compensate and hold Mootori, its parents, subsidiaries and affiliates, agents,
                        officers, directors,
                        or other employees harmless from any claim, demand, or damage (whether direct, indirect, or
                        consequential),
                        including reasonable attorneys' fees, made by anyone in connection with your use of the
                        Platform,
                        with your
                        submissions, with any alleged infringement of intellectual property or other right of any person
                        or
                        entity
                        relating to the Platform, your violation of these Terms, and any other acts or omissions
                        relating to
                        the
                        Platform.
                    </p>
                </div>

                <div class="terms-content">
                    <h3>Amendments</h3>
                    <p>
                        Mootori may amend these Terms at any time in its sole discretion, and the amended Terms shall be
                        effective
                        immediately upon posting, subject to applicable laws. It is your responsibility to check the
                        Terms
                        regularly. If
                        you do not agree with the amended Terms, whether in whole or in part, you must stop using the
                        Platform and/or
                        any of its services immediately.
                    </p>
                </div>

                <div class="terms-content">
                    <h3>Disclaimer</h3>
                    <p>
                        To the extent permitted by law, Mootori provides this Platform and content on an "as-is" and "as
                        available"
                        basis and we make no representation or warranty of any kind, express or implied, regarding the
                        content or
                        availability of this Platform, or that it will be timely or error-free, that defects will be
                        corrected, or that
                        the Platform or server that makes it available are free of viruses or other harmful components.
                        Subject to the
                        previous paragraph, neither Mootori nor its directors, officers, agents, employees, or
                        contractors
                        shall have
                        any liability for any direct, indirect, special or consequential losses or damages (including
                        without
                        limitation, damages for loss of business or loss of profits), arising in contract, tort or
                        otherwise
                        from the
                        use or inability to use this Platform. Nothing in these terms shall exclude or limit liability
                        for
                        death or
                        personal injury resulting from our negligence or that of our agents or employees.
                    </p>
                    <p>
                        Service Providers are solely responsible for the services provision, condition and quality of
                        spare
                        parts or
                        tools sold or delivered to you. Mootori shall not be responsible or liable for any loss, damage,
                        expense, cost,
                        or fees arising from your contractual relationship with the Service Provider.
                    </p>
                    <p>
                        Mootori assumes no responsibility for products or services information provided by Service
                        Providers. In no
                        event shall Mootori be liable for any special, direct, indirect, consequential, or incidental
                        damages or any
                        damages whatsoever, whether in an action of contract, negligence, or other tort, arising out of
                        or
                        in connection
                        with the use of the service or the contents of the service.

                    </p>
                </div>

                <div class="terms-content">
                    <h3>Contact</h3>
                    <p>
                        All comments, queries and requests relating to our use of your information are welcomed and
                        should
                        be addressed
                        to Mootori Portal.
                    </p>
                    <p>
                        For questions or concerns relating to our privacy policy, we can be contacted by email at
                        info&#64;mootori.com or
                        at:
                    </p>
                    <p>
                        Phone: (+971) 52 77 2020 1
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>