import { Component } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent {
  constructor() {
    document.querySelector(".preloader")?.setAttribute("style", "display: none;");
  }
}
