import { Component, OnInit, ViewEncapsulation } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-select-city',
  templateUrl: './select-city.component.html',
  styleUrls: ['./select-city.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SelectCityComponent implements OnInit {
  cities: string[] = [
    "All",
    "Abu Dhabi",
    "Al Ain",
    "Dubai",
    "Sharjah",
    "Ajman",
    "Umm Al-Quwain",
    "Fujairah",
  ];

  selectedCity: string = "Dubai";

  constructor() {
    this.selectedCity = localStorage.getItem('city') ?? "All";
  }

  ngOnInit(): void {
    setTimeout(() => {
      let _self = this;
      (function ($) {
        $('#slected_city').niceSelect();

        $("#slected_city").on('change', function (e: any) {
          _self.selectedCity = e.target.value;

          localStorage.setItem("city", _self.selectedCity);

          window.location.reload();
        });


        $('#slected_city').niceSelect('update');
      })(jQuery);
    }, 200);

  }


}
