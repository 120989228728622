import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessProfileService {

  private APIURL: string = environment.APIURL;
  private APIKEY: string = environment.APIKEY;

  constructor(private http: HttpClient) { }

  private _orderPromotedBusiness(businesses: any[]) {
    var promotedArry = [];
    var otherArry = [];

    for (var bus in businesses) {
      var isPromoted = bus["businesssubscriptions"] != null
        ? (bus["businesssubscriptions"] as []).filter((s: any) =>
          s["subscriptionheader"] != "Free Listing" &&
          (s["subscriptiontype"].toString() == "2" ||
            s["subscriptiontype"].toString() == "5") &&
          Date.parse(s["subscriptionenddate"]) > Date.now()
        )
        : false;
      if (isPromoted) {
        promotedArry.push(bus);
      } else {
        otherArry.push(bus);
      }
    }

    promotedArry.concat(otherArry);

    return promotedArry;
  }

  getPromotedBunissProfile(
    count: number = 10,
    lat: number = -1,
    lng: number = -1,
  ): Observable<any> {
    const theUrl = this.APIURL +
      `businessprofile/read_promoted_business.php?limit=${count}&userlat=${lat}&userlng=${lng}`;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    }).pipe(
      catchError((error) => {
        // Handle the error and return null
        // console.error('An error occurred:', error);
        if (error.status === 404) {
          return of(null);
        } else {
          // Other errors
          throw error;
        }
      })
    );
  }

  async getPromotedBunissProfileAsync(count: number = 10,
    lat: number = -1,
    lng: number = -1) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getPromotedBunissProfile(count, lat, lng)).then(res => {
        resolve(res != null ? res.body : null);
      },
        msg => {

        });
    });
    return await promise;
  }

  getNearbyBunissProfile(
    count: number = 10,
    lat: number = -1,
    lng: number = -1,
  ): Observable<any> {
    const theUrl = this.APIURL +
      `businessprofile/read.php?limit=${count}&nearby=1&userlat=${lat}&userlng=${lng}`;

    console.log(theUrl);


    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    }).pipe(
      catchError((error) => {
        // Handle the error and return null
        // console.error('An error occurred:', error);
        if (error.status === 404) {
          return of(null);
        } else {
          // Other errors
          throw error;
        }
      })
    );
  }

  async getNearbyBunissProfileAsync(count: number = 10,
    lat: number = -1,
    lng: number = -1) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getNearbyBunissProfile(count, lat, lng)).then(res => {
        resolve(res != null ? res.body : null);
      },
        msg => {

        });
    });
    return await promise;
  }

  getNearbyBunissProfileByType(
    type: number,
    count: number = 10,
    lat: number = -1,
    lng: number = -1,
    filterData: any[] | null = null,
  ): Observable<any> {
    let theUrl = this.APIURL +
      `businessprofile/read_by_type.php?type=${type}&limit=${count}&nearby=1&doorstoponly=0&userlat=${lat}&userlng=${lng}`;

    if (filterData != null && filterData.length > 0) {
      theUrl += "&filter=" + JSON.stringify(filterData);
    }

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    }).pipe(
      catchError((error) => {
        // Handle the error and return null
        // console.error('An error occurred:', error);
        if (error.status === 404) {
          return of(null);
        } else {
          // Other errors
          throw error;
        }
      })
    );
  }

  async getNearbyBunissProfileByTypeAsync(
    type: number,
    count: number = 10,
    lat: number = -1,
    lng: number = -1,
    filterData: any[] | null = null,
  ) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getNearbyBunissProfileByType(type, count, lat, lng, filterData)).then(res => {
        resolve(res != null ? res.body : null);
      },
        msg => {

        });
    });
    return await promise;
  }

  getBusinessprofile(tradelicensenumber: string, tradelicensecity: string): Observable<any> {
    const theUrl =
      this.APIURL + "businessprofile/read_one_license_number.php?licensenumber=" + tradelicensenumber + "&licensecity=" + tradelicensecity;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getBusinessprofileAsync(tradelicensenumber: string, tradelicensecity: string) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getBusinessprofile(tradelicensenumber, tradelicensecity)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Not Found
          if (msg.status == 404)
            resolve(null);
          else {
            // Error
            reject(msg);
          }
        });
    });
    return await promise;
  }

  getBusinessprofileByid(id: number, onlyactive: boolean = true): Observable<any> {
    const theUrl =
      this.APIURL + "businessprofile/read_one.php?businessid=" + id + "&onlyactive=" + onlyactive;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getBusinessprofileByidAsync(id: number, onlyactive: boolean = true) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getBusinessprofileByid(id, onlyactive)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Not Found
          if (msg.status == 404)
            resolve(null);
          else {
            // Error
            reject(msg);
          }
        });
    });
    return await promise;
  }

  getBunissProfileByType(type: number, lat: number = -1, lng: number = -1, filterData: any[] | null = null): Observable<any> {
    let theUrl =
      this.APIURL + "businessprofile/read_by_type.php?type=" + type + "&userlat=" + lat + "&userlng=" + lng;

    if (filterData != null && filterData.length > 0) {
      theUrl += "&filter=" + JSON.stringify(filterData);
    }

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getBunissProfileByTypeAsync(type: number, lat: number = -1, lng: number = -1, filterData: any[] | null = null) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getBunissProfileByType(type, lat, lng, filterData)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Not Found
          if (msg.status == 404)
            resolve(null);
          else {
            // Error
            reject(msg);
          }
        });
    });
    return await promise;
  }

  getAllCategories(type: number): Observable<any> {
    const theUrl =
      this.APIURL + "category/read_by_type.php?type=" + type;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getAllCategoriesAsync(type: number) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getAllCategories(type)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Not Found
          if (msg.status == 404)
            resolve(null);
          else {
            // Error
            reject(msg);
          }
        });
    });
    return await promise;
  }

  getBransByTypes(types: string[]): Observable<any> {
    const theUrl =
      this.APIURL + "brand/read_by_type.php?types=" + JSON.stringify(types);

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getBransByTypesAsync(types: string[]) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getBransByTypes(types)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Not Found
          if (msg.status == 404)
            resolve(null);
          else {
            // Error
            reject(msg);
          }
        });
    });
    return await promise;
  }

  addBusinessprofile(data: any): Observable<any> {
    const theUrl =
      this.APIURL + "businessprofile/create.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    });
  }

  async addBusinessprofileAsync(data: any) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.addBusinessprofile(data)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  updateBusinessprofile(data: any): Observable<any> {
    const theUrl =
      this.APIURL + "businessprofile/update.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    });
  }

  async updateBusinessprofileAsync(data: any) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.updateBusinessprofile(data)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  approveBusiness(data: any): Observable<any> {
    const theUrl =
      this.APIURL + "businessprofile/change_business_approval.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    });
  }

  async approveBusinessAsync(data: any) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.approveBusiness(data)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  addHits(data: any): Observable<any> {
    const theUrl =
      this.APIURL + "businessautohits/create.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    });
  }

  async addHitsAsync(data: any) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.addHits(data)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  sendMesssage(to: string, subject: string, message: string, extraData?: object) {
    const theUrl = this.APIURL + "mootoriemail/message_send.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    var data = {
      "to": to,
      "subject": subject,
      "message": message,
      ...extraData ?? null,
    };

    this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    }).subscribe(m => {

    });
  }

  async sendMesssageAsync(to: string, subject: string, message: string, extraData?: object) {
    const theUrl = this.APIURL + "mootoriemail/message_send.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    var data = {
      "to": to,
      "subject": subject,
      "message": message,
      ...extraData ?? null,
    };

    var result = this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    })

    let promise = new Promise<void>((resolve, reject) => {
      lastValueFrom(result).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }
}
