<div class="login-area py-20" *ngIf="!logingCompleted">
    <div class="container">
        <div class="col-md-5 mx-auto">
            <div class="login-form">
                <div class="login-header">
                    <img src="assets/img/logo/mootori_Red.png" alt="Mootori">
                    <p>Login with your mootori account</p>
                </div>
                <form class="form w-100" novalidate="novalidate" action="#" (submit)="login($event)">
                    <div class="form-group">
                        <label>Email Address</label>
                        <input type="email" class="form-control" placeholder="Your Email" name="email"
                            (focus)="setLoginType('email')">
                    </div>
                    <div class="separator separator-content my-5">
                        <span class="w-125px text-gray-500 fw-semibold fs-7 text-nowrap">Or with mobile</span>
                    </div>
                    <div class="form-group">
                        <label>Mobile Number</label>

                        <div class="input-group mb-3">
                            <span class="input-group-text">+971</span>
                            <input type="number" class="form-control" placeholder="Your Mobile Number" name="mobile"
                                (focus)="setLoginType('mobile')">
                        </div>

                    </div>

                    <div class="d-flex align-items-center">
                        <button type="submit" class="theme-btn"><i class="far fa-sign-in"></i> Login</button>
                    </div>
                </form>

                <div class="login-footer">
                    <p>Don't have an account? <a href="register">Register.</a></p>
                    <div class="social-login" *ngIf="usesocial">
                        <p>Continue with social media</p>
                        <div class="social-login-list">
                            <a role="button" (click)="loginWithFacebook()"><i class="fab fa-facebook-f"></i></a>
                            <!-- <a role="button" (click)="loginWithGoogle()"><i class="fab fa-google"></i></a> -->

                            <!-- <asl-google-signin-button type='icon' shape="circle" size='large'
                                style="position: relative; top: 15px; display: inline-block;">
                            </asl-google-signin-button> -->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-otp [sendTo]="sendTo" (submitOTP)="otpVerify()" (resendOTP)="resendOTP()" *ngIf="logingCompleted"></app-otp>