import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { AddAutoComponent } from './add-auto/add-auto.component';
import { AddBusinessComponent } from './add-business/add-business.component';
import { AutoDetailsComponent } from './auto-details/auto-details.component';
import { AutoServicesDetailsComponent } from './auto-services-details/auto-services-details.component';
import { AutoServicesComponent } from './auto-services/auto-services.component';
import { AutoSparePartsComponent } from './auto-spare-parts/auto-spare-parts.component';
import { AutoSparepartsDetailsComponent } from './auto-spareparts-details/auto-spareparts-details.component';
import { AutosListComponent } from './autos-list/autos-list.component';
import { ContactComponent } from './contact/contact.component';
import { EditAutoComponent } from './edit-auto/edit-auto.component';
import { EditBusinessComponent } from './edit-business/edit-business.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { MyAutoComponent } from './my-auto/my-auto.component';
import { PolicyComponent } from './policy/policy.component';
import { RegisterComponent } from './register/register.component';
import { ShowroomDetailsComponent } from './showroom-details/showroom-details.component';
import { ShowroomsComponent } from './showrooms/showrooms.component';
import { TermsComponent } from './terms/terms.component';
import { GmsInfoComponent } from './gms-info/gms-info.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'motors', component: AutosListComponent },
  { path: 'mymotors', component: MyAutoComponent },
  { path: 'business/add', component: AddBusinessComponent },
  { path: 'business/edit/:id', component: EditBusinessComponent },
  { path: 'business/showrooms', component: ShowroomsComponent },
  { path: 'business/showrooms/details/:id', component: ShowroomDetailsComponent },
  { path: 'business/services', component: AutoServicesComponent },
  { path: 'business/services/details/:id', component: AutoServicesDetailsComponent },
  { path: 'business/spareparts', component: AutoSparePartsComponent },
  { path: 'business/spareparts/details/:id', component: AutoSparepartsDetailsComponent },
  { path: 'auto/add', component: AddAutoComponent },
  { path: 'auto/edit/:id', component: EditAutoComponent },
  { path: 'auto/details/:id', component: AutoDetailsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'policy', component: PolicyComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'about', component: AboutComponent },
  { path: 'gmsinfo', component: GmsInfoComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
