import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective {
  @Input() allowNegative: boolean = false;
  @Input() allowDecimal: boolean = false;

  private regex?: RegExp;

  constructor(private el: ElementRef) {
    this.setRegex();
  }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const initialValue = this.el.nativeElement.value;
    this.el.nativeElement.value = this.filterValue(initialValue);
    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }

  private setRegex() {
    let pattern = '^[0-9]*';
    if (this.allowNegative) {
      pattern = '^-?' + pattern.substring(1);
    }
    if (this.allowDecimal) {
      pattern += '(\\.[0-9]*)?';
    }

    console.log(pattern);
    
    this.regex = new RegExp(pattern);
  }

  private filterValue(value: string): string {
    const match = value.match(this.regex!);
    return match ? match[0] : '';
  }

  ngOnChanges() {
    this.setRegex();
  }
}
