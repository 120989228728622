<!-- privacy policy -->
<div class="pt-25 pb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 mx-auto">
                <div class="site-heading text-center">
                    <h2 class="site-title">Privacy Policy</h2>
                    <div class="heading-divider"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="terms-content">
                    <h3>Personal Information</h3>
                    <p>
                        Mootori may collect the following information from users of our site:
                    </p>
                    <ul>
                        <li>Name
                        <li>Mobile number
                        <li>E-mail address
                        <li>GPS location
                        <li>Motor details
                            <ul>
                                <li>Chassis number
                                <li>City
                                <li>Plate code
                                <li>Plate number
                                <li>Service history
                            </ul>
                    </ul>

                    <p>
                        In addition, Mootori may collect information regarding Mootori users past orders, requests,
                        favorite
                        business
                        providers, customer service inquiries, business providers reviews and certain social networking
                        preferences
                        (e.g. pages you "Rate" or "Share").
                    </p>
                    <p>
                        Mootori also uses web analytics software to track and analyze traffic on the site in connection
                        with
                        Mootori
                        advertising and promotion of Mootori services. Mootori may publish these statistics or share
                        them
                        with third
                        parties without including Personally Identifiable Information (PII).
                    </p>
                    <p>
                        Non-Personally Identifiable Information (or "Non-PII") is aggregated information, demographic
                        information and
                        any other information that does not reveal your specific identity. We and our third-party
                        service
                        providers may
                        collect Non-PII from you, including your MAC address, your computer type, screen resolution, OS
                        version,
                        Internet browser and demographic data, for example your location, and gender and we may
                        aggregate
                        PII in a
                        manner such that the end-product does not personally identify you or any other user of the Site.
                        We
                        and our
                        third-party service providers may also use cookies, pixel tags, web beacons, and other similar
                        technologies to
                        better serve you with more tailored information and facilitate your ongoing use of our Site. If
                        you
                        do not want
                        information collected using cookies, there is a simple procedure in most browsers that allows
                        you to
                        decline the
                        use of cookies.
                    </p>
                    <p>
                        IP Addresses are the Internet Protocol addresses of the computers that you are using. Your IP
                        Address is
                        automatically assigned to the computer that you are using by your Internet Service Provider
                        (ISP).
                        This number
                        is identified and logged automatically in our server log files whenever users visit the Site,
                        along
                        with the
                        time(s) of such visit(s) and the page(s) that were visited. Collecting IP Addresses is standard
                        practice on the
                        Internet and is done automatically by many websites.
                    </p>
                    <p>
                        We do not sell or rent your personal information to third parties for their marketing purposes
                        without your
                        explicit consent. We may combine your information with information we collect from other
                        companies
                        and use it to
                        improve and personalize our services, content, and advertising. If you do not want to receive
                        marketing
                        communications from us via e-mail or participate in our ad-customization programs, simply change
                        your relevant
                        settings from my profile on the user menu.
                    </p>
                </div>


                <div class="terms-content">
                    <h3>Information Usage</h3>
                    <p>
                        Mootori uses PII to create users' Mootori profile, to:
                    </p>
                    <ul>
                        <li>Provide you with a personalized delightful experience throughout the journey.
                        <li>Communicate with users about Mootori services.
                        <li>Offer users additional services, promotions, and special offers.
                        <li>And charge for purchases made through Mootori.
                    </ul>
                    <p>
                        Users may affirmatively opt-out of receiving promotional communications from Mootori by visiting
                        www.mootori.com. “My Profile” page and providing Mootori with their e-mail address via the
                        opt-out
                        link. Mootori
                        may also use PII to enforce Mootori terms of use and service.
                    </p>
                    <p>
                        Mootori uses cookies to remember users on the site and to enhance users' experience on the site.
                        For
                        example,
                        when users with Mootori profiles return to the site, cookies identify those users and allow the
                        site
                        to provide
                        certain user-specific information such as Mootori login information, choice of website language
                        and
                        country
                        selection in addition to any other information we might collect in the future. Mootori does not
                        sell
                        the
                        information it collects to third parties. Mootori shares collected PII to third-party vendors
                        and
                        service
                        providers with whom Mootori works to provide application programming interfaces ("APIs") and
                        other
                        functions for
                        the site in connection with the delivery of Mootori services. In addition, Mootori shares users'
                        Mootori
                        requests content, special requests, name, address, mobile number voice messages, video messages,
                        images, and
                        motor details with business providers where users' requests are placed to the extent necessary
                        to
                        process those
                        requests. Mootori may also disclose PII to third parties such as attorneys, collection agencies,
                        tribunals, or
                        law enforcement authorities pursuant to valid requests in connection with alleged violations of
                        Mootori terms of
                        use and service or other alleged contract violations, infringement or similar harm to persons or
                        property.
                    </p>
                    <p>
                        User generated content posted through the site such as service/business providers reviews and
                        certain social
                        networking preferences (e.g. pages you "Like" or "Recommend") may be viewed by the general
                        public.
                        Accordingly,
                        Mootori cannot ensure the privacy of any PII included in such user generated content.
                    </p>
                    <p>
                        Non-Personally Identifiable Information. Because Non-PII does not personally identify you, we
                        may
                        use such
                        information for any purpose. In addition, we reserve the right to share such Non PII, which does
                        not
                        personally
                        identify you, with our affiliates and with other third parties, for any purpose. In some
                        instances,
                        we may
                        combine Non-PII with PII (such as combining your name with your geographical location). If we do
                        combine any
                        Non-PII with PII, the combined information will be treated by us as PII hereunder if it is so
                        combined.
                    </p>
                    <p>
                        IP Addresses. We use IP addresses for purposes such as calculating site usage levels, helping
                        diagnose server
                        problems, and administering the site. We may also use and disclose IP Addresses for all the
                        purposes
                        for which
                        we use and disclose PII. Please note that we treat IP Addresses, server log files and related
                        information as
                        Non-PII, except where we are required to do otherwise under applicable law.
                    </p>
                    <p>
                        We may disclose your information to our staff and to third parties involved in the fulfilment of
                        your request or
                        order, and the analysis and support of your use of the website or app. We may disclose your
                        information if
                        necessary to protect our legal rights, if the information relates to actual or threatened
                        harmful
                        conduct, or we
                        have good faith belief that such action is necessary to (1) conform to the requirements of law
                        or
                        comply with
                        governmental orders, court orders, or legal process served on us or (2) to protect and defend
                        our
                        property or
                        other rights, the users of the website or the public. This includes exchanging information with
                        other companies
                        and organizations for fraud protection and credit risk protection.

                    </p>
                </div>

                <div class="terms-content">
                    <h3>Registration</h3>
                    <p>
                        To register with Mootori as a consumer or a partner, we require you to supply us with your email
                        address, mobile
                        number, name and gender needed to create user profile. This information you provide is used to
                        confirm your
                        request and to communicate with you for any further requirements.
                    </p>
                    <p>
                        Upon successful creation of the profile, we may send you a welcoming email. We will also
                        communicate
                        with you in
                        response to your inquiries, to provide the services you request, and to manage your profile. We
                        may
                        communicate
                        with you by email or via phone, SMS or WhatsApp on your mobile number.
                    </p>
                    <p>
                        If you supply us with your email address, mobile number, name and gender, you may later access
                        it,
                        update it,
                        modify it, and delete any inaccuracies by accessing your profile. You may also choose simply not
                        to
                        provide us
                        with your email address and mobile number. However, you will not be able to register in Mootori,
                        submit requests
                        or place orders or benefit from other services. We communicate part of your contact information
                        (name, email,
                        mobile number and gender) with the business provider from which you have placed an order or
                        submit a
                        request. We
                        also use this information to facilitate and improve your use of the website and app, to
                        communicate
                        with you,
                        for internal purposes and to comply with any requirements of law. This information may be
                        disclosed
                        to our staff
                        and to third parties involved in the fulfilment of your request or the analysis and support of
                        your
                        use of the
                        website or app. We do not sell and will not disclose your personal contact information to third
                        parties other
                        than as specified in this Privacy Policy without your permission unless we are legally entitled
                        or
                        obliged to do
                        so (for example, if required to do so by Court order or for the purposes of prevention of fraud
                        or
                        other crime).

                    </p>
                </div>

                <div class="terms-content" id="AD">
                    <h3>Account Deletion and Data Removal</h3>
                    <p>
                        We understand that circumstances change, and if you've decided to part ways, we've streamlined
                        the process for account deletion. Please carefully review the information below to ensure a
                        smooth transition. In this section, we'll guide you through the steps of deleting your account,
                        explain what happens to your data, and provide essential points to consider before initiating
                        the deletion.
                    </p>
                </div>

                <div class="terms-content">
                    <h3>Account Deletion</h3>
                    <p>
                        We value your security, which is why we require some key steps to ensure a verified and secure
                        account deletion process. Once your account is deleted, please note that reactivation, data
                        recovery, and access restoration will not be possible except for specific data that we are
                        legally required to retain. Should you wish to use Mootori again in the future, you'll need to
                        set up a new account.
                    </p>
                </div>

                <div class="terms-content">
                    <h3>The Deletion Process</h3>
                    <p>
                        When you submit a request to delete your account:
                    </p>
                    <ol>
                        <li>
                            <b>Data Deletion: </b>Once your request is approved, all your personal information will be
                            deleted, except for specific data that we are legally required to retain.
                        </li>
                        <li>
                            <b>Processing Time: </b>If you do not have any active repairs or services, your account will
                            be deleted immediately.
                        </li>
                    </ol>
                </div>

                <div class="terms-content">
                    <h3>Preparation Before Deletion</h3>
                    <p>
                        Before proceeding with your account deletion request, ensure the following steps are taken:When
                        you submit a request to delete your account:
                    </p>
                    <ol>
                        <li>
                            <b>Services and Repairs in Progress: </b>OComplete any ongoing services or repairs.
                        </li>
                        <li>
                            <b>Advertisements: </b>If you have any active advertisement, it will be automatically
                            cancelled upon account deletion.
                        </li>
                        <li>
                            <b>Subscriptions: </b>If you hold any subscription or promoted advertisement, rest assured
                            that these will be automatically cancelled upon account deletion.
                        </li>
                    </ol>
                </div>

                <div class="terms-content">
                    <h3>Request Submission</h3>
                    <p>
                        Please carefully consider these guidelines before proceeding with your account deletion request.
                        We appreciate the opportunity to serve you and hope you've had a positive experience with
                        Mootori.
                    </p>
                    <p>
                        If you have any questions or concerns regarding the account deletion process or our privacy
                        policy, do not hesitate to contact our support team. Your satisfaction remains our priority.
                    </p>
                </div>

                <div class="terms-content">
                    <h3>Payment Information</h3>
                    <p>
                        When you place an order through the website or app, you are required to select a method of
                        payment.
                        Mootori is
                        not interested in your debit / credit card information nor do store any of your debit / credit
                        information,
                        since orders could be paid at the doorstep or directly to the business provider through methods
                        of
                        payment such
                        as cash or credit card. Online debit / credit card payment is also an option and for some
                        business
                        providers it
                        can be the only method of payment. For transactions with online debit / credit cards, we
                        transmit
                        your entire
                        card information to the appropriate debit / credit card company in an encrypted format with
                        globally
                        accepted
                        rules and applications during order processing. Upon your choice, we keep a part of your card
                        information in an
                        encrypted format, taking precaution to maintain physical, electronic, and procedural safeguards
                        over
                        your credit
                        card information.
                    </p>
                </div>

                <div class="terms-content">
                    <h3>Request and Order Information</h3>
                    <p>
                        Personal information required during the request or order process includes name, address, mobile
                        number, email
                        address and other similar information used to identify you and complete a request or an order.
                        Personal
                        information collected at our site and app will be shared with a business provider when
                        processing
                        your request
                        or order. We have an agreement with all member business providers, restricting disclosure or
                        further
                        processing
                        of personal information provided to them by us. Your personal information will be used to notify
                        you
                        of your
                        request or order status. Personal information will not be shared with any of our business
                        partners
                        or affiliates
                        without your permission. It is treated as confidential, and will not be disclosed to outside
                        parties, unless
                        compelled by applicable legislation. We only send marketing emails to those people who have
                        specifically
                        requested to receive this information.
                    </p>
                </div>

                <div class="terms-content">
                    <h3>Navigation Data</h3>
                    <p>
                        As you browse our website or app, we gather navigational and click stream data that shows which
                        pages you have
                        visited, what kind of features you have used or how long you have stayed on a particular page
                        etc.
                        This
                        information will not reveal your identity or be linked to you personally. We use this
                        information to
                        complete,
                        support and analyze your transactions with us; to facilitate and improve your use of the website
                        or
                        app; to
                        comply with any requirements of law; to serve, or have a third party serve specialized or
                        relevant
                        advertising
                        content and/or recommendations to you on the website or on a third party’s website and for
                        internal
                        purposes.
                        This information may be disclosed only to our staff and to third parties involved in the
                        completion
                        of your
                        transaction, the fulfilment of your request or order, and the analysis and support of your use
                        of
                        the website or
                        app.
                    </p>
                </div>

                <div class="terms-content">
                    <h3>Security</h3>
                    <p>
                        We use reasonable organizational, technical and administrative measures to protect PII under our
                        control.
                        Unfortunately, no data transmission over the Internet or data storage system can be guaranteed
                        to be
                        100%
                        secure. Please do not send us sensitive information through email. If you have reason to believe
                        that your
                        interaction with us is no longer secure (for example, if you feel that the security of any
                        profile
                        you might
                        have with us has been compromised), you must immediately notify us of the problem by contacting
                        us
                        in accordance
                        with the "Contacting Us" section below (note that if you choose to notify us via physical mail,
                        this
                        will delay
                        the time it takes for us to respond to the problem).
                    </p>
                    <p>
                        Mootori has secure pages to collect user information and most critical data is stored in
                        encrypted
                        form. We use
                        various technical and management practices to help protect the confidentiality, security and
                        integrity of data
                        stored on our system.

                    </p>
                </div>

                <div class="terms-content">
                    <h3>Marketing</h3>
                    <p>
                        It is very important to us that we provide you with the highest level of service. To help us do
                        this, from time
                        to time we may send you details of products, services, discounts, promotions, and campaigns,
                        which
                        we think, may
                        be of interest to you. We may send you marketing communications with your consent. If at any
                        time
                        you do not
                        wish to receive these details and wish to unsubscribe from marketing communications, contact us
                        via
                        e-mail or
                        log onto your profile to change your relevant settings from my profile on the user main menu.
                    </p>
                </div>

                <div class="terms-content">
                    <h3>Announcements</h3>
                    <p>
                        Mootori will send users announcements to notify them whenever there is there is a service
                        suspension
                        for
                        maintenance, download a new release of the app, or similar notification that are not promotional
                        in
                        nature.
                        Users can opt-out these communications by updating their profiles.
                    </p>
                </div>

                <div class="terms-content">
                    <h3>Invite Friends</h3>
                    <p>
                        If you choose to use our referral service to tell a friend about our site, we will ask you for
                        your
                        friend’s
                        name and email address. We will automatically send your friend a one-time email inviting him or
                        her
                        to visit the
                        site. We store this information for the sole purpose of sending this one-time email and tracking
                        the
                        success of
                        our referral program.
                    </p>
                    <p>
                        Your friend may contact us at to request that we remove this information from our database.

                    </p>
                </div>

                <div class="terms-content">
                    <h3>Privacy Policy Amendment</h3>
                    <p>
                        Mootori may change this Privacy Policy at any time by publishing the new version on the website
                        or
                        app.
                    </p>
                </div>

                <div class="terms-content">
                    <h3>Contact</h3>
                    <p>
                        All comments, queries and requests relating to our use of your information are welcomed and
                        should
                        be addressed
                        to Mootori Portal.
                    </p>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- privacy policy end -->