<!-- contact area -->
<div class="contact-area py-20">
    <div class="container">
        <div class="contact-content">
            <div class="row">
                <div class="col-md-4">
                    <div class="contact-info">
                        <div class="contact-info-icon">
                            <i class="fal fa-map-location-dot"></i>
                        </div>
                        <div class="contact-info-content">
                            <h5>Address</h5>
                            <p>Dubai, UAE</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="contact-info">
                        <div class="contact-info-icon">
                            <i class="fal fa-phone-volume"></i>
                        </div>
                        <div class="contact-info-content">
                            <h5>Call Us</h5>
                            <p>+971 5277 2020 1</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="contact-info">
                        <div class="contact-info-icon">
                            <i class="fal fa-envelopes"></i>
                        </div>
                        <div class="contact-info-content">
                            <h5>Email Us</h5>
                            <p>info@mootori.com</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-3">
                    <div class="contact-info">
                        <div class="contact-info-icon">
                            <i class="fal fa-alarm-clock"></i>
                        </div>
                        <div class="contact-info-content">
                            <h5>Open Time</h5>
                            <p>24 / 7</p>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="contact-wrapper">
            <div class="row">
                <div class="col-lg-6 align-self-center">
                    <div class="contact-img">
                        <img src="assets/img/mootori/contact-01.jpg" alt="Mootori">
                    </div>
                </div>
                <div class="col-lg-6 align-self-center">
                    <div class="contact-form">
                        <div class="contact-form-header">
                            <h2>Get In Touch</h2>
                            <p>It is a long established fact that a reader will be distracted by the readable
                                content of a page randomised words which don't look even slightly when looking at its
                                layout. </p>
                        </div>
                        <div id="contact-form">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="txt_name" placeholder="Your Name"
                                            required>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="email" class="form-control" id="txt_email" placeholder="Your Email"
                                            required>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" id="txt_subject" placeholder="Your Subject"
                                    required>
                            </div>
                            <div class="form-group">
                                <textarea id="txt_message" cols="30" rows="5" class="form-control"
                                    placeholder="Write Your Message"></textarea>
                            </div>
                            <button type="button" class="theme-btn" (click)="sendMessage()">Send
                                Message <i class="far fa-paper-plane"></i></button>
                            <div class="col-md-12 mt-3">
                                <div class="form-messege text-success"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end contact area -->