<div class="blog-single-area pt-20 pb-120" *ngIf="showroom">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="blog-single-wrapper">
                    <div class="blog-single-content">

                        <div class="car-single-slider">
                            <div class="item-gallery">
                                <div class="flexslider-thumbnails">
                                    <ul class="slides">
                                        <li *ngFor="let path of showroomImages"
                                            [attr.data-thumb]="USERSFILESURL + '/' + showroom.businessid + path + randomQueryParam">
                                            <img [src]="USERSFILESURL + '/' + showroom.businessid + path + randomQueryParam"
                                                alt="{{showroom.businessname}}">
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="blog-info">
                            <!-- <div class="blog-meta">
                                <div class="blog-meta-left">
                                    <ul>
                                        <li><i class="far fa-user"></i><a href="#">Jean R Gunter</a></li>
                                        <li><i class="far fa-comments"></i>3.2k Comments</li>
                                        <li><i class="far fa-thumbs-up"></i>1.4k Like</li>
                                    </ul>
                                </div>
                                <div class="blog-meta-right">
                                    <a href="#" class="share-btn"><i class="far fa-share-alt"></i>Share</a>
                                </div>
                            </div> -->
                            <div class="blog-details">
                                <div class="blog-details-title-container">
                                    <h3 class="blog-details-title mb-20"><img
                                            [src]="USERSFILESURL + '/' + showroom.businessid + showroom.businesslogopath + randomQueryParam"
                                            alt="{{showroom.businessname}}" style="height: 30px;">
                                        {{showroom.businessname}}</h3>

                                    <span><i class="far fa-eye"></i> Views: {{showroom.hits + 1}}</span>
                                </div>
                                <p class="mb-10">
                                    {{showroom.businessdescription}}
                                </p>

                            </div>

                        </div>

                        <hr />

                        <div class="row dealer-single car-area {{isListView ? 'list' : 'grid'}}" *ngIf="pageAutos">

                            <div class="col-md-12">
                                <h4 class="widget-title">Autos List</h4>
                                <div class="car-sort" style="background: var(--theme-bg-light);">
                                    <!-- <h6>Showing 1-10 of 50 Results</h6> -->
                                    <div class="car-sort-list-grid">
                                        <a class="car-sort-grid me-1" role="button" (click)="changeView(false)"><i
                                                class="far fa-grid-2"></i></a>
                                        <a class="car-sort-list active" role="button" (click)="changeView(true)"><i
                                                class="far fa-list-ul"></i></a>
                                    </div>
                                    <div class="col-md-3 car-sort-box">
                                        <select class="select" (change)="sortChanged($event)">
                                            <option value="reset_none">Sort By Default</option>
                                            <option value="year_desc">Sort By Newest Year</option>
                                            <option value="year_asc">Sort By Oldest Year</option>
                                            <option value="price_asc">Sort By Low Price</option>
                                            <option value="price_desc">Sort By High Price</option>
                                            <option value="added_asc">Sort By Latest Added</option>
                                            <option value="added_desc">Sort By Oldest Added</option>
                                            <option value="distance_desc">Sort By Nearest Distance</option>
                                            <option value="distance_asc">Sort By Farthest Distance</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="isListView">
                                <div class="col-md-6 col-lg-12" *ngFor="let auto of pageAutos">
                                    <div class="car-item" (click)="addHits(auto)">
                                        <div class="stm-badge-directory heading-font" *ngIf="auto.isPromoted"> Promoted
                                        </div>

                                        <div class="car-img">
                                            <span class="car-status status-1">{{auto.mileage > 0 ? "Used" :
                                                "New"}}</span>
                                            <img [src]="USERSFILESURL + '/' + auto.autoid + auto.autophotopath1 + randomQueryParam"
                                                alt="">
                                            <div class="car-btns" *ngIf="canEdit">
                                                <!-- <a href="#"><i class="far fa-heart"></i></a> -->
                                                <a href="auto/edit/{{auto.autoid}}"><i
                                                        class="fa-solid fa-pen-to-square"></i></a>
                                            </div>
                                        </div>
                                        <div class="car-content">
                                            <div class="car-top">
                                                <h4><a role="button" (click)="addHits(auto)"><img
                                                            [src]="FILESURL + '/brandslogo/' + auto.makemodeltrim[0].makelogo + randomQueryParam"
                                                            style="height: 15px !important; padding-right: 5px;"
                                                            alt="{{auto.makemodeltrim[0].autodisplayname}}">{{auto.makemodeltrim[0].autodisplayname}}</a>
                                                </h4>
                                                <!-- <div class="car-rate">
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <span>5.0 (58.5k Review)</span>
                                                </div> -->
                                            </div>
                                            <ul class="car-list">
                                                <li>
                                                    <!-- <i class="far fa-steering-wheel"></i> -->
                                                    <img src="assets/img/mootori/gearservices.svg" style="height: 14px;"
                                                        alt="gear services">
                                                    {{ auto.transmission == 1
                                                    ? "Automatic"
                                                    : "Manual"}}
                                                </li>
                                                <li><i class="far fa-road"></i>{{auto.mileage | number}}km</li>
                                                <li><i class="fa-regular fa-calendar-days"></i>Model: {{auto.year}}</li>
                                                <li><i class="far fa-gas-pump"></i>{{engineType[auto.enginetype - 1]}}
                                                </li>
                                            </ul>
                                            <p style="min-height: 75px;">
                                                {{auto.autodescription}}
                                            </p>
                                            <div class="car-footer">
                                                <span class="car-price">AED {{auto.price | number: '1.0-0'}}</span>
                                                <a role="button" class="theme-btn" (click)="addHits(auto)"><span
                                                        class="far fa-eye"></span>Details</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="!isListView">
                                <div class="col-md-6 col-lg-6" *ngFor="let auto of pageAutos">
                                    <div class="car-item" (click)="addHits(auto)">
                                        <div class="stm-badge-directory heading-font" *ngIf="auto.isPromoted"> Promoted
                                        </div>

                                        <div class="car-img">
                                            <span class="car-status status-1">{{auto.mileage > 0 ?"Used" :
                                                "New"}}</span>
                                            <img [src]="USERSFILESURL + '/' + auto.autoid + auto.autophotopath1 + randomQueryParam"
                                                alt="{{auto.makemodeltrim[0].autodisplayname}}">
                                            <div class="car-btns" *ngIf="canEdit">
                                                <!-- <a href="#"><i class="far fa-heart"></i></a> -->
                                                <a href="auto/edit/{{auto.autoid}}"><i
                                                        class="fa-solid fa-pen-to-square"></i></a>
                                            </div>
                                        </div>
                                        <div class="car-content">
                                            <div class="car-top" style="height: 40px;">
                                                <h4><a role="button" (click)="addHits(auto)"><img
                                                            [src]="FILESURL + '/brandslogo/' + auto.makemodeltrim[0].makelogo + randomQueryParam"
                                                            style="height: 15px !important; padding-right: 5px;"
                                                            alt="{{auto.makemodeltrim[0].autodisplayname}}">{{auto.makemodeltrim[0].autodisplayname}}</a>
                                                </h4>
                                                <!-- <div class="car-rate">
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <span>5.0 (58.5k Review)</span>
                                                </div> -->
                                            </div>
                                            <ul class="car-list" style="height: 65px;">
                                                <li>
                                                    <!-- <i class="far fa-steering-wheel"></i> -->
                                                    <img src="assets/img/mootori/gearservices.svg" style="height: 14px;"
                                                        alt="gear services">
                                                    {{ auto.transmission == 1
                                                    ? "Automatic"
                                                    : "Manual"}}
                                                </li>
                                                <li><i class="far fa-road"></i>{{auto.mileage | number}}km</li>
                                                <li><i class="fa-regular fa-calendar-days"></i>Model: {{auto.year}}</li>
                                                <li><i class="far fa-gas-pump"></i>{{engineType[auto.enginetype - 1]}}
                                                </li>
                                            </ul>
                                            <div class="car-footer">
                                                <span class="car-price">AED {{auto.price | number: '1.0-0'}}</span>
                                                <a role="button" class="theme-btn" (click)="addHits(auto)"><span
                                                        class="far fa-eye"></span>Details</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="pagination-area" *ngIf="allAutos && allAutos.length > itemsPerPage">
                                <div aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link" role="button" aria-label="Previous"
                                                (click)="goToPreviousPage()">
                                                <span aria-hidden="true"><i class="far fa-arrow-left"></i></span>
                                            </a>
                                        </li>
                                        <li class="page-item me-2" style="width: 70px;">

                                            <!-- <a class="page-link"
                                                role="button" (click)="goToPage(i + 1)">{{i+ 1}}</a> -->

                                            <select class="select " id="list_pagination">
                                                <option value="{{i+ 1}}"
                                                    *ngFor="let page of  [].constructor(pageCount); let i = index"
                                                    [selected]="i == 0">{{i+ 1}}</option>
                                            </select>
                                        </li>
                                        <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li> -->
                                        <li class="page-item">
                                            <a class="page-link" role="button" aria-label="Next"
                                                (click)="goToNextPage()">
                                                <span aria-hidden="true"><i class="far fa-arrow-right"></i></span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <h4 class="widget-title">Location</h4>
                        <div class="car-single-map">
                            <div class="contact-map">
                                <!-- <iframe [src]="mapURL()" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->

                                <div id="map"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <aside class="sidebar">
                    <div class="widget">
                        <h5 class="widget-title">Contact</h5>
                        <div class="car-single-author">

                            <div class="car-single-author-content">
                                <span><i class="far fa-envelopes"></i> {{showroom.businessemail}}</span>

                                <br>
                                <span><i class="far fa-phone-volume"></i>
                                    +971{{showroom.businesscontactnumber}}</span>

                                <br *ngIf="showroom.businessmobilenumber">
                                <span *ngIf="showroom.businessmobilenumber"><i class="fa-solid fa-mobile-retro"></i>
                                    +971{{showroom.businessmobilenumber}}</span>

                                <br *ngIf="showroom.businesssecondarymobilenumber">
                                <span *ngIf="showroom.businesssecondarymobilenumber"><i
                                        class="fa-solid fa-mobile-retro"></i>
                                    +971{{showroom.businesssecondarymobilenumber}}</span>

                                <br *ngIf="showroom.businesswhatsappnumber">
                                <span *ngIf="showroom.businesswhatsappnumber"><i class="fa-brands fa-whatsapp"></i>
                                    +971{{showroom.businesswhatsappnumber}}</span>
                                <!-- <div class="car-single-author-social">
                                    <a href="#"><i class="fab fa-facebook"></i></a>
                                    <a href="#"><i class="fab fa-instagram"></i></a>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                    <a href="#"><i class="fab fa-linkedin"></i></a>
                                </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="widget brands" *ngIf="showroom && showroom.businessbrands">
                        <h5 class="widget-title">Brands</h5>
                        <div class="row">
                            <div class="col-6" *ngFor="let brand of showroom.businessbrands">
                                <div class="category-item wow fadeInUp" data-wow-delay=".25s"
                                    style="visibility: visible; animation-delay: 0.25s; animation-name: fadeInUp;">
                                    <div class="category-img">
                                        <img [src]="FILESURL + '/brandslogo/' + brand.brandlogopath"
                                            alt="{{brand.brandname}}">
                                    </div>
                                    <h5>{{brand.brandname}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="widget" *ngIf="showroom && showroom.autocondition">
                        <h5 class="widget-title">Condition</h5>
                        <ul class=" about-list list-unstyled">
                            <li *ngIf="showroom.autocondition == 1 || showroom.autocondition == 3">New</li>
                            <li *ngIf="showroom.autocondition == 2 || showroom.autocondition == 3">Used</li>
                        </ul>
                    </div>

                    <div class="widget wh" *ngIf="showroom && showroom.businessworkinghours">
                        <h5 class="widget-title">Working Hours</h5>
                        <ul>
                            <li *ngFor="let wh of showroom.businessworkinghours; let i = index;">

                                <span class="badge text-bg-success" style="width: 40px;"
                                    *ngIf="wh.isweekend == 0">{{getDayName(i)}}</span>
                                <span class="badge text-bg-danger" style="width: 40px;"
                                    *ngIf="wh.isweekend == 1">{{getDayName(i)}}</span>
                                <div class="d-inline-block ms-2"
                                    *ngIf="(wh.starttime != '00:00:00' && wh.closetime != '00:00:00') || (wh.starttime != '00:00:00' && wh.closetime == '00:00:00')">
                                    <span>
                                        <i class="far fa-alarm-clock me-1" style="color: var(--theme-color);"></i>
                                        ({{(wh.isweekend == 0) ? (timeFormat(wh.starttime) + " - " +
                                        timeFormat(wh.closetime)) : "Closed"}})
                                    </span>
                                    <span class="d-block" style="font-size: 10px; color: orange;"
                                        *ngIf="wh.breakstarttime && wh.breakendtime">
                                        Break ({{timeFormat(wh.breakstarttime) + " - " +
                                        timeFormat(wh.breakendtime)}})
                                    </span>
                                </div>

                                <div class="d-inline-block ms-2"
                                    *ngIf="wh.starttime == '00:00:00' && wh.closetime == '00:00:00'">
                                    <span>
                                        <i class="far fa-alarm-clock me-1" style="color: var(--theme-color);"></i>
                                        Open 24 hours
                                    </span>
                                </div>

                            </li>
                        </ul>

                    </div>

                </aside>
            </div>
        </div>
    </div>
</div>