import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/services/authentication.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  usesocial: boolean = false;

  verifyMode: boolean = false;
  currentOTP: string = "";

  sendTo: string | null = null;

  private _verifyType: string | null = null;

  newUser: any = {
    "usermobile": null,
    "userlang": 2,
    "usercountry": "UAE",
    "username": null,
    "useremail": null,
    "referraluserid": 0,
    "mobileverified": 0,
    "emailverified": 0,
    "userreferralcode": "",
    "userstatus": 1,
  };

  errors: string[] = [];

  constructor(private authenticationService: AuthenticationService) {
    document.querySelector(".preloader")?.setAttribute("style", "display: none;");
  }

  ngOnInit(): void {
    localStorage.removeItem('user');
  }

  async setVerifyType(type: string) {
    document.querySelector(".preloader")?.removeAttribute("style");

    this._verifyType = type;

    var verifyBy = this._verifyType === "email" ? (<HTMLInputElement>document.querySelector("[name='email']")).value : '971' + (<HTMLInputElement>document.querySelector("[name='mobile']")).value;

    this.currentOTP = this.authenticationService.generateOTP();

    if (this._verifyType === "email") {
      let isValid = this.validate("eotp");
      if (isValid) {
        this.sendTo = verifyBy;

        this.authenticationService.sendOTP(verifyBy, this.currentOTP);

        this.verifyMode = true;
      }
    } else {
      let isValid = this.validate("motp");
      if (isValid) {
        this.sendTo = verifyBy;

        let mes = `Your OTP is ${this.currentOTP}, it valid only for 5 minutes. Use this Passcode to complete your transaction.`

        await this.authenticationService.sendSMSAsync(verifyBy, mes);

        // await this.authenticationService.smsOTPAsync(verifyBy);

        this.verifyMode = true;
      }
    }

    document.querySelector(".preloader")?.setAttribute("style", "display: none;");
  }

  async otpVerify() {
    var otp1 = (<HTMLInputElement>document.querySelector("[name='code_1']"));
    var otp2 = (<HTMLInputElement>document.querySelector("[name='code_2']"));
    var otp3 = (<HTMLInputElement>document.querySelector("[name='code_3']"));
    var otp4 = (<HTMLInputElement>document.querySelector("[name='code_4']"));

    var otp = otp1.value + otp2.value + otp3.value + otp4.value;

    document.querySelector(".preloader")?.removeAttribute("style");

    var verifyBy = this._verifyType === "email" ? (<HTMLInputElement>document.querySelector("[name='email']")).value : '971' + (<HTMLInputElement>document.querySelector("[name='mobile']")).value;

    if (this._verifyType === "email") {
      if (otp === this.currentOTP) {
        this.verifyMode = false;

        this.newUser.useremail = verifyBy;
        this.newUser.emailverified = 1;

        this._verifyType = null;

        this.sendTo = null;
      }
    } else {
      // var verified: any = await this.authenticationService.verifyOTPAsync(verifyBy, otp);

      // if (verified["status"] == "Verified") {
      if (otp === this.currentOTP) {
        this.verifyMode = false;

        this.newUser.usermobile = verifyBy;
        this.newUser.mobileverified = 1;

        this._verifyType = null;

        this.sendTo = null;
      }
    }

    otp1.value = '';
    otp2.value = '';
    otp3.value = '';
    otp4.value = '';

    document.querySelector(".preloader")?.setAttribute("style", "display: none;");

    return false;
  }

  async resendOTP() {
    var verifyBy = this._verifyType === "email" ? (<HTMLInputElement>document.querySelector("[name='email']")).value : '971' + (<HTMLInputElement>document.querySelector("[name='mobile']")).value;

    this.currentOTP = this.authenticationService.generateOTP();
    if (this._verifyType === "email") {
      this.authenticationService.sendOTP(verifyBy, this.currentOTP);
    } else {
      // await this.authenticationService.smsOTPAsync("971" + verifyBy);

      let mes = `Your OTP is ${this.currentOTP}, it valid only for 5 minutes. Use this Passcode to complete your transaction.`

      await this.authenticationService.sendSMSAsync(verifyBy, mes);
    }

    return false;
  }

  validateEmail(value: string) {
    let pattern = "^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9-_]+\.[a-zA-Z]+";
    let regex = new RegExp(pattern);
    return (!regex.test(value)) ? false : true;
  }

  validateMobile(value: string) {
    let pattern = /^(05\d{8}|5\d{8,9})$/;
    let regex = new RegExp(pattern);
    return (!regex.test(value)) ? false : true;
  }

  validate(type: string) {
    this.errors = [];

    let emailCtrl = <HTMLInputElement>document.querySelector("[name='email']");
    let mobileCtrl = <HTMLInputElement>document.querySelector("[name='mobile']");

    switch (type) {
      case "eotp":
        if (emailCtrl.value === "") {
          this.errors.push("Enter Correct Email");
        }
        break;
      case "motp":
        if (mobileCtrl.value === "") {
          this.errors.push("Enter Correct Mobile Number");
        }
        break;
      case "register":
        if (!this.newUser.username)
          this.errors.push("Enter your Full Name.");

        // if (!this.newUser.useremail)
        //   this.errors.push("Enter your Email Address.");

        if (emailCtrl.value.trim() != "" && !this.validateEmail(emailCtrl.value.trim()))
          this.errors.push("Enter Correct Email Address.");

        if (mobileCtrl.value.trim() == "")
          this.errors.push("Enter your Mobile Number.");
        else if (mobileCtrl.value.trim() != "" && !this.validateMobile(mobileCtrl.value.trim()))
          this.errors.push("Enter Correct Mobile Number.");

        if (emailCtrl.value.trim() != "" && !this.newUser.emailverified)
          this.errors.push("Verify your Email Address.");
        if (!this.newUser.mobileverified)
          this.errors.push("Verify your Mobile Number.");

        let check = <HTMLInputElement>document.getElementById("agree");

        if (!check.checked)
          this.errors.push("You must agree Terms of Service.");
        break;
    }

    return this.errors.length === 0;
  }

  async register(event: any) {
    event.stopPropagation()
    event.preventDefault();

    var name = (<HTMLInputElement>document.querySelector("[name='username']"));

    this.newUser.username = name.value;
    this.newUser.userreferralcode = this.makeRandom(10);

    let isValid = this.validate("register");

    if (isValid) {
      let userid = await this.authenticationService.registerAsync(this.newUser);

      this.newUser.userid = userid;

      localStorage.setItem('user', JSON.stringify(this.newUser));

      window.location.href = "home";
    }
  }

  private makeRandom(lengthOfCode: number) {
    let possible = "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890";

    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}
