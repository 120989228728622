import { MapsAPILoader } from '@agm/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AutoService } from 'src/services/auto.service';
import { BusinessProfileService } from 'src/services/business-profile.service';
import { DynamicScriptLoaderService } from 'src/services/dynamic-script-loader.service';

declare var jQuery: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  USERSFILESURL: string = environment.USERSFILESURL;
  FILESURL: string = environment.FILESURL;

  randomQueryParam = `?v=${Math.random().toString(36).substring(7)}`;

  currentUser: any = null;

  latitude: number = -1;
  longitude: number = -1;

  autos: any[] = null;

  promotedBusniss: any[] = null;

  nearbyBusiness: any[] = null;

  nearbyBusinessService: any[] = null;

  nearbyBusinessSPare: any[] = null;

  nearbyBusinessShowroom: any[] = null;

  engineType: any[] = ["Petrol", "Diesel", "Hybrid", "Electric"];

  constructor(private autoService: AutoService, private businessProfileService: BusinessProfileService, private dynamicScriptLoader: DynamicScriptLoaderService, private mapsAPILoader: MapsAPILoader) {
    this.currentUser = JSON.parse(localStorage.getItem('user')!);
  }

  async ngOnInit(): Promise<void> {

    (function ($) {
      $(window).on('load', function () {
        $(".preloader").fadeOut("slow");
      });
    })(jQuery);

    await this.loadPageData();

    // await this.getData();

    setTimeout(() => {
      this.dynamicScriptLoader.load("main");
    }, 0);
  }

  async loadPageData() {
    let selectedCity = localStorage.getItem('city') ?? "All";

    try {
      this.autos = await this.autoService.getAllAutosAsync(12, -1, -1, selectedCity != "All" ? [{ "key": "city", "value": selectedCity }] : null);
    } catch (error) {

    }

    try {
      this.promotedBusniss = await this.businessProfileService.getPromotedBunissProfileAsync(12, this.latitude, this.longitude);
    } catch (error) {

    }

    try {
      this.nearbyBusiness = await this.businessProfileService.getNearbyBunissProfileAsync(12, 25.1576349, 55.2262386);
    } catch (error) {

    }

    try {
      this.nearbyBusinessService = await this.businessProfileService.getNearbyBunissProfileByTypeAsync(1, 12, this.latitude, this.longitude, selectedCity != "All" ? [{ "key": "location", "value": selectedCity }] : null);
    } catch (error) {

    }

    try {
      this.nearbyBusinessSPare = await this.businessProfileService.getNearbyBunissProfileByTypeAsync(2, 12, this.latitude, this.longitude, selectedCity != "All" ? [{ "key": "location", "value": selectedCity }] : null);
    } catch (error) {

    }

    try {
      this.nearbyBusinessShowroom = await this.businessProfileService.getNearbyBunissProfileByTypeAsync(3, 12, this.latitude, this.longitude, selectedCity != "All" ? [{ "key": "location", "value": selectedCity }] : null);
    } catch (error) {

    }
  }

  getPageName(business: any) {
    let page = "";
    switch (business.businessactivitytype) {
      case 1:
        page = "services";
        break;
      case 2:
        page = "spareparts";
        break;
      case 3:
        page = "showrooms";
        break;
    }

    return page;
  }

  geoToDetails(event: any, services: any) {
    let ctrl = event.target;

    if (ctrl.getAttribute("name") != "location") {
      let page = "";
      switch (services.businessactivitytype) {
        case 1:
          page = "services";
          break;
        case 2:
          page = "spareparts";
          break;
        case 3:
          page = "showrooms";
          break;

      }
      window.location.href = `business/${page}/details/` + services.businessid;
    }
  }

  async getData() {
    let map = await this.mapsAPILoader.load();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position: any) => {
        if (position) {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        } else {
          this.latitude = -1;
          this.longitude = -1;
        }

        await this.loadPageData();
      }, async (error) => {
        this.latitude = -1;
        this.longitude = -1;

        await this.loadPageData();
      })
    }
  }

  async addHits(auto: any) {
    // await this.autoService.addHitsAsync({
    //   "businessid": null,
    //   "autoid": auto.autoid,
    //   "userid": null
    // });

    window.location.href = "auto/details/" + auto.autoid;
  }

}
