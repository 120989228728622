import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';

import { NgSelect2Module } from 'ng-select2';
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AutosListComponent } from './autos-list/autos-list.component';
import { HttpClientModule } from '@angular/common/http';
import { AutoDetailsComponent } from './auto-details/auto-details.component';
import { ContactComponent } from './contact/contact.component';
import { PolicyComponent } from './policy/policy.component';
import { TermsComponent } from './terms/terms.component';
import { MenuComponent } from './menu/menu.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { OtpComponent } from './otp/otp.component';
import { ExtraBarComponent } from './extra-bar/extra-bar.component';
import { AddAutoComponent } from './add-auto/add-auto.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxImageCompressService } from 'ngx-image-compress';
import { ShowroomsComponent } from './showrooms/showrooms.component';
import { ShowroomDetailsComponent } from './showroom-details/showroom-details.component';
import { EditAutoComponent } from './edit-auto/edit-auto.component';
import { MyAutoComponent } from './my-auto/my-auto.component';
import { AutoServicesComponent } from './auto-services/auto-services.component';
import { AutoServicesDetailsComponent } from './auto-services-details/auto-services-details.component';
import { AddBusinessComponent } from './add-business/add-business.component';
import { BusinessLinksComponent } from './business-links/business-links.component';
import { EditBusinessComponent } from './edit-business/edit-business.component';
import { catchError, throwError } from 'rxjs';
import { EmailService } from 'src/services/email.service';
// import { FacebookLoginProvider, GoogleLoginProvider, GoogleSigninButtonDirective, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { AutoSparePartsComponent } from './auto-spare-parts/auto-spare-parts.component';
import { AutoSparepartsDetailsComponent } from './auto-spareparts-details/auto-spareparts-details.component';
import { MetadataService } from 'src/services/metadata.service';
import { AboutComponent } from './about/about.component';
import { SelectCityComponent } from './select-city/select-city.component';
import { GmsInfoComponent } from './gms-info/gms-info.component';
import { OnlyNumbersDirective } from 'src/directive/only-numbers.directive';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private emailService: EmailService) {

  }

  private extractFilename(stack: string): string {
    const matches = stack.match(/\b\w+\.\w+\.ts\b/);
    return matches ? matches[0] : 'Unknown File';
  }

  private extractLineNumber(stack: string): number {
    const matches = stack.match(/:\d+:\d+/);
    if (matches) {
      const lineNumber = matches[0].split(':')[1];
      return parseInt(lineNumber, 10);
    }
    return -1; // Return -1 if line number is not found
  }

  private extractComponentName(stack: string): string | null {
    const compMatch = stack.match(/at\s([A-Z][a-zA-Z]+)\./);
    return compMatch ? compMatch[1] : null;
  }

  appendAlert(message: any, type: any) {
    const alertPlaceholder = document.getElementById('liveAlertPlaceholder')

    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
      `<div class="alert alert-${type} alert-dismissible alert-fixed" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      '</div>'
    ].join('');

    alertPlaceholder!.innerHTML = '';

    alertPlaceholder!.append(wrapper);
  }

  async handleError(err: any): Promise<void> {
    // Handle the error here (e.g., log the error or display a user-friendly message)
    document.querySelector(".only-lock")?.setAttribute("style", "display: none;");

    if (err.stack) {
      this.appendAlert("An error occurred, try again later", "warning");

      console.error('An error occurred:', err);

      const filename = this.extractFilename(err.stack);
      const linenumber = this.extractLineNumber(err.stack);
      const componentName = this.extractComponentName(err.stack);

      try {
        let error = '';
        error += "<div style='text-align: center;'>";
        error += "<h2 style='color: rgb(190, 50, 50);'>Exception Occured:</h2>";
        error +=
          "<table style='width: 100%; display: inline-block; text-align: left;'>";

        error +=
          `<tr style='background-color:rgb(240,240,240); text-align: left;'><th style='white-space: nowrap;'>Page URL</th><td>${window.location.href}</td></tr>`;
        error +=
          `<tr style='background-color:rgb(240,240,240); text-align: left;'><th style='white-space: nowrap;'>File Name</th><td>${filename}</td></tr>`;
        error +=
          `<tr style='background-color:rgb(240,240,240); text-align: left;'><th style='white-space: nowrap;'>line</th><td>${linenumber}</td></tr>`;
        error +=
          `<tr style='background-color:rgb(240,240,240); text-align: left;'><th style='white-space: nowrap;'>Component Name</th><td>${componentName}</td></tr>`;

        error +=
          `<tr style='background-color:rgb(240,240,240); text-align: left;'><th style='white-space: nowrap;'>Message</th><td>${err.message}</td></tr>`;
        error +=
          `<tr style='background-color:rgb(240,240,240); text-align: left;'><th style='white-space: nowrap;'>Stack</th><td>${err.stack}</td></tr>`;

        error += "</table></div>";


        await this.emailService.sendMessageAsync("eng.mohamedfarouk@gmail.com", `Website Error in ${filename} at line ${linenumber}`, error);
      } catch (error) {

      }
    }
  }
}

// @Injectable()
// export class HttpErrorInterceptor implements HttpInterceptor {
//   constructor(private emailService: EmailService) {

//   }
//   intercept(req: HttpRequest<any>, next: HttpHandler) {
//     return next.handle(req).pipe(
//       catchError((err: HttpErrorResponse) => {
//         // Your error handling logic here
//         console.error('HTTP error occurred:', err);
//         // You can show a user-friendly message or perform any other error-related action.
//         try {
//           let ex: any = err;
//           let error = '';
//           error += "<div style='text-align: center;'>";
//           error += "<h2 style='color: rgb(190, 50, 50);'>Exception Occured:</h2>";
//           error +=
//             "<table style='width: 800px; display: inline-block; text-align: left;'>";

//           Object.keys(ex).map((key) => {
//             error +=
//               `<tr style='background-color:rgb(240,240,240); text-align: left;'><th>${key}</th><td>${ex[key]}</td></tr>`;
//           });
//           error += "</table></div>";

//           this.emailService.sendMessageAsync("eng.mohamedfarouk@gmail.com", "Website Error", error);
//         } catch (e) {

//         }

//         return null;// throwError(() => err);
//       })
//     );
//   }
// }

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AutosListComponent,
    AutoDetailsComponent,
    ContactComponent,
    PolicyComponent,
    TermsComponent,
    MenuComponent,
    LoginComponent,
    RegisterComponent,
    OtpComponent,
    ExtraBarComponent,
    AddAutoComponent,
    ShowroomsComponent,
    ShowroomDetailsComponent,
    EditAutoComponent,
    MyAutoComponent,
    AutoServicesComponent,
    AutoServicesDetailsComponent,
    AddBusinessComponent,
    BusinessLinksComponent,
    EditBusinessComponent,
    AutoSparePartsComponent,
    AutoSparepartsDetailsComponent,
    AboutComponent,
    SelectCityComponent,
    GmsInfoComponent,
    OnlyNumbersDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelect2Module,
    ReactiveFormsModule,
    // SocialLoginModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA-ZAjwV7EB0quFqkKZzlz2JWFeAqxhAEY',
      libraries: ['places']
    })
  ],
  providers: [
    MetadataService,
    NgxImageCompressService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    // {
    //   provide: 'SocialAuthServiceConfig',
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: GoogleLoginProvider.PROVIDER_ID,
    //         provider: new GoogleLoginProvider(
    //           '347658318246-foq2jaenk5glqscgoejc80tjb0k096lj.apps.googleusercontent.com'
    //         )
    //       },
    //       {
    //         id: FacebookLoginProvider.PROVIDER_ID,
    //         provider: new FacebookLoginProvider('3154220198180159'),
    //       },
    //     ],
    //   } as SocialAuthServiceConfig,
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpErrorInterceptor,
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent, MenuComponent, ExtraBarComponent, BusinessLinksComponent]
})
export class AppModule { }

