<div class="footer-widget-box list">
    <h4 class="footer-widget-title">Business Links</h4>
    <div class="footer-newsletter">
        <!-- <p>Subscribe Our Newsletter To Get Latest Update And News</p> -->
        <div class="subscribe-form">
            <!-- <input type="email" class="form-control" placeholder="Your Email"> -->
            <a class="theme-btn" [href]="'https://gms.mootori.com/dashboard' + (extraData != '' ? '?u=' + extraData : '')"
                target="_blank">
                Business Login <i class="fa-solid fa-right-to-bracket"></i>
            </a>

            <a class="theme-btn" href="https://gms.mootori.com/register/c" *ngIf="currentUser">
                Register Business <i class="fa-solid fa-square-arrow-up-right"></i>
            </a>
        </div>
    </div>
</div>